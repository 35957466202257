import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '',
    width: 'fit-content',
    margin: 'auto',
    padding: 40,
    borderRadius: 10,
  },
  title: {
    margin: '10px 0',
  },
  button: {
    margin: '20px 0',
  },
}));

export default useStyles;
