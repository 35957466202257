const SCREEN_NAMES = {
  INVESTMENTS: 'investments',
  TRANSACTIONS: 'transactions',
  ORDERS: 'orders',
  SERIES: 'series',
  OFFERS: 'offers',
  PAGES: 'pages',
  FEATURED_OFFERS: 'featuredOffers',
  STATIC_CONTENT: 'staticContent',
  FAQ: 'faq',
  SLIDER: 'slider',
  AGREEMENTS: 'agreements',
  EMAIL_TEMPLATES: 'emailTemplates',
  USERS: 'users',
  PERMISSIONS: 'permissions',
  ROLES: 'roles',
  SETTINGS: 'settings',
};

export default SCREEN_NAMES;
