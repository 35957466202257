import React from 'react';
import { Form, Grid, Label } from 'semantic-ui-react';
import TRANSLATIONS from '@translations/translationNamespaces';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';

class DatePickerField extends React.PureComponent {
  render() {
    const {
      label,
      name,
      onChange,
      t,
      value,
      icon,
      errorMessage,
      placeholder,
      className,
      onKeyUp,
      clearable = false,
    } = this.props;

    return (
      <Form>
        <Grid.Column>
          <Form.Field>
            {errorMessage && (
              <Label color="red" className="mb-5">
                {t(errorMessage)}
              </Label>
            )}
            {label && <label>{label}</label>}
            <DatePicker
              placeholderText={placeholder}
              id={name}
              className={className}
              name={name}
              isClearable={clearable}
              utcOffset={0}
              {...(new Date(value)?.toString?.() !== 'Invalid Date' && value !== null && value !== undefined
                ? { selected: new Date(moment?.tz?.(value, 'Europe/Warsaw').format('YYYY-MM-DDTHH:mm:ss.SSS')) }
                : {})}
              onChange={(date, e) => onChange(e, { value: date, name })}
              dateFormat="dd/MM/yyyy'"
              onKeyUp={onKeyUp}
              monthsShown={1}
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'],
                },
                preventOverflow: {
                  enabled: false,
                },
                hide: {
                  enabled: false,
                },
              }}
            />
          </Form.Field>
        </Grid.Column>
      </Form>
    );
  }
}

export default withTranslation(TRANSLATIONS.ERRORS)(DatePickerField);
