import React from 'react';
import LanguageSwitcher from '@theme/layouts/Main/components/Header/components/Topbar/components/LanguageSwitcher/LanguageSwitcher';
import { func, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const Topbar = ({ getMenu, logoutUser, user }) => {
  const history = useHistory();
  const [t] = useTranslation(TRANSLATIONS.COMMON);

  return (
    <div className="topbar align-items-center">
      <div className="d-flex align-items-center justify-content-center mx-2">
        <span className="mx-2">{user?.userData?.firstName}</span>
        <span>{user?.userData?.lastName}</span>
      </div>
      <LanguageSwitcher getMenu={getMenu} />
      <Button secondary basic type="button" onClick={() => logoutUser({ history })}>
        {t('logout')}
      </Button>
    </div>
  );
};

Topbar.propTypes = {
  getMenu: func.isRequired,
  logoutUser: func.isRequired,
  user: shape({}),
};

Topbar.defaultProps = {
  user: {},
};

export default Topbar;
