import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import errorImage from './404-image.png';
import useStyles from './styles';

const Main = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <img className={styles.image} src={errorImage} alt="404 Not Found" />
      <Typography className={styles.title} variant="h2">
        Ooooooops!
      </Typography>
      <Typography variant="h5">Page with the given Slug does not exist</Typography>
      <Typography variant="h5">Check the URL and try again</Typography>
      <Button className={styles.button} component={Link} variant="outlined" color="primary" to={ROUTES.HOME}>
        Back to Dashboard
      </Button>
    </div>
  );
};

export default Main;
