import React, { useMemo } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from '@metronic/providers';
import { Brand } from '../index';
import { AsideMenu } from './components';

const Aside = ({ sidebarMenuItems, menuStatus, logoutUser }) => {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      disableScroll: objectPath.get(uiService.config, 'aside.menu.dropdown') === 'true' || false,
      asideClassesFromConfig: uiService.getClasses('aside', true),
      disableAsideSelfDisplay: objectPath.get(uiService.config, 'aside.self.display') === false,
      headerLogo: uiService.getLogo(),
    };
  }, [uiService]);

  return (
    <>
      <div
        id="kt_aside"
        className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}
      >
        <Brand />
        <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
          {layoutProps.disableAsideSelfDisplay && (
            <>
              <div className="header-logo">
                <Link to="/">
                  <img alt="logo" src={layoutProps.headerLogo} />
                </Link>
              </div>
            </>
          )}
          <AsideMenu
            logoutUser={logoutUser}
            disableScroll={layoutProps.disableScroll}
            sidebarMenuItems={sidebarMenuItems}
            menuStatus={menuStatus}
          />
        </div>
      </div>
    </>
  );
};

Aside.propTypes = {
  sidebarMenuItems: arrayOf(shape({})),
  menuStatus: string.isRequired,
  logoutUser: func.isRequired,
};

Aside.defaultProps = {
  sidebarMenuItems: [],
};

export default Aside;
