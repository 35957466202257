import React from 'react';
import { bool, func, shape, arrayOf } from 'prop-types';
import { Divider, Form, Grid, Icon, Segment } from 'semantic-ui-react';
import { InputField } from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import { Select } from '@shared/components';
import TRANSLATIONS from '@translations/translationNamespaces';
import { isEmpty } from 'lodash';
import useStyles from './styles';

const InvestorData = ({
  isLoading,
  manualInvestingData,
  setManualInvestingData,
  offersOptions,
  countries,
  states,
  getOfferAgreements,
  checkEmail,
  isEmailBusy,
  createOrderErrors,
  setAgreementsData,
}) => {
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.ORDERS);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);

  const onChangeContent = (e, { name, value }) =>
    setManualInvestingData(prevState => ({
      ...prevState,
      [name]: value,
    }));

  const onInvestorDataChange = (e, { name, value }) =>
    setManualInvestingData(prevState => ({
      ...prevState,
      investorData: {
        ...prevState.investorData,
        [name]: value,
      },
    }));

  const onAddressChange = (e, { name, value }) => {
    if (name === 'country') {
      return setManualInvestingData(prevState => ({
        ...prevState,
        investorData: {
          ...prevState.investorData,
          address: {
            ...prevState.investorData.address,
            [name]: value,
            state: '',
          },
        },
      }));
    }

    return setManualInvestingData(prevState => ({
      ...prevState,
      investorData: {
        ...prevState.investorData,
        address: {
          ...prevState.investorData.address,
          [name]: value,
        },
      },
    }));
  };

  const onBankInfoChange = (e, { name, value }) =>
    setManualInvestingData(prevState => ({
      ...prevState,
      investorData: {
        ...prevState.investorData,
        bankAccount: {
          ...prevState.investorData.bankAccount,
          [name]: value,
        },
      },
    }));

  const onOfferChange = async (e, { name, value }) => {
    await setManualInvestingData(prevState => ({
      ...prevState,
      [name]: value,
    }));

    await setAgreementsData([]);

    await getOfferAgreements({ offerId: value });
  };

  return (
    <Segment className={styles.segment}>
      <Form loading={isLoading}>
        <Form.Group>
          <Grid container stackable columns={1} divided>
            <Grid.Column verticalAlign="middle">
              <Form.Field className={styles.infoColumn}>
                <h3 className="d-flex">
                  <Icon name="chart bar" color="black" />

                  <b style={{ lineHeight: 1.4 }} className="ml-1">
                    {t('form.offer')}
                  </b>
                </h3>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <Select
              label={t('form.offer')}
              placeholder={t('form.selectOffer')}
              iconPosition="left"
              name="offer"
              value={manualInvestingData?.offer}
              options={offersOptions}
              selection
              button
              onChange={onOfferChange}
              errorMessage={error(createOrderErrors?.offer)}
            />

            <InputField
              icon="sort amount up"
              name="quantity"
              type="number"
              label={t('form.quantity')}
              value={manualInvestingData?.quantity}
              labelClassName={styles.label}
              onChange={onChangeContent}
              errorMessage={
                error(createOrderErrors?.quantity) || manualInvestingData?.quantity <= 0
                  ? error('Thisvalueshouldbegreaterthan0')
                  : null
              }
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={1} divided>
            <Grid.Column verticalAlign="middle">
              <Form.Field>
                <h3 className="d-flex">
                  <Icon name="user" color="black" />

                  <b style={{ lineHeight: 1.4 }} className="ml-1">
                    {t('form.investorData')}
                  </b>
                </h3>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <InputField
              icon="at"
              label={t('form.email')}
              name="email"
              onChange={onChangeContent}
              value={manualInvestingData?.email}
              labelClassName={styles.label}
              onBlur={checkEmail}
              tooltip={isEmailBusy ? error('EMAIL_EXISTS') : null}
              errorMessage={error(createOrderErrors?.email)}
            />
            <InputField
              icon="phone"
              label={t('form.phoneNumber')}
              name="phoneNumber"
              value={manualInvestingData?.investorData?.phoneNumber}
              labelClassName={styles.label}
              onChange={onInvestorDataChange}
              errorMessage={error(createOrderErrors?.investorData?.phoneNumber)}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <InputField
              icon="font"
              label={t('form.firstName')}
              name="firstName"
              onChange={onInvestorDataChange}
              value={manualInvestingData?.investorData?.firstName}
              labelClassName={styles.label}
              errorMessage={error(createOrderErrors?.investorData?.firstName)}
            />
            <InputField
              icon="font"
              label={t('form.lastName')}
              name="lastName"
              onChange={onInvestorDataChange}
              value={manualInvestingData?.investorData?.lastName}
              labelClassName={styles.label}
              errorMessage={error(createOrderErrors?.investorData?.lastName)}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={1} divided>
            <Grid.Column verticalAlign="middle">
              <Form.Field>
                <h3 className="d-flex">
                  <Icon name="home" color="black" />

                  <b style={{ lineHeight: 1.4 }} className="ml-1">
                    {t('form.home')}
                  </b>
                </h3>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <Select
              button
              label={t('form.country')}
              placeholder={t('form.selectInvestorCountry')}
              name="country"
              options={countries}
              value={manualInvestingData?.investorData?.address?.country}
              selection
              onChange={onAddressChange}
              errorMessage={error(createOrderErrors?.investorData?.address?.country)}
            />

            <Select
              label={t('form.state')}
              placeholder={t('form.selectInvestorState')}
              iconPosition="left"
              name="state"
              value={manualInvestingData?.investorData?.address?.state}
              options={states}
              selection
              button
              onChange={onAddressChange}
              disabled={isEmpty(states)}
              errorMessage={error(createOrderErrors?.investorData?.address?.state)}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <InputField
              icon="zip"
              label={t('form.zip')}
              name="zip"
              onChange={onAddressChange}
              value={manualInvestingData?.investorData?.address?.zip}
              labelClassName={styles.label}
              errorMessage={error(createOrderErrors?.investorData?.address?.zip)}
            />
            <InputField
              icon="home"
              label={t('form.city')}
              name="city"
              onChange={onAddressChange}
              value={manualInvestingData?.investorData?.address?.city}
              labelClassName={styles.label}
              errorMessage={error(createOrderErrors?.investorData?.address?.city)}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={1} divided>
            <InputField
              icon="home"
              label={t('form.address')}
              name="address"
              onChange={onAddressChange}
              value={manualInvestingData?.investorData?.address?.address}
              errorMessage={error(createOrderErrors?.investorData?.address?.address)}
              labelClassName={styles.label}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={1} divided>
            <Grid.Column verticalAlign="middle">
              <Form.Field>
                <h3 className="d-flex">
                  <Icon name="money" color="black" />

                  <b style={{ lineHeight: 1.4 }} className="ml-1">
                    {t('form.bankInfo')}
                  </b>
                </h3>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <Select
              label={t('form.country')}
              placeholder={t('form.selectBankCountry')}
              iconPosition="left"
              name="country"
              value={manualInvestingData?.investorData?.bankAccount?.country}
              options={countries}
              selection
              button
              onChange={onBankInfoChange}
              errorMessage={error(createOrderErrors?.investorData?.bankAccount?.country)}
            />
            <InputField
              icon="money bill alternate outline"
              label={t('form.bankName')}
              name="bankName"
              onChange={onBankInfoChange}
              value={manualInvestingData?.investorData?.bankAccount?.bankName}
              labelClassName={styles.label}
              errorMessage={error(createOrderErrors?.investorData?.bankAccount?.bankName)}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={1} divided>
            <InputField
              icon="money bill alternate outline"
              label={t('form.accountNumber')}
              name="accountNumber"
              type="number"
              onChange={onBankInfoChange}
              value={manualInvestingData?.investorData?.bankAccount?.accountNumber}
              labelClassName={styles.label}
              errorMessage={error(createOrderErrors?.investorData?.bankAccount?.accountNumber)}
            />
          </Grid>
        </Form.Group>
      </Form>
    </Segment>
  );
};

InvestorData.propTypes = {
  isEmailBusy: bool,
  isLoading: bool,
  setManualInvestingData: func,
  setAgreementsData: func,
  getOfferAgreements: func,
  checkEmail: func,
  manualInvestingData: shape({}),
  offersOptions: arrayOf(shape({})),
  countries: arrayOf(shape({})),
  states: arrayOf(shape({})),
  createOrderErrors: shape({}),
};

InvestorData.defaultProps = {
  isEmailBusy: false,
  isLoading: false,
  setManualInvestingData: () => {},
  setAgreementsData: () => {},
  getOfferAgreements: () => {},
  checkEmail: () => {},
  manualInvestingData: {},
  offersOptions: [],
  countries: [],
  states: [],
  createOrderErrors: {},
};

export default InvestorData;
