import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Divider, Form, Grid } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { InputField, TranslatableTextField } from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useStyles from './styles';

const General = ({ isLoading, status, configData, setConfigData }) => {
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.SETTINGS);

  const onContactFormChange = (e, { name, value }) => {
    setConfigData(prevState => ({
      ...prevState,
      contactForm: {
        ...prevState?.contactForm,
        [name]: value,
      },
    }));
  };

  const onChangeTranslatableContent = (e, { name, value, language }) => {
    setConfigData(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));
  };

  return (
    <div>
      <Form loading={status === FETCH_STATUS.IDLE || isLoading}>
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              name="seoTitle"
              value={configData?.seoTitle}
              label={t('form.seoTitle')}
              // errorMessage={error(faqErrors?.question)}
              icon="font"
              onChange={onChangeTranslatableContent}
              labelClassName={styles.label}
              tooltip={t('tooltip.seoTitle')}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              tooltip={t('tooltip.seoDescription')}
              multiline
              name="seoDescription"
              value={configData?.seoDescription}
              label={t('form.seoDescription')}
              icon="font"
              onChange={onChangeTranslatableContent}
              labelClassName={styles.label}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={3} divided>
            <InputField
              tooltip={t('tooltip.sendFrom')}
              onChange={onContactFormChange}
              label={t('form.sendFrom')}
              icon="font"
              name="sendFrom"
              value={configData?.contactForm?.sendFrom}
            />
            <InputField
              onChange={onContactFormChange}
              label={t('form.sendTo')}
              tooltip={t('tooltip.sendTo')}
              name="sendTo"
              icon="font"
              value={configData?.contactForm?.sendTo}
            />
            <InputField
              onChange={onContactFormChange}
              label={t('form.sendFromName')}
              name="sendFromName"
              icon="font"
              value={configData?.contactForm?.sendFromName}
            />
          </Grid>
        </Form.Group>
      </Form>
    </div>
  );
};

General.propTypes = {
  isLoading: bool,
  status: string,
  configData: shape({}),
  setConfigData: func,
};

General.defaultProps = {
  isLoading: false,
  status: undefined,
  configData: {},
  setConfigData: () => {},
};

export default General;
