import {
  GET_SINGLE_SERIES_INIT,
  GET_SINGLE_SERIES_SUCCESS,
  GET_SINGLE_SERIES_FAILURE,
} from '@store/action-types/series';
import client from '@api-sdk/client';
import moment from 'moment';

const getSingleSeriesInit = () => ({ type: GET_SINGLE_SERIES_INIT });
const getSingleSeriesSuccess = singleSeries => ({
  type: GET_SINGLE_SERIES_SUCCESS,
  payload: { singleSeries },
});
const getSingleSeriesFailure = error => ({ type: GET_SINGLE_SERIES_FAILURE, payload: { error } });

const getSingleSeries = id => async dispatch => {
  dispatch(getSingleSeriesInit());

  try {
    const singleSeries = await client.getSingleSeries({ slug: id });
    dispatch(
      getSingleSeriesSuccess({
        ...singleSeries,
        response: {
          ...singleSeries.response,
          availableTo: singleSeries?.response?.availableTo ? moment(singleSeries?.response?.availableTo) : moment(),
          availableFrom: singleSeries?.response?.availableFrom
            ? moment(singleSeries?.response?.availableFrom)
            : moment(),
        },
      }),
    );
  } catch (error) {
    dispatch(getSingleSeriesFailure(error));
  }
};

export default getSingleSeries;
