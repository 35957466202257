import React, { useEffect, useRef, useState } from 'react';
import { bool, func, shape } from 'prop-types';
import { Button, Divider, Form, Grid, Segment } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { SwitchField, TranslatableTextField, TranslatableRichTextField } from '@screens/offers/NewOfferForm/components';
import { VALID_DOCUMENT_MIME_TYPES, VALID_IMAGE_MIME_TYPES } from '@screens/page/const';
import { validateDocumentType, validateFileSize, validateImageType } from '@screens/page/utils';
import DescriptionIcon from '@material-ui/icons/Description';
import { isEmpty } from 'lodash';
import useStyles from './styles';

const PageForm = ({ isCreateMode, loading, page, pageErrors, onSavePage, onRefreshPage, onCreatePage, isViewMode }) => {
  const [t] = useTranslation(TRANSLATIONS.PAGES);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);
  const params = useParams();
  const [imageUploadError, setImageUploadError] = useState('');
  const [fileUploadError, setFileUploadError] = useState('');
  const [imageData, setImageData] = useState({});
  const [filesData, setFilesState] = useState([]);
  const [pageData, setPageData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef();
  const styles = useStyles();

  useEffect(() => {
    if (isEmpty(pageData)) {
      setPageData(page);
      setFilesState(page?.attachments);
      setImageData(page?.images);
    }
  }, [page, pageData]);

  const handleActive = () => {
    setPageData(prevState => ({
      ...prevState,
      active: !prevState.active,
    }));
  };

  const handleCreatePage = async ({ saveAndBack }) => {
    await setIsLoading(true);
    await onCreatePage({ pageData, imageData, filesData, saveAndBack });
    await setIsLoading(false);
  };

  const handleRefreshPage = async () => {
    await setPageData({});
    await onRefreshPage(params.id);
  };

  const toBase64Handler = async files => {
    const filePathsPromises = [];
    const toBase64 = file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const findDuplicateFileNames = filesData?.filter?.(stateFile => stateFile.file.fileName === file.name);
        reader.onload = () =>
          resolve({
            displayName:
              findDuplicateFileNames.length > 0 ? `${file.name} (${findDuplicateFileNames.length + 1})` : file.name,
            file: {
              fileName:
                findDuplicateFileNames.length > 0 ? `${file.name} (${findDuplicateFileNames.length + 1})` : file.name,
              base64: reader.result,
              delete: false,
            },
            isNewFile: true,
            fileItemIndex: filesData.length,
          });
        reader.onerror = err => reject(err);
      });
    };

    files.forEach(file => {
      filePathsPromises.push(toBase64(file));
    });

    return Promise.all(filePathsPromises);
  };

  const handleFiles = async files => {
    if ((validateDocumentType(files) || validateImageType(files)) && validateFileSize(files, 0, 100000000)) {
      setFileUploadError('');
      const base64FilesArray = await toBase64Handler([...files]);

      return setFilesState(prevState => prevState.concat(base64FilesArray));
    }

    return setFileUploadError(
      'Wrong file type or size, we accept common document and image file types, file size cannot exceed 100 MB',
    );
  };

  const removeFile = fileItemIndex => {
    setFilesState(prevState => {
      return prevState.map(item => {
        if (item.fileItemIndex === fileItemIndex) {
          return { ...item, file: { ...item.file, delete: !item.file.delete } };
        }
        return item;
      });
    });
  };

  const filesSelected = async e => {
    e.preventDefault();

    if (fileInputRef.current.files.length) {
      await handleFiles(fileInputRef.current.files);
    }
  };

  const fileDrop = async e => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (files.length) {
      await handleFiles(files);
    }
  };

  const handleImageChange = e => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if ((validateDocumentType([file]) || validateImageType([file])) && validateFileSize([file], 0, 10000000)) {
      setImageUploadError('');

      if (file) {
        reader.onloadend = () => {
          return setImageData({
            fileName: file.name,
            delete: false,
            base64: reader.result,
          });
        };

        return reader.readAsDataURL(file);
      }
      return setImageUploadError(
        'Wrong file type or size, we accept common image file types, image file size cannot exceed 10 MB',
      );
    }
    return setImageUploadError(
      'Wrong file type or size, we accept common image file types, image file size cannot exceed 10 MB',
    );
  };

  const onChangeTranslatableContent = (e, { name, value, language }) => {
    return setPageData(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));
  };

  const renderMenuButtons = () => {
    return (
      <Grid columns={2}>
        <Grid.Column>
          <h2>{isCreateMode ? t('addPage') : t('editPage')}</h2>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button style={{ marginRight: '5px' }} as={Link} type="button" to={ROUTES.PAGES}>
            {t('actionButtons.goBack')}
          </Button>
          {!isCreateMode && (
            <Button
              disabled={isLoading || loading}
              type="submit"
              secondary
              onClick={handleRefreshPage}
              style={{ marginRight: '5px' }}
            >
              {t('actionButtons.reload')}
            </Button>
          )}

          {!isCreateMode && !isViewMode && (
            <Button
              style={{ marginRight: '5px' }}
              disabled={isLoading || loading}
              variant="outlined"
              primary
              onClick={() => onSavePage({ pageData, imageData, filesData })}
            >
              {t('actionButtons.save')}
            </Button>
          )}

          {!isCreateMode && !isViewMode && (
            <Button
              disabled={isLoading || loading}
              variant="outlined"
              color="facebook"
              onClick={() => onSavePage({ pageData, imageData, filesData, saveAndBack: true })}
            >
              {t('actionButtons.saveAndBack')}
            </Button>
          )}

          {isCreateMode && (
            <Button
              style={{ marginRight: '5px' }}
              disabled={isLoading || loading}
              variant="outlined"
              primary
              onClick={handleCreatePage}
            >
              {t('actionButtons.add')}
            </Button>
          )}

          {isCreateMode && (
            <Button
              disabled={isLoading || loading}
              variant="outlined"
              color="facebook"
              onClick={() => handleCreatePage({ saveAndBack: true })}
            >
              {t('actionButtons.addAndBack')}
            </Button>
          )}
        </Grid.Column>
      </Grid>
    );
  };

  return (
    <div>
      {renderMenuButtons()}
      <Segment>
        <Form loading={loading || isLoading}>
          <Form.Group>
            <Grid container stackable columns={3} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="slug"
                label={t('slug')}
                value={pageData?.slug}
                errorMessage={error(pageErrors?.slug)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
                tooltip={t('tooltip.slug')}
              />
              <Grid.Column>
                <SwitchField
                  disabled={isViewMode}
                  onChange={handleActive}
                  className="text-center"
                  name="active"
                  value={pageData?.active}
                  label={t('active')}
                />
              </Grid.Column>
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                tooltip={t('tooltip.seoTitle')}
                name="seoTitle"
                label={t('seoTitle')}
                value={pageData?.seoTitle}
                errorMessage={error(pageErrors?.seoTitle)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                multiline
                tooltip={t('tooltip.seoDescription')}
                name="seoDescription"
                label={t('seoDescription')}
                value={pageData?.seoDescription}
                errorMessage={error(pageErrors?.seoDescription)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="title"
                label={t('title')}
                value={pageData?.title}
                errorMessage={error(pageErrors?.title)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="subTitle"
                label={t('subtitle')}
                value={pageData?.subTitle}
                errorMessage={error(pageErrors?.subTitle)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableRichTextField
                disabled={isViewMode}
                label={t('content')}
                name="content"
                errorMessage={error(pageErrors?.content)}
                value={pageData?.content}
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={isViewMode ? 1 : 2} divided>
              {isViewMode ? null : (
                <Grid.Column>
                  <div className={styles['page-image-upload--buttons']}>
                    <div className="page-errors">
                      {imageUploadError && (
                        <p style={{ color: 'red' }} key={imageUploadError}>
                          {imageUploadError}
                        </p>
                      )}
                    </div>

                    <Form.Field>
                      <label aria-controls="" htmlFor="create-page-add-file">
                        <p>{t('imageUpload')}</p>
                        <input
                          id="create-page-add-file"
                          type="file"
                          onChange={handleImageChange}
                          accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                        />
                      </label>
                    </Form.Field>

                    <br />
                    {(imageData?.base64 || pageData?.thumbUrl) && (
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={e => {
                          e.preventDefault();
                          setImageData({ base64: undefined, delete: true, name: undefined });
                        }}
                      >
                        remove photo
                      </Button>
                    )}
                  </div>
                </Grid.Column>
              )}

              <Grid.Column>
                <div className="page-image-upload--preview">
                  <Form.Field>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>{t('imagePreview')}</label>
                    {!imageData?.delete && <img src={imageData?.base64 || pageData?.thumbUrl} alt="" />}
                  </Form.Field>
                </div>
              </Grid.Column>
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={1} divided>
              <Grid.Column>
                <Form.Field>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>
                    <p>{t('attachments')}</p>
                    <div className={styles['page-attachments--container']}>
                      {isViewMode ? null : (
                        <>
                          <div className="page-errors">
                            {fileUploadError && (
                              <p style={{ color: 'red' }} key={fileUploadError}>
                                {fileUploadError}
                              </p>
                            )}
                          </div>
                          <div
                            onDragOver={e => e.preventDefault()}
                            onDragEnter={e => e.preventDefault()}
                            onDragLeave={e => e.preventDefault()}
                            onDrop={fileDrop}
                            className={styles['page-attachments--dropzone']}
                          >
                            <div className={styles['page-attachments--dropzone-elements']}>
                              <div>{t('dragAndDrop')}</div>
                              <input
                                ref={fileInputRef}
                                type="file"
                                multiple
                                onChange={filesSelected}
                                accept={`${VALID_IMAGE_MIME_TYPES.join(', ')}, ${VALID_DOCUMENT_MIME_TYPES.join(', ')}`}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <br />
                      <div className={styles['page-attachments--file-list']}>
                        {filesData?.map?.(file => (
                          <p
                            style={file.file.delete ? { 'text-decoration': 'line-through' } : {}}
                            className={styles['file-item']}
                            key={file.displayName + file.fileItemIndex}
                            onClick={isViewMode ? () => {} : () => removeFile(file.fileItemIndex)}
                          >
                            <DescriptionIcon /> {file.displayName}
                          </p>
                        ))}
                      </div>
                    </div>
                  </label>
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      {renderMenuButtons()}
    </div>
  );
};

PageForm.propTypes = {
  isCreateMode: bool,
  loading: bool,
  page: shape({}),
  pageErrors: shape({}),
  onSavePage: func,
  onRefreshPage: func,
  onCreatePage: func,
  isViewMode: bool,
};

PageForm.defaultProps = {
  isCreateMode: false,
  loading: false,
  page: {},
  pageErrors: {},
  onSavePage: () => {},
  onRefreshPage: () => {},
  onCreatePage: () => {},
  isViewMode: false,
};

export default PageForm;
