import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Divider, Form, Grid } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { SwitchField, TranslatableTextField } from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const Maintenance = ({ isLoading, status, configData, setConfigData }) => {
  const [t] = useTranslation(TRANSLATIONS.SETTINGS);

  const onSwitchChange = () => {
    setConfigData(prevState => ({
      ...prevState,
      maintenanceMode: !prevState.maintenanceMode,
    }));
  };

  const onChangeTranslatableContent = (e, { name, value, language }) => {
    setConfigData(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));
  };

  return (
    <div>
      <Form loading={status === FETCH_STATUS.IDLE || isLoading}>
        <Form.Group>
          <Grid container stackable columns={1} divided>
            <SwitchField
              tooltip={t('tooltip.maintenanceMode')}
              className="text-center"
              name="maintenanceMode"
              value={configData?.maintenanceMode}
              label={t('form.isMaintenanceMode')}
              onChange={onSwitchChange}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              name="maintenanceModeTitle"
              value={configData?.maintenanceModeTitle}
              label={t('form.maintenanceModeTitle')}
              icon="font"
              onChange={onChangeTranslatableContent}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              name="maintenanceModeMessage"
              value={configData?.maintenanceModeMessage}
              label={t('form.maintenanceModeMessage')}
              icon="font"
              onChange={onChangeTranslatableContent}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              name="systemMaintenanceModeTitle"
              value={configData?.systemMaintenanceModeTitle}
              label={t('form.systemMaintenanceModeTitle')}
              icon="font"
              onChange={onChangeTranslatableContent}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              name="systemMaintenanceModeMessage"
              value={configData?.systemMaintenanceModeMessage}
              label={t('form.systemMaintenanceModeMessage')}
              icon="font"
              onChange={onChangeTranslatableContent}
            />
          </Grid>
        </Form.Group>
      </Form>
    </div>
  );
};

Maintenance.propTypes = {
  isLoading: bool,
  status: string,
  configData: shape({}),
  setConfigData: func,
};

Maintenance.defaultProps = {
  isLoading: false,
  status: undefined,
  configData: {},
  setConfigData: () => {},
};

export default Maintenance;
