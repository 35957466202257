import React from 'react';
import { Segment, Grid, Button, Icon, Dropdown, Label, Menu } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import OfferSectionElement from '../elements/OfferSectionElement';
import OfferSectionElementModel from '../../models/OfferSectionElementModel';

class OfferSection extends React.Component {
  state = {
    isSectionExpanded: true,
  };

  constructor(props) {
    super(props);

    this.onRemoveSection = this.onRemoveSection.bind(this);
    this.onChangePositionArrowDown = this.onChangePositionArrowDown.bind(this);
    this.onChangePositionArrowUp = this.onChangePositionArrowUp.bind(this);
    this.onDropdownChangePosition = this.onDropdownChangePosition.bind(this);
    this.onAddSectionElement = this.onAddSectionElement.bind(this);
  }

  onRemoveSection(position) {
    const { onChange } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove section ${position}? Section changes will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === position) {
            return acc;
          }
          if (section.position > position) {
            return acc.concat({ ...section, position: section.position - 1 });
          }
          return acc.concat(section);
        }, []),
      }));
    }
  }

  onChangePositionArrowDown(position) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        const nextItem = prevState.offerSections.find(item => item.position === position + 1);
        const matchingItem = prevState.offerSections.find(item => item.position === position);

        if (section.position === matchingItem.position) {
          return acc.concat({ ...nextItem, position: matchingItem.position });
        }

        if (section.position === nextItem.position) {
          return acc.concat({ ...matchingItem, position: nextItem.position });
        }

        return acc.concat(section);
      }, []),
    }));
  }

  onChangePositionArrowUp(position) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        const prevItem = prevState.offerSections.find(item => item.position === position - 1);
        const matchingItem = prevState.offerSections.find(item => item.position === position);

        if (section.position === prevItem.position) {
          return acc.concat({ ...matchingItem, position: prevItem.position });
        }

        if (section.position === matchingItem.position) {
          return acc.concat({ ...prevItem, position: matchingItem.position });
        }

        return acc.concat(section);
      }, []),
    }));
  }

  onDropdownChangePosition({ currentPosition, newPosition }) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        const currentPositionItem = prevState.offerSections.find(item => item.position === currentPosition);
        const newPositionItem = prevState.offerSections.find(item => item.position === newPosition);

        if (section.position === currentPositionItem.position) {
          return acc.concat({ ...currentPositionItem, position: newPositionItem.position });
        }

        if (section.position === newPositionItem.position) {
          return acc.concat({ ...newPositionItem, position: currentPositionItem.position });
        }

        return acc.concat(section);
      }, []),
    }));
  }

  onAddSectionElement(position) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === position) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.concat([
                {
                  ...OfferSectionElementModel,
                  position: section?.elements?.[section?.elements?.length - 1]?.position + 1 || 1,
                  backendIndex: section?.elements?.[section?.elements?.length - 1]?.backendIndex + 1 || 0,
                },
              ]),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  renderSectionElements() {
    const { section, onChange, sectionErrors, isViewMode } = this.props;

    return section.elements.map(element => {
      return (
        <OfferSectionElement
          isViewMode={isViewMode}
          key={element.position}
          element={element}
          elementErrors={sectionErrors?.elements?.[element?.backendIndex]}
          sectionNumber={section.position}
          onChange={onChange}
          disableUpArrow={element.position === section.elements?.[0]?.position}
          disableDownArrow={element.position === section.elements?.[section.elements.length - 1]?.position}
        />
      );
    });
  }

  toggleIsExpanded = () =>
    this.setState(prevState => ({ ...prevState, isSectionExpanded: !prevState.isSectionExpanded }));

  render() {
    const { section, disableUpArrow, disableDownArrow, dropdownPositions, t, isViewMode } = this.props;

    return (
      <Segment color="blue">
        <Menu stackable fluid className="offer-section">
          <Menu.Menu position="left">
            <Menu.Item>
              <Label size="big" color="blue">
                {t('section')} {section.position}
              </Label>
            </Menu.Item>
            <Menu.Item>
              <Button color="green" icon onClick={() => this.toggleIsExpanded()}>
                {this.state.isSectionExpanded ? <Icon name="angle double up" /> : <Icon name="angle double down" />}
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Dropdown
                disabled={isViewMode}
                placeholder="Section number"
                selection
                fluid
                value={section.position}
                onChange={(e, { value }) =>
                  this.onDropdownChangePosition({
                    currentPosition: section.position,
                    newPosition: value,
                  })
                }
                options={dropdownPositions}
              />
            </Menu.Item>
            {isViewMode ? null : (
              <Menu.Item>
                <Grid columns={2}>
                  <Grid.Column>
                    <Button
                      icon
                      disabled={disableUpArrow || isViewMode}
                      onClick={() => this.onChangePositionArrowUp(section.position)}
                    >
                      <Icon name="arrow up" />
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      icon
                      disabled={disableDownArrow || isViewMode}
                      onClick={() => this.onChangePositionArrowDown(section.position)}
                    >
                      <Icon name="arrow down" />
                    </Button>
                  </Grid.Column>
                </Grid>
              </Menu.Item>
            )}
            {isViewMode ? null : (
              <Menu.Item>
                <Button color="red" onClick={() => this.onRemoveSection(section.position)}>
                  <Icon name="trash" />
                  {t('removeSection')}
                </Button>
              </Menu.Item>
            )}
          </Menu.Menu>
          {isViewMode ? null : (
            <Menu.Menu position="right">
              <Menu.Item>
                <Button secondary onClick={() => this.onAddSectionElement(section.position)}>
                  <Icon name="plus" />
                  {t('addElement')}
                </Button>
              </Menu.Item>
            </Menu.Menu>
          )}
        </Menu>
        {this.state.isSectionExpanded && <Grid columns={12}>{this.renderSectionElements()}</Grid>}
      </Segment>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(OfferSection);
