import React from 'react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { shape, string } from 'prop-types';
import OfferForm from '../NewOfferForm/OfferForm';

class Main extends React.PureComponent {
  componentDidMount() {
    const { actions, match } = this.props;
    actions.getSingleOfferAction(match?.params?.id);
    actions.getSeriesOptionsAction();
    actions.getTypesAndTargetsAction();
  }

  render() {
    const {
      actions,
      singleOffer,
      offerStatus,
      offerErrors,
      match,
      seriesOptions,
      targets,
      types,
      permittedPermissions,
    } = this.props;

    const isUserPermitted = permission =>
      permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

    return (
      <div>
        <OfferForm
          isLoading={offerStatus === FETCH_STATUS.IDLE || offerStatus === FETCH_STATUS.LOADING}
          offer={singleOffer}
          offerErrors={offerErrors}
          agreementTargets={targets}
          agreementTypes={types}
          onRefresh={() => actions.getSingleOfferAction(match?.params?.id)}
          onSaveEditedOffer={actions.editOfferAction}
          seriesOptions={seriesOptions}
          isViewMode={!isUserPermitted(PERMISSIONS.EDIT_OFFER)}
        />
      </div>
    );
  }
}

Main.propTypes = {
  actions: shape({}),
  singleOffer: shape({}),
  offerStatus: string,
};

Main.defaultProps = {
  actions: {},
  singleOffer: {},
  offerStatus: '',
};

export default Main;
