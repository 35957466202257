import {
  ACCEPT_ORDER_INIT,
  ACCEPT_ORDER_FAILURE,
  ACCEPT_ORDER_SUCCESS,
  GET_SINGLE_ORDER_SUCCESS,
} from '@store/action-types/orders';
import client from '@api-sdk/client';

const acceptOrderInit = () => ({ type: ACCEPT_ORDER_INIT });
const acceptOrderSuccess = order => ({ type: ACCEPT_ORDER_SUCCESS, payload: { order } });
const acceptOrderFailure = error => ({ type: ACCEPT_ORDER_FAILURE, payload: { error } });

const getSingleOrderSuccess = order => ({ type: GET_SINGLE_ORDER_SUCCESS, payload: { order } });

const acceptOrder = orderId => async dispatch => {
  dispatch(acceptOrderInit());

  try {
    const acceptOrderResponse = await client.acceptOrder({ slugs: [orderId, 'accept'] });

    await dispatch(acceptOrderSuccess(acceptOrderResponse.response));
    await dispatch(getSingleOrderSuccess(acceptOrderResponse.response));
  } catch (error) {
    dispatch(acceptOrderFailure(error));
  }
};

export default acceptOrder;
