const PERMISSIONS = {
  AGREEMENT_CREATE: 'admin:agreement:create',
  AGREEMENT_DELETE: 'admin:agreement:delete',
  AGREEMENT_EDIT: 'admin:agreement:edit',
  GET_AGREEMENT_BY_ID: 'admin:agreement:get',
  GET_AGREEMENTS_LIST: 'admin:agreement:list',
  EMAIL_TEMPLATE_EDIT: 'admin:email-template:edit',
  GET_EMAIL_TEMPLATE_BY_ID: 'admin:email-template:get	',
  EMAIL_TEMPLATE_GET_CONFIG: 'admin:email-template:get-config',
  GET_EMAIL_TEMPLATE_LIST: 'admin:email-template:list',
  EMAIL_TEMPLATE_TEST_TEMPLATE: 'admin:email-template:test-template',
  FAQ_CREATE: 'admin:faq:create',
  FAQ_DELETE: 'admin:faq:delete',
  FAQ_EDIT: 'admin:faq:edit',
  GET_FAQ_LIST: 'admin:faq:get',
  GET_FAQ_BY_ID: 'admin:faq:list',
  FEATURED_OFFER_EDIT: 'admin:featured-offer:edit',
  GET_FEATURED_OFFER_BY_ID: 'admin:featured-offer:get',
  GET_FEATURED_OFFERS_LIST: 'admin:featured-offer:list',
  GET_INVESTMENT_BY_ID: 'admin:investment:get',
  GET_INVESTMENT_LIST: 'admin:investment:list',
  EDIT_ORDER: 'admin:order:edit',
  ACCEPT_ORDER: 'admin:order:accept',
  REJECT_ORDER: 'admin:order:reject',
  DOWNLOAD_ORDER_SPREAD_SHEET: 'admin:order:download-spread-sheet',
  GET_ORDER_BY_ID: 'admin:order:get',
  GET_ORDER_LIST: 'admin:order:list',
  PAGE_CREATE: 'admin:page:create',
  PAGE_DELETE: 'admin:page:delete',
  PAGE_EDIT: 'admin:page:edit',
  GET_PAGE_BY_ID: 'admin:page:get',
  GET_PAGE_LIST: 'admin:page:list',
  PERMISSION_CREATE: 'admin:permission:create',
  PERMISSION_DELETE: 'admin:permission:delete',
  PERMISSION_EDIT: 'admin:permission:edit',
  GET_PERMISSION_BY_ID: 'admin:permission:get',
  GET_PERMISSION_LIST: 'admin:permission:list',
  ADD_PERMISSION_TO_ROLE: 'admin:role:add-permission',
  ADD_USER_TO_ROLE: 'admin:role:add-user',
  ROLE_CREATE: 'admin:role:create',
  ROLE_DELETE: 'admin:role:delete',
  ROLE_EDIT: 'admin:role:edit',
  GET_ROLE_BY_ID: 'admin:role:get',
  GET_PERMISSIONS_ASSIGNED_TO_ROLE: 'admin:role:get-permissions',
  GET_USERS_ASSIGNED_TO_ROLE: 'admin:role:get-users',
  GET_ROLE_LIST: 'admin:role:list',
  REMOVE_PERMISSION_FROM_ROLE: 'admin:role:remove-permission',
  REMOVE_USER_FROM_ROLE: 'admin:role:remove-user',
  GET_SERIES_LIST: 'admin:series:list',
  GET_SERIES_BY_ID: 'admin:series:get',
  CREATE_SERIES: 'admin:series:create',
  DELETE_SERIES: 'admin:series:delete',
  EDIT_SERIES: 'admin:series:edit',
  SLIDER_CREATE: 'admin:slider:create',
  SLIDER_DELETE: 'admin:slider:delete',
  SLIDER_EDIT: 'admin:slider:edit',
  GET_SLIDER_BY_ID: 'admin:slider:get',
  GET_SLIDER_LIST: 'admin:slider:list',
  STATIC_CONTENT_EDIT_ELEMENT: 'admin:static-content:edit',
  GET_STATIC_CONTENT_ELEMENT_BY_ID: 'admin:static-content:get',
  GET_STATIC_CONTENT_LIST: 'admin:static-content:list',
  GET_TRANSACTION_LIST: 'admin:transaction:list',
  GET_TRANSACTION_BY_ID: 'admin:transaction:get',
  ACCEPT_TRANSACTION: 'admin:transaction:accept',
  EXTEND_TRANSACTION: 'admin:transaction:extend',
  USER_CREATE: 'admin:user:create',
  USER_DELETE: 'admin:user:delete',
  USER_EDIT: 'admin:user:edit',
  GET_USER_BY_ID: 'admin:user:get',
  GET_PERSONAL_PERMISSIONS: 'admin:user:get-personal-permissions',
  GET_USER_ROLES: 'admin:user:get-roles',
  GET_USER_LIST: 'admin:user:list',
  GET_USER_AGREEMENTS: 'admin:user:list-agreements',
  ADD_PERSONAL_PERMISSION_TO_USER: 'admin:user:add-personal-permission',
  CREATE_OFFER: 'admin:offer:create',
  DELETE_OFFER: 'admin:offer:delete',
  EDIT_OFFER: 'admin:offer:edit',
  GET_OFFER_LIST: 'admin:offer:list',
  GET_OFFER_BY_ID: 'admin:offer:get',
  MANUAL_ORDER: 'admin:investment:manual-order',
};

export default PERMISSIONS;
