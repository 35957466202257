import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import AgreementsForm from '@screens/agreements/components/AgreementsForm';

const Main = ({ createStatus, errors, actions, types, targets, typeStatus }) => {
  const AgreementModel = {
    active: false,
    required: false,
    name: {
      en: '',
      pl: '',
    },
    content: {
      en: '',
      pl: '',
    },
    target: '',
    type: '',
  };

  useMountWithTranslate(() => {
    actions.getTypesAndTargetsAction();
  });

  return (
    <AgreementsForm
      types={types}
      targets={targets}
      isCreateMode
      isLoading={createStatus === FETCH_STATUS.IDLE || typeStatus === FETCH_STATUS.IDLE}
      agreement={AgreementModel}
      agreementErrors={errors}
      onCreateAgreement={actions.createAgreementAction}
    />
  );
};

Main.propTypes = {
  actions: shape({ createAgreementAction: func }).isRequired,
  errors: shape({}),
  types: arrayOf(shape({})),
  targets: arrayOf(shape({})),
  typeStatus: string,
  createStatus: string,
};

Main.defaultProps = {
  errors: {},
  types: [],
  targets: [],
  typeStatus: undefined,
  createStatus: undefined,
};

export default Main;
