import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getAllOrders,
  getAllOrdersError,
  getAllOrdersQueryParams,
  getAllOrdersStatus,
} from '@store/reducers/orders/selectors';
import { getOrdersAction, downloadSpreadsheetAction } from '@store/actions/orders';
import { checkPermissionAction, setIsPermissionCheckedAction } from '@store/actions/check-permission';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  allOrders: getAllOrders(state),
  queryParams: getAllOrdersQueryParams(state),
  allOrdersError: getAllOrdersError(state),
  allOrdersStatus: getAllOrdersStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
  isPermissionChecked: getIsPermissionChecked(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getOrdersAction,
      downloadSpreadsheetAction,
      checkPermissionAction,
      setIsPermissionCheckedAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
