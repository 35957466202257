import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getSingleOrder,
  getSingleOrderStatus,
  getSingleOrderError,
  getSingleOrderInputErrors,
} from '@store/reducers/orders/selectors';
import {
  getSingleOrderAction,
  rejectOrderAction,
  acceptOrderAction,
  editOrderAction,
  downloadSpreadsheetAction,
  removeSignedDocumentAction,
} from '@store/actions/orders';
import { getCountriesAction } from '@store/actions/investors';
import { getOfferDocumentsAction } from '@store/actions/offers';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import { getInvestorDocuments, getDocumentsStatus } from '@store/reducers/offers/selectors';
import { getCountries } from '@store/reducers/investors/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  order: getSingleOrder(state),
  orderStatus: getSingleOrderStatus(state),
  orderError: getSingleOrderError(state),
  inputOrderErrors: getSingleOrderInputErrors(state),
  countries: getCountries(state),
  permittedPermissions: getPermittedPermissions(state),
  investorDocuments: getInvestorDocuments(state),
  documentsStatus: getDocumentsStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      acceptOrderAction,
      getSingleOrderAction,
      editOrderAction,
      rejectOrderAction,
      getCountriesAction,
      downloadSpreadsheetAction,
      getOfferDocumentsAction,
      removeSignedDocumentAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
