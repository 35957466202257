import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  label: {
    width: 'fit-content',
    display: 'flex !important',
  },
}));

export default useStyles;
