import { FRONTEND_SET_PASSWORD_URL_PATTERN } from '@api-sdk/consts';

const ManualInvestingModel = {
  email: '',
  offer: '',
  series: '',
  quantity: 1,
  investorData: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: {
      address: '',
      zip: '',
      city: '',
      state: '',
      country: '',
    },
    bankAccount: {
      country: '',
      bankName: '',
      accountNumber: '',
    },
  },
  thankYouPageUrlPattern: FRONTEND_SET_PASSWORD_URL_PATTERN,
  resetPasswordUrlPattern: `${FRONTEND_SET_PASSWORD_URL_PATTERN}/pl/ustaw-haslo/:resetPasswordToken`,
};

const ManualInvestingAgreementsModel = [];

export { ManualInvestingModel, ManualInvestingAgreementsModel };
