const getAllInvestments = state => state?.investments?.allInvestments;
const getAllInvestmentsQueryParams = state => state?.investments?.queryParams;
const getAllInvestmentsStatus = state => state?.investments?.allInvestmentsStatus;
const getAllInvestmentsError = state => state?.investments?.allInvestmentsError;

const getSingleInvestment = state => state?.investments?.singleInvestment;
const getSingleInvestmentStatus = state => state?.investments?.singleInvestmentStatus;
const getSingleInvestmentError = state => state?.investments?.singleInvestmentError;

export {
  getAllInvestments,
  getAllInvestmentsStatus,
  getAllInvestmentsError,
  getSingleInvestment,
  getSingleInvestmentStatus,
  getSingleInvestmentError,
  getAllInvestmentsQueryParams,
};
