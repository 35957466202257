import React, { useEffect, useState } from 'react';
import { arrayOf, shape } from 'prop-types';
import clsx from 'clsx';
import { Table as SemanticTable, Pagination } from 'semantic-ui-react';
import { useTable } from 'react-table';
import { isEmpty } from 'lodash';
import useStyles from './styles';

const SimpleTable = ({ columns = [], data = [] }) => {
  const [visibleData, setVisibleData] = useState([]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: visibleData });
  const styles = useStyles();
  const totalPages = Math.ceil(data?.length / 10);

  useEffect(() => {
    if (isEmpty(visibleData)) {
      setVisibleData(data?.filter((item, i) => i < 10));
    }
  }, [columns]);

  const handlePageChange = (e, paginationData) =>
    setVisibleData(
      data?.filter((item, i) => i <= paginationData?.activePage * 10 && i > (paginationData?.activePage - 1) * 10),
    );

  return (
    <div className="table-responsive">
      <SemanticTable padded {...getTableProps}>
        <SemanticTable.Header>
          {headerGroups.map(headerGroup => (
            <SemanticTable.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <SemanticTable.HeaderCell {...column.getHeaderProps({ className: column.className })}>
                  {column.render('Header')}
                </SemanticTable.HeaderCell>
              ))}
            </SemanticTable.Row>
          ))}
        </SemanticTable.Header>
        <SemanticTable.Body {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <SemanticTable.Row key={`row-${row.id}`}>
                {row.cells.map(cell => {
                  return (
                    <SemanticTable.Cell
                      key={`cell-${cell?.row?.original?.id}`}
                      {...cell.getCellProps({ className: clsx(styles.tableCell, cell.column.className) })}
                    >
                      {cell.render('Cell')}
                    </SemanticTable.Cell>
                  );
                })}
              </SemanticTable.Row>
            );
          })}
        </SemanticTable.Body>
      </SemanticTable>
      {totalPages > 1 ? (
        <div className="d-flex justify-content-center my-5">
          <Pagination totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
      ) : null}
    </div>
  );
};

SimpleTable.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
};

export default SimpleTable;
