import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Grid, Popup, Button, Message, Icon } from 'semantic-ui-react';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, Table } from '@shared/components';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { mapErrorCodes } from '@screens/transactions/utils';
import mapTransactionStatus from '@shared/utils/mapTransactionStatus';
import { arrayOf, shape, string, func } from 'prop-types';
import { Typography } from '@material-ui/core';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';

const Main = ({
  actions,
  allTransactions,
  status,
  transactionListError,
  queryParams,
  permittedPermissions,
  checkPermissionStatus,
  isPermissionChecked,
}) => {
  const [t] = useTranslation(TRANSLATIONS.TRANSACTIONS);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;
  const refreshPage = () => actions.getTransactionsAction();

  useMountWithTranslate(() => {
    actions.getTransactionsAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.TRANSACTIONS]) {
      if (
        !isUserPermitted(PERMISSIONS.GET_TRANSACTION_BY_ID) ||
        !isUserPermitted(PERMISSIONS.ACCEPT_TRANSACTION) ||
        !isUserPermitted(PERMISSIONS.EXTEND_TRANSACTION)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.TRANSACTIONS);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.GET_TRANSACTION_BY_ID) && PERMISSIONS.GET_TRANSACTION_BY_ID,
          !isUserPermitted(PERMISSIONS.ACCEPT_TRANSACTION) && PERMISSIONS.ACCEPT_TRANSACTION,
          !isUserPermitted(PERMISSIONS.EXTEND_TRANSACTION) && PERMISSIONS.EXTEND_TRANSACTION,
        ]);
      }
    }
  }, [permittedPermissions]);

  const columns = useMemo(
    () => [
      {
        Header: t('list.id'),
        accessor: 'id',
        columnName: 'id',
      },
      {
        Header: t('list.user'),
        accessor: 'userMeta',
        columnName: 'userEmail',
        columnOrder: 'userEmail',
        Cell: ({ row }) => {
          const Username =
            row?.original?.meta?.user?.isDeleted || !isUserPermitted(PERMISSIONS.GET_USER_BY_ID) ? Typography : Link;

          return (
            <div className="d-flex align-items-center">
              <Username to={`/users/${row?.original?.meta?.user?.id}`} className="mr-1">
                {`${row?.original?.meta?.user?.firstName || ''} ${row?.original?.meta?.user?.lastName || ''} ${
                  row?.original?.meta?.user?.email ? `(${row?.original?.meta?.user?.email})` : ''
                }`}
              </Username>
              {row?.original?.meta?.user?.isDeleted && (
                <Popup basic content="User deleted" trigger={<Icon name="warning sign" color="yellow" />} />
              )}
            </div>
          );
        },
      },
      {
        Header: t('list.date'),
        accessor: 'date',
        columnName: 'date',
        columnOrder: 'date',
      },
      {
        Header: t('list.amount'),
        accessor: 'amount',
        columnOrder: 'amount',
      },
      {
        Header: t('list.status'),
        accessor: 'status',
        columnName: 'status',
        Cell: ({ row }) => {
          return <div>{mapTransactionStatus({ status: row?.original?.status, t })}</div>;
        },
      },
      {
        Header: t('list.type'),
        accessor: 'type',
        columnName: 'type',
        Cell: ({ row }) => <div>{t(row?.original?.type)}</div>,
      },
      {
        Header: t('list.method'),
        accessor: 'method',
        columnName: 'method',
        Cell: ({ row }) => <div>{t(row?.original?.method)}</div>,
      },
      {
        Header: t('list.meta'),
        accessor: 'meta',
        className: 'text-center',
        Cell: ({ row }) => {
          return (
            <div className="meta-popup">
              <Popup
                style={{ minWidth: '500px' }}
                wide
                position="top right"
                trigger={<Button content={t('actionButtons.meta')} />}
                on="click"
              >
                <Grid container className="meta-popup--grid">
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('transactionInfo.waitUntil')}:</b>{' '}
                      {row?.original?.waitUntil ? row?.original?.waitUntil : '-'}
                    </p>
                  </Grid.Row>
                  <Grid.Row>
                    <p>
                      <b>{t('transactionMeta.investmentId')}:</b> {row.original?.meta?.investmentId}
                    </p>
                  </Grid.Row>
                  <Grid.Row>
                    <p>
                      <b>{t('transactionMeta.offerId')}:</b> {row.original?.meta?.offerId}
                    </p>
                  </Grid.Row>
                  <Grid.Row>
                    <p>
                      <b>{t('transactionMeta.offerTitle')}:</b> {row.original?.meta?.offerTitle}
                    </p>
                  </Grid.Row>
                  <Grid.Row>
                    <p>
                      <b>{t('transactionMeta.sharesQuantity')}:</b> {row.original?.meta?.sharesQuantity}
                    </p>
                  </Grid.Row>
                  <Grid.Row>
                    <p>
                      <b>{t('transactionMeta.orderId')}:</b> {row.original?.meta?.orderId}
                    </p>
                  </Grid.Row>
                  <Grid.Row>
                    <div className="pt-4">
                      {isUserPermitted(PERMISSIONS.GET_ORDER_BY_ID) ? (
                        <Button as={Link} to={`/orders/${row.original?.meta?.orderId}/edit`}>
                          {t('actionButtons.viewOrder')}
                        </Button>
                      ) : null}

                      {isUserPermitted(PERMISSIONS.GET_INVESTMENT_BY_ID) ? (
                        <Button as={Link} to={`/investments/${row.original?.meta?.investmentId}/edit`}>
                          {t('actionButtons.viewInvestment')}
                        </Button>
                      ) : null}
                    </div>
                  </Grid.Row>
                </Grid>
              </Popup>
            </div>
          );
        },
      },
      {
        Header: t('list.edit'),
        className: 'text-center',
        Cell: ({ row }) => {
          return isUserPermitted(PERMISSIONS.GET_TRANSACTION_BY_ID) ? (
            <Button primary as={Link} to={`/transactions/${row.original?.id}/edit`}>
              {t('actionButtons.edit')}
            </Button>
          ) : null;
        },
      },
      {
        Header: t('list.toAccept'),
        className: 'text-center',
        Cell: ({ row }) => {
          return row?.original?.meta?.canAccept && isUserPermitted(PERMISSIONS.ACCEPT_TRANSACTION) ? (
            <Button
              color="green"
              disabled={!row?.original?.meta?.canAccept}
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                if (confirm(`${t('actionButtons.acceptDisclaimer')} ${row.original?.id}?`)) {
                  actions.acceptTransactionAction(row.original?.id);
                }
              }}
            >
              {t('actionButtons.toAccept')}
            </Button>
          ) : (
            <div style={{ textAlign: 'center' }}>-</div>
          );
        },
      },
    ],
    [t, permittedPermissions],
  );

  const renderTable = () => {
    return (
      <Table
        className="table-responsive"
        isLoading={status === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
        data={allTransactions}
        columns={columns}
        queryParams={queryParams}
        searchAction={actions.getTransactionsAction}
        reloadAction={refreshPage}
        notFoundMessage={<Message>{t('errors.notFoundTransactions')}</Message>}
      />
    );
  };

  return (
    <Card width="100%" className="admin-transactions">
      <CardHeader>
        <CardTitle title={t('title')} />
        <CardToolbar>
          <Button color="black" disabled={status === FETCH_STATUS.LOADING} onClick={refreshPage}>
            {t('actionButtons.reload')}
          </Button>
        </CardToolbar>
      </CardHeader>
      {transactionListError && mapErrorCodes({ errorCode: transactionListError, t })}
      <CardBody transparent>{renderTable()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({
    getInvestorsAction: func,
  }),
  allTransactions: arrayOf(shape({})),
  row: shape({}),
  status: string,
  transactionListError: shape({}),
  queryParams: shape({}),
  permittedPermissions: arrayOf(shape({})),
  checkPermissionStatus: string,
  isPermissionChecked: shape({}),
};
Main.defaultProps = {
  actions: {},
  allTransactions: [],
  row: {},
  transactionListError: undefined,
  status: undefined,
  queryParams: {},
  permittedPermissions: [],
  checkPermissionStatus: undefined,
  isPermissionChecked: {},
};

export default Main;
