import React, { useEffect, useState } from 'react';
import { bool, func, shape } from 'prop-types';
import { Button, Divider, Form, Grid, Segment } from 'semantic-ui-react';
import {
  InputField,
  SwitchField,
  TranslatableTextField,
  TranslatableRichTextField,
} from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { isEmpty } from 'lodash';
import { Container } from '@material-ui/core';
import useStyles from './styles';

const FaqForm = ({ isCreateMode, isLoading, onRefreshFaq, onSaveFaq, onCreateFaq, faqErrors, faq, isViewMode }) => {
  const [t] = useTranslation(TRANSLATIONS.FAQ);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);
  const [faqData, setFaqData] = useState(faq);
  const styles = useStyles();

  useEffect(() => {
    isEmpty(faqData) && setFaqData(faq);
  }, [faq]);

  const onChangeContent = (e, { name, value }) => {
    setFaqData({
      ...faqData,
      [name]: value,
    });
  };

  const onSwitchChange = (e, { name }) => {
    setFaqData(prevState => ({
      ...prevState,
      [name]: !prevState?.[name],
    }));
  };

  const onChangeTranslatableContent = (e, { name, value, language }) => {
    setFaqData(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));
  };

  const renderMenuButtons = () => (
    <Grid columns={2}>
      <Grid.Column>
        <h2>{isCreateMode ? t('addFaq') : t('editFaq')}</h2>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button style={{ marginRight: '5px' }} as={Link} type="button" to={ROUTES.FAQ}>
          {t('actionButtons.goBack')}
        </Button>
        {!isCreateMode && (
          <Button
            disabled={isLoading}
            type="submit"
            secondary
            onClick={() => onRefreshFaq()}
            style={{ marginRight: '5px' }}
          >
            {t('actionButtons.reload')}
          </Button>
        )}

        {!isCreateMode && !isViewMode && (
          <Button
            style={{ marginRight: '5px' }}
            disabled={isLoading}
            variant="outlined"
            primary
            onClick={() => onSaveFaq({ faqData })}
          >
            {t('actionButtons.save')}
          </Button>
        )}

        {!isCreateMode && !isViewMode && (
          <Button
            disabled={isLoading}
            variant="outlined"
            color="facebook"
            onClick={() => onSaveFaq({ faqData, saveAndBack: true })}
          >
            {t('actionButtons.saveAndBack')}
          </Button>
        )}

        {isCreateMode && (
          <Button
            style={{ marginRight: '5px' }}
            disabled={isLoading}
            variant="outlined"
            primary
            onClick={() => onCreateFaq({ faqData })}
          >
            {t('actionButtons.add')}
          </Button>
        )}

        {isCreateMode && (
          <Button
            disabled={isLoading}
            variant="outlined"
            color="facebook"
            onClick={() => onCreateFaq({ faqData, saveAndBack: true })}
          >
            {t('actionButtons.addAndBack')}
          </Button>
        )}
      </Grid.Column>
    </Grid>
  );

  return (
    <Container maxWidth="md">
      {renderMenuButtons()}
      <Segment className={styles.segment}>
        <Form loading={isLoading}>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <InputField
                disabled={isViewMode}
                icon="numbered list"
                label={t('form.position')}
                name="position"
                onChange={onChangeContent}
                value={faqData?.position}
                errorMessage={error(faqErrors?.position)}
                labelClassName={styles.label}
              />
              <Grid.Column className="d-flex justify-content-around align-items-center">
                <SwitchField
                  disabled={isViewMode}
                  tooltip={t('tooltip.isFeatured')}
                  className="text-center"
                  name="isFeatured"
                  value={faqData?.isFeatured}
                  label={t('form.isFeatured')}
                  onChange={onSwitchChange}
                />
                <SwitchField
                  disabled={isViewMode}
                  onChange={onSwitchChange}
                  className="text-center"
                  name="active"
                  value={faqData?.active}
                  label={t('form.active')}
                />
              </Grid.Column>
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="question"
                label={t('form.question')}
                value={faqData?.question}
                errorMessage={error(faqErrors?.question)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableRichTextField
                disabled={isViewMode}
                label={t('form.answer')}
                name="answer"
                errorMessage={error(faqErrors?.answer)}
                value={faqData?.answer}
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      {renderMenuButtons()}
    </Container>
  );
};

FaqForm.propTypes = {
  isCreateMode: bool,
  isLoading: bool,
  onRefreshFaq: func,
  onSaveFaq: func,
  onCreateFaq: func,
  faqErrors: shape({}),
  faq: shape({}),
  isViewMode: bool,
};

FaqForm.defaultProps = {
  isCreateMode: false,
  isLoading: false,
  onRefreshFaq: () => {},
  onSaveFaq: () => {},
  onCreateFaq: () => {},
  faqErrors: {},
  faq: {},
  isViewMode: false,
};

export default FaqForm;
