import {
  DOWNLOAD_SPREADSHEET_INIT,
  DOWNLOAD_SPREADSHEET_SUCCESS,
  DOWNLOAD_SPREADSHEET_FAILURE,
} from '@store/action-types/orders';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { getUserAccessTokenFromStorage } from '@api-sdk/utils';

const downloadSpreadsheetInit = () => ({ type: DOWNLOAD_SPREADSHEET_INIT });
const downloadSpreadsheetSuccess = spreadsheet => ({ type: DOWNLOAD_SPREADSHEET_SUCCESS, payload: { spreadsheet } });
const downloadSpreadsheetFailure = error => ({ type: DOWNLOAD_SPREADSHEET_FAILURE, payload: { error } });

const downloadSpreadsheet = ({ url, orderId }) => async dispatch => {
  dispatch(downloadSpreadsheetInit());
  const userAccessToken = getUserAccessTokenFromStorage();

  try {
    const downloadSpreadSheetResponse = await axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    }).then(response => {
      FileDownload(response.data, `${orderId}.xlsx`);
    });

    await dispatch(downloadSpreadsheetSuccess());
  } catch (error) {
    dispatch(downloadSpreadsheetFailure(error));
  }
};

export default downloadSpreadsheet;
