import React from 'react';
import { element, func } from 'prop-types';

const DropdownTopbarItemToggler = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <div
      ref={ref}
      className="topbar-item"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  );
});

DropdownTopbarItemToggler.propTypes = {
  onClick: func,
  children: element.isRequired,
};

DropdownTopbarItemToggler.defaultProps = {
  onClick: () => {},
};

DropdownTopbarItemToggler.displayName = 'DropdownTopbarItemToggler';

export default DropdownTopbarItemToggler;
