import React from 'react';
import { func, shape } from 'prop-types';
import { Grid, Label } from 'semantic-ui-react';
import { InputField } from '@shared/components/translatable';
import { SwitchField } from '@screens/offers/NewOfferForm/components';
import { SOCIAL_MEDIA_ICON } from '@screens/settings/components/SocialMedia/consts';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useStyles from './styles';

const SocialItem = ({ social, setConfigData, configData }) => {
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.SETTINGS);

  const onSwitchChange = () => {
    const array = [...configData?.socialMediaIcons];
    const findSocial = array.find(item => item.id === social.id);
    findSocial.isEnabled = !findSocial.isEnabled;
    setConfigData(prevState => ({
      ...prevState,
      socialMediaIcons: array,
    }));
  };

  const handleChange = (e, { name, value }) => {
    const array = [...configData?.socialMediaIcons];
    const findSocial = array.find(item => item.id === social.id);
    findSocial[name] = value;
    setConfigData(prevState => ({
      ...prevState,
      socialMediaIcons: array,
    }));
  };

  const mapSocialMediaIcon = icon => {
    switch (icon) {
      case SOCIAL_MEDIA_ICON.FACEBOOK: {
        return <FacebookIcon className={styles.icon} />;
      }

      case SOCIAL_MEDIA_ICON.TWITTER: {
        return <TwitterIcon className={styles.icon} />;
      }

      case SOCIAL_MEDIA_ICON.INSTAGRAM: {
        return <InstagramIcon className={styles.icon} />;
      }

      case SOCIAL_MEDIA_ICON.LINKEDIN: {
        return <LinkedInIcon className={styles.icon} />;
      }

      case SOCIAL_MEDIA_ICON.YOUTUBE: {
        return <YouTubeIcon className={styles.icon} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Grid.Column>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center" style={{ marginTop: 18 }}>
          {mapSocialMediaIcon(social.icon)}
          <Label className={styles.label}>{social.name}</Label>
        </div>
        <SwitchField name="isEnabled" value={social.isEnabled} label={t('active')} onChange={onSwitchChange} />
      </div>
      <div>
        <InputField name="position" onChange={handleChange} icon="list" value={social.position} label={t('position')} />
        <InputField name="url" onChange={handleChange} icon="font" value={social.url} label={t('url')} />
      </div>
    </Grid.Column>
  );
};

SocialItem.propTypes = {
  social: shape({}),
  configData: shape({}),
  setConfigData: func,
};

SocialItem.defaultProps = {
  social: {},
  configData: {},
  setConfigData: () => {},
};

export default SocialItem;
