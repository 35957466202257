import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSeriesAction, deleteSeriesAction } from '@store/actions/series';
import { getAllSeries, getSeriesStatus, getAllSeriesQueryParams } from '@store/reducers/series/selectors';
import {
  getPermittedPermissions,
  getPermissionCheckStatus,
  getIsPermissionChecked,
} from '@store/reducers/check-permission/selectors';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import Main from './Main';

const mapStateToProps = state => ({
  allSeries: getAllSeries(state),
  queryParams: getAllSeriesQueryParams(state),
  seriesStatus: getSeriesStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
  isPermissionChecked: getIsPermissionChecked(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSeriesAction,
      deleteSeriesAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
