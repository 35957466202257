import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  segment: {
    padding: '0 !important',
  },
  infoColumn: {
    padding: '16px 0 0 !important',
  },
  tabLoader: {
    margin: 0,
    marginLeft: 10,
    height: '12px !important',
    width: '12px !important',
  },
}));

export default useStyles;
