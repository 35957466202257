const getAllOffers = state => state?.offers?.allOffers;
const getOfferStatus = state => state?.offers?.status;
const getSingleOffer = state => state?.offers?.singleOffer;
const getOfferErrors = state => state?.offers?.error?.errors;
const getAllOffersQueryParams = state => state?.offers?.queryParams;
const getFeaturedOffersSelectOptions = state =>
  state?.offers?.allOffers?.map(offer => ({ key: offer?.id, value: offer?.id, text: offer?.title }));
const getOffersOptions = state =>
  state?.offers?.allOffers?.map(offer => ({ key: offer?.id, value: offer?.id, text: offer?.title }));
const getOfferOptionsForInvesting = state => ({
  agreements: state?.offers?.singleOffer?.agreements,
  series: state?.offers?.singleOffer?.series,
});
const getInvestorDocuments = state => state?.offers?.investorDocuments;
const getDocumentsStatus = state => state?.offers?.documentsStatus;

export {
  getAllOffers,
  getOfferStatus,
  getSingleOffer,
  getOfferErrors,
  getAllOffersQueryParams,
  getFeaturedOffersSelectOptions,
  getOffersOptions,
  getOfferOptionsForInvesting,
  getInvestorDocuments,
  getDocumentsStatus,
};
