import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  languageIcon: {
    marginRight: theme.spacing(1),
    maxWidth: '100%',
  },
  languageButton: {
    background: 'none',
    border: 'none',
    width: '100%',
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',

    '&:active': {
      background: '#F3F6F9',
    },
  },
}));

export default useStyles;
