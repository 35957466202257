const getAllOrders = state => state?.orders?.allOrders;
const getAllOrdersStatus = state => state?.orders?.allOrdersStatus;
const getAllOrdersQueryParams = state => state?.orders?.queryParams;
const getAllOrdersError = state => state?.orders?.allOrdersError;

const getSingleOrder = state => state?.orders?.singleOrder;
const getSingleOrderStatus = state => state?.orders?.singleOrderStatus;
const getSingleOrderError = state => state?.orders?.singleOrderError;
const getSingleOrderInputErrors = state => state?.orders?.singleOrderInputErrors;

const getCreateOrderErrors = state => state?.orders?.createOrderErrors;

export {
  getAllOrders,
  getAllOrdersError,
  getAllOrdersStatus,
  getSingleOrder,
  getSingleOrderError,
  getSingleOrderStatus,
  getSingleOrderInputErrors,
  getAllOrdersQueryParams,
  getCreateOrderErrors,
};
