import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getAllInvestments,
  getAllInvestmentsError,
  getAllInvestmentsQueryParams,
  getAllInvestmentsStatus,
} from '@store/reducers/investments/selectors';
import { getInvestmentsAction } from '@store/actions/investments';
import { getPermittedPermissions, getIsPermissionChecked } from '@store/reducers/check-permission/selectors';
import { checkPermissionAction, setIsPermissionCheckedAction } from '@store/actions/check-permission';
import Main from './Main';

const mapStateToProps = state => ({
  allInvestments: getAllInvestments(state),
  queryParams: getAllInvestmentsQueryParams(state),
  allInvestmentsError: getAllInvestmentsError(state),
  allInvestmentsStatus: getAllInvestmentsStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getInvestmentsAction,
      checkPermissionAction,
      setIsPermissionCheckedAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
