import React, { useRef } from 'react';
import { Card, Message, Segment, Icon, Button, Form, Checkbox } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import './styles.scss';

const OfferAttachments = ({ isLoading, offerAttachments, onChange, offerErrors, isViewMode }) => {
  const inputFileRef = useRef(null);
  const [t] = useTranslation(TRANSLATIONS.OFFERS);

  const toBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          file: { base64: reader.result, fileName: file.name, delete: false },
          displayName: file.name,
          name: file.name,
          type: file.type,
          size: file.size,
        });
      reader.onerror = error => reject(error);
    });
  };

  const toBase64Handler = async files => {
    const filePathsPromises = [];
    files.forEach(file => {
      filePathsPromises.push(toBase64(file));
    });
    return Promise.all(filePathsPromises);
  };

  const onUploadAttachment = async e => {
    const files = Array.from(e.target.files);
    if (files?.length > 0) {
      const base64Files = await toBase64Handler(files);
      const lastAttachmentBackendIndex =
        offerAttachments?.length > 0
          ? offerAttachments?.sort?.((a, b) => a.backendIndex - b.backendIndex)?.[offerAttachments?.length - 1]
              ?.backendIndex + 1
          : 0;
      const lastAttachmentPosition =
        offerAttachments?.length > 0
          ? offerAttachments?.sort((a, b) => a.position - b.position)?.[offerAttachments?.length - 1]?.position + 1
          : 1;

      const mappedFiles = base64Files.map((file, index) => ({
        ...file,
        position: lastAttachmentPosition + index,
        backendIndex: lastAttachmentBackendIndex + index,
        signable: false,
        notifyInvestors: false,
      }));

      onChange(prevState => ({
        offerAttachments: prevState.offerAttachments.concat(mappedFiles),
      }));
    }
  };

  const removeFile = document => {
    const documentToRemoveBackendIndex = document.backendIndex;
    const isDocumentUploaded = document.url && document.id;

    onChange(prevState => ({
      offerAttachments: prevState.offerAttachments.reduce((acc, attachment) => {
        if (attachment.backendIndex === documentToRemoveBackendIndex) {
          if (isDocumentUploaded) {
            return acc.concat([{ ...attachment, file: { ...attachment.file, delete: true } }]);
          }
          return acc;
        }
        return acc.concat([attachment]);
      }, []),
    }));
  };

  const undoRemoveFile = document => {
    const documentToRemoveBackendIndex = document.backendIndex;

    onChange(prevState => ({
      offerAttachments: prevState.offerAttachments.reduce((acc, attachment) => {
        if (attachment.backendIndex === documentToRemoveBackendIndex) {
          return acc.concat([{ ...attachment, file: { ...attachment.file, delete: false } }]);
        }
        return acc.concat([attachment]);
      }, []),
    }));
  };

  const toggleSignable = (document, checked) =>
    onChange(prevState => ({
      offerAttachments: prevState.offerAttachments.reduce((acc, attachment) => {
        if (attachment.backendIndex === document.backendIndex) {
          return acc.concat([{ ...attachment, signable: checked }]);
        }
        return acc.concat([attachment]);
      }, []),
    }));

  const toggleNotifyInvestors = (document, checked) =>
    onChange(prevState => ({
      offerAttachments: prevState.offerAttachments.reduce((acc, attachment) => {
        if (attachment.backendIndex === document.backendIndex) {
          return acc.concat([{ ...attachment, notifyInvestors: checked }]);
        }
        return acc.concat([attachment]);
      }, []),
    }));

  const toggleRequired = (document, checked) =>
    onChange(prevState => ({
      offerAttachments: prevState.offerAttachments.reduce((acc, attachment) => {
        if (attachment.backendIndex === document.backendIndex) {
          return acc.concat([{ ...attachment, required: checked }]);
        }
        return acc.concat([attachment]);
      }, []),
    }));

  return (
    <Form loading={isLoading}>
      <div className="offer-tab-attachments">
        {isViewMode ? null : (
          <Segment className="offer-tab-action-buttons">
            <Button primary onClick={() => inputFileRef?.current?.click?.()}>
              {t('actionButtons.uploadDocuments')}
            </Button>
            <p>{t('uploadDocumentsDisclaimer')}</p>
            <input multiple ref={inputFileRef} type="file" style={{ display: 'none' }} onChange={onUploadAttachment} />
          </Segment>
        )}

        {!isEmpty(offerErrors?.investorDocuments) && <Message color="red">{t('uploadDocumentsError')}</Message>}
        <Card.Group>
          {offerAttachments?.map?.(document => {
            return (
              <Card fluid>
                <Segment className="offer-tab-attachment">
                  <Icon size="big" color="black" name="file alternate outline" />
                  <span
                    className={document?.file?.delete ? 'attachment-marked-as-delete' : ''}
                  >{`${document.position}. `}</span>
                  {document?.url ? (
                    <a
                      href={document?.url}
                      rel="noreferrer"
                      target="_blank"
                      className={document?.file?.delete ? 'attachment-marked-as-delete' : ''}
                    >
                      {document?.displayName}
                    </a>
                  ) : (
                    <span>{document?.displayName}</span>
                  )}
                  {isViewMode ? null : (
                    <div className="offer-tab-checkbox-wrapper">
                      <Checkbox
                        onClick={(e, { checked }) => toggleSignable(document, checked)}
                        checked={document.signable}
                        label={t('signable')}
                      />
                      <Checkbox
                        onClick={(e, { checked }) => toggleNotifyInvestors(document, checked)}
                        checked={document.notifyInvestors}
                        label={t('notifyInvestors')}
                      />

                      {document.signable ? (
                        <Checkbox
                          onClick={(e, { checked }) => toggleRequired(document, checked)}
                          checked={document.required}
                          label={t('required')}
                        />
                      ) : null}
                    </div>
                  )}
                  {!document?.file?.delete && !isViewMode && (
                    <Button color="red" icon="trash alternate outline" onClick={() => removeFile(document)} />
                  )}
                  {document?.file?.delete && !isViewMode && (
                    <Button icon="undo" color="blue" onClick={() => undoRemoveFile(document)} />
                  )}
                </Segment>
              </Card>
            );
          })}
        </Card.Group>
      </div>
    </Form>
  );
};

export default OfferAttachments;
