import {
  ACCEPT_TRANSACTION_INIT,
  ACCEPT_TRANSACTION_SUCCESS,
  ACCEPT_TRANSACTION_FAILURE,
  GET_SINGLE_TRANSACTION_SUCCESS,
} from '@store/action-types/transactions';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const acceptTransactionInit = () => ({ type: ACCEPT_TRANSACTION_INIT });
const acceptTransactionSuccess = transaction => ({ type: ACCEPT_TRANSACTION_SUCCESS, payload: { transaction } });
const acceptTransactionFailure = error => ({ type: ACCEPT_TRANSACTION_FAILURE, payload: { error } });
const getSingleTransactionSuccess = transaction => ({ type: GET_SINGLE_TRANSACTION_SUCCESS, payload: { transaction } });

const acceptTransaction = transactionId => async dispatch => {
  dispatch(acceptTransactionInit());

  try {
    const acceptTransactionResponse = await client.acceptTransaction({ slugs: [transactionId, 'accept'] });

    await dispatch(acceptTransactionSuccess(acceptTransactionResponse.response));
    await dispatch(getSingleTransactionSuccess(acceptTransactionResponse.response));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('transaction.accept'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    await dispatch(acceptTransactionFailure(error?.errors?.[0]?.code));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('transaction.error.accept'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default acceptTransaction;
