import {
  EXTEND_TRANSACTION_INIT,
  EXTEND_TRANSACTION_SUCCESS,
  EXTEND_TRANSACTION_FAILURE,
  GET_SINGLE_TRANSACTION_SUCCESS,
} from '@store/action-types/transactions';
import client from '@api-sdk/client';
import moment from 'moment';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const extendTransactionInit = () => ({ type: EXTEND_TRANSACTION_INIT });
const extendTransactionSuccess = transaction => ({ type: EXTEND_TRANSACTION_SUCCESS, payload: { transaction } });
const extendTransactionFailure = error => ({ type: EXTEND_TRANSACTION_FAILURE, payload: { error } });
const getSingleTransactionSuccess = transaction => ({ type: GET_SINGLE_TRANSACTION_SUCCESS, payload: { transaction } });

const extendTransaction = ({ id, waitUntil }) => async dispatch => {
  dispatch(extendTransactionInit());

  try {
    const extendTransactionResponse = await client.extendTransaction({
      slugs: [id, 'extend'],
      data: { waitUntil: moment(waitUntil).format('YYYY-MM-DD HH:mm:ss') },
    });

    await dispatch(extendTransactionSuccess(extendTransactionResponse.response));
    await dispatch(getSingleTransactionSuccess(extendTransactionResponse.response));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('transaction.extend'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    await dispatch(extendTransactionFailure(error?.errors?.[0]?.code));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('transaction.error.extend'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default extendTransaction;
