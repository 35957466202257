import i18next from 'i18next';
import common_en from '@translations/en/common.json';
import common_pl from '@translations/pl/common.json';
import topbar_en from '@translations/en/topbar.json';
import topbar_pl from '@translations/pl/topbar.json';
import faq_en from '@translations/en/faq.json';
import faq_pl from '@translations/pl/faq.json';
import offers_en from '@translations/en/offers.json';
import offers_pl from '@translations/pl/offers.json';
import agreements_en from '@translations/en/agreements.json';
import agreements_pl from '@translations/pl/agreements.json';
import pages_en from '@translations/en/pages.json';
import pages_pl from '@translations/pl/pages.json';
import errors_en from '@translations/en/errors.json';
import errors_pl from '@translations/pl/errors.json';
import series_en from '@translations/en/series.json';
import series_pl from '@translations/pl/series.json';
import orders_en from '@translations/en/orders.json';
import orders_pl from '@translations/pl/orders.json';
import investments_en from '@translations/en/investments.json';
import investments_pl from '@translations/pl/investments.json';
import transactions_en from '@translations/en/transactions.json';
import transactions_pl from '@translations/pl/transactions.json';
import users_en from '@translations/en/users.json';
import users_pl from '@translations/pl/users.json';
import slider_en from '@translations/en/slider.json';
import slider_pl from '@translations/pl/slider.json';
import roles_en from '@translations/en/roles.json';
import roles_pl from '@translations/pl/roles.json';
import permissions_en from '@translations/en/permissions.json';
import permissions_pl from '@translations/pl/permissions.json';
import email_templates_en from '@translations/en/email-templates.json';
import email_templates_pl from '@translations/pl/email-templates.json';
import unauthorized_en from '@translations/en/unauthorized.json';
import unauthorized_pl from '@translations/pl/unauthorized.json';
import settings_en from '@translations/en/settings.json';
import settings_pl from '@translations/pl/settings.json';
import static_content_en from '@translations/en/static-content.json';
import static_content_pl from '@translations/pl/static-content.json';
import featured_offers_pl from '@translations/pl/featured-offers.json';
import featured_offers_en from '@translations/en/featured-offers.json';
import TRANSLATIONS from '@translations/translationNamespaces';

const storagePlatformLanguage = window?.localStorage?.platformLanguage;
const defaultPlatformLanguage = window?._env_?.DEFAULT_APP_LANGUAGE;

i18next.init({
  lng: storagePlatformLanguage || defaultPlatformLanguage,
  fallbackLng: defaultPlatformLanguage,
  resources: {
    en: {
      [TRANSLATIONS.COMMON]: common_en,
      [TRANSLATIONS.TOPBAR]: topbar_en,
      [TRANSLATIONS.FAQ]: faq_en,
      [TRANSLATIONS.OFFERS]: offers_en,
      [TRANSLATIONS.SERIES]: series_en,
      [TRANSLATIONS.AGREEMENTS]: agreements_en,
      [TRANSLATIONS.ERRORS]: errors_en,
      [TRANSLATIONS.PAGES]: pages_en,
      [TRANSLATIONS.TRANSACTIONS]: transactions_en,
      [TRANSLATIONS.ORDERS]: orders_en,
      [TRANSLATIONS.INVESTMENTS]: investments_en,
      [TRANSLATIONS.SLIDER]: slider_en,
      [TRANSLATIONS.PERMISSIONS]: permissions_en,
      [TRANSLATIONS.ROLES]: roles_en,
      [TRANSLATIONS.USERS]: users_en,
      [TRANSLATIONS.EMAIL_TEMPLATES]: email_templates_en,
      [TRANSLATIONS.UNAUTHORIZED]: unauthorized_en,
      [TRANSLATIONS.SETTINGS]: settings_en,
      [TRANSLATIONS.STATIC_CONTENT]: static_content_en,
      [TRANSLATIONS.FEATURED_OFFERS]: featured_offers_en,
    },
    pl: {
      [TRANSLATIONS.COMMON]: common_pl,
      [TRANSLATIONS.TOPBAR]: topbar_pl,
      [TRANSLATIONS.FAQ]: faq_pl,
      [TRANSLATIONS.OFFERS]: offers_pl,
      [TRANSLATIONS.SERIES]: series_pl,
      [TRANSLATIONS.AGREEMENTS]: agreements_pl,
      [TRANSLATIONS.ERRORS]: errors_pl,
      [TRANSLATIONS.PAGES]: pages_pl,
      [TRANSLATIONS.TRANSACTIONS]: transactions_pl,
      [TRANSLATIONS.ORDERS]: orders_pl,
      [TRANSLATIONS.INVESTMENTS]: investments_pl,
      [TRANSLATIONS.SLIDER]: slider_pl,
      [TRANSLATIONS.PERMISSIONS]: permissions_pl,
      [TRANSLATIONS.ROLES]: roles_pl,
      [TRANSLATIONS.USERS]: users_pl,
      [TRANSLATIONS.EMAIL_TEMPLATES]: email_templates_pl,
      [TRANSLATIONS.UNAUTHORIZED]: unauthorized_pl,
      [TRANSLATIONS.SETTINGS]: settings_pl,
      [TRANSLATIONS.STATIC_CONTENT]: static_content_pl,
      [TRANSLATIONS.FEATURED_OFFERS]: featured_offers_pl,
    },
  },
});
