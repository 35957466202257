import React from 'react';
import { Grid, Image, Segment, Input, Button, Label } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import VALID_IMAGE_MIME_TYPES from '../../models/ImageTypes';

class OfferImage extends React.PureComponent {
  offerImageRef = React.createRef();

  constructor(props) {
    super(props);

    this.onUndoUpload = this.onUndoUpload.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
  }

  onUploadImage(e) {
    const { onChange } = this.props;

    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        return onChange({
          offerImage: {
            fileName: file.name,
            delete: false,
            base64: reader.result,
          },
        });
      };

      return reader.readAsDataURL(file);
    }
  }

  onRemoveImage() {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerImage: { ...prevState.offerImage, delete: !prevState.offerImage.delete },
    }));
  }

  onUndoUpload() {
    const { onChange } = this.props;

    onChange(
      () => ({
        offerImage: { base64: undefined, fileName: undefined, delete: false },
      }),
      () => (this.offerImageRef.current.inputRef.current.value = ''),
    );
  }

  render() {
    const { label, uploadedImage, offerImage, t, isViewMode } = this.props;
    const toBeRemoved = offerImage.delete;

    return (
      <Grid.Column>
        <Label size="big" color="black">
          {label}
        </Label>
        <Segment>
          <Grid container stackable columns={2} verticalAlign="middle">
            <Grid.Column>
              {uploadedImage || offerImage.base64 ? (
                <Image src={offerImage.base64 || uploadedImage} size="medium" disabled={toBeRemoved} rounded bordered />
              ) : (
                <Label content={t('noImageYet')} icon="warning" />
              )}
            </Grid.Column>
            {isViewMode ? null : (
              <Grid.Column textAlign="right">
                <Input
                  ref={this.offerImageRef}
                  onChange={this.onUploadImage}
                  type="file"
                  accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                />
                <br />
                <br />
                {uploadedImage && !offerImage.base64 && (
                  <Button color={toBeRemoved ? 'blue' : 'red'} onClick={this.onRemoveImage}>
                    {toBeRemoved ? t('undoRemoveImage') : t('removeImage')}
                  </Button>
                )}
                <br />
                <br />
                {uploadedImage && offerImage.base64 && (
                  <Button color="red" onClick={this.onUndoUpload}>
                    Undo new image upload
                  </Button>
                )}
              </Grid.Column>
            )}
          </Grid>
        </Segment>
      </Grid.Column>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(OfferImage);
