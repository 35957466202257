import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSingleInvestmentAction } from '@store/actions/investments';
import {
  getSingleInvestment,
  getSingleInvestmentError,
  getSingleInvestmentStatus,
} from '@store/reducers/investments/selectors';
import { getPermissionCheckStatus, getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  investment: getSingleInvestment(state),
  investmentStatus: getSingleInvestmentStatus(state),
  investmentError: getSingleInvestmentError(state),
  permissionCheckStatus: getPermissionCheckStatus(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSingleInvestmentAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
