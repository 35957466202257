import React, { useEffect, useRef, useState } from 'react';
import { Switch } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { PrivateRouteWithLayout, RouteWithLayout } from '@shared/route-components';
import { Main as MainLayout, Unauthorized as UnauthorizedLayout } from '@theme/layouts';
import DashboardPage from '@screens/dashboard/Container';
import LoginPage from '@screens/login/Container';
import ForgotPasswordPage from '@screens/forgot-password/Container';
import ResetPasswordPage from '@screens/reset-password/Container';
import OffersScreen from '@screens/offers/Container';
import AddOfferScreen from '@screens/offers/add-offer/Container';
import EditOfferScreen from '@screens/offers/edit-offer/Container';
import FaqPage from '@screens/faq/Container';
import EditFaqPage from '@screens/faq/edit-faq/Container';
import CreateFaqPage from '@screens/faq/add-faq/Container';
import EmailTemplatePage from '@screens/email-templates/Container';
import SingleTemplatePage from '@screens/email-templates/single-template/Container';
import InvestmentsScreen from '@screens/investments/Container';
import EditInvestmentScreen from '@screens/investments/edit-investment/Container';
import OrdersScreen from '@screens/orders/Container';
import EditOrderScreen from '@screens/orders/edit-order/Container';
import CreateOrderScreen from '@screens/orders/add-order/Container';
import TransactionsPage from '@screens/transactions/Container';
import EditTransactionPage from '@screens/transactions/edit-transactions/Container';
import Settings from '@screens/settings/Container';
import PageList from '@screens/page/Container';
import PageCreate from '@screens/page/add-page/Container';
import PageEdit from '@screens/page/edit-page/Container';
import AgreementsPage from '@screens/agreements/Container';
import StaticContentPage from '@screens/static-content/Container';
import StaticContentParent from '@screens/static-content/static-content-parent/Container';
import StaticContentElement from '@screens/static-content/static-content-element/Container';
import AgreementCreate from '@screens/agreements/add-agreement/Container';
import SingleAgreementPage from '@screens/agreements/edit-agreement/Container';
import RolesScreen from '@screens/roles/Container';
import CreateRoleScreen from '@screens/roles/create-role/Container';
import EditRoleScreen from '@screens/roles/edit-role/Container';
import PermissionManagement from '@screens/permissions/Container';
import SinglePermission from '@screens/permissions/edit-permission/Container';
import CreatePermission from '@screens/permissions/add-permission/Container';
import SliderManagement from '@screens/slider/Container';
import EditSliderPage from '@screens/slider/edit-slider/Container';
import CreateSliderPage from '@screens/slider/add-slider/Container';
import NotFoundPage from '@screens/not-found/Container';
import SeriesScreen from '@screens/series/Container';
import AddSeriesScreen from '@screens/series/add-series/Container';
import EditSeriesScreen from '@screens/series/edit-series/Container';
import UserListScreen from '@screens/users/Container';
import AddUserScreen from '@screens/users/add-user/Container';
import EditUserScreen from '@screens/users/edit-user/Container';
import SetPasswordScreen from '@screens/set-password/Container';
import SystemMaintenance from '@screens/system-maintenance/Container';
import FeaturedOffersList from '@screens/featured-offers/Container';
import EditFeaturedOffer from '@screens/featured-offers/edit-featured-offer/Container';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from '@shared/layout-components/Loader';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { useTranslation } from 'react-i18next';
import { getGlobalStatus, getIsMaintenanceMode } from '@store/reducers/global/selectors';
import { getIsUserLoggedInFromStorage } from '@api-sdk/utils';
import getUserInfo from '@store/actions/user/getUserInfo';
import { checkPermissionAction } from '@store/actions/check-permission';
import { getLoggedUser } from '@store/reducers/user/selectors';
import { getSplashScreenStatus } from '@store/reducers/splash-screen/selectors';
import TRANSLATIONS from '@translations/translationNamespaces';
import { arrayOf, bool, shape, string } from 'prop-types';
import Notifier from '@shared/components/Notifier';
import { SnackbarProvider } from 'notistack';
import '@theme/index.scss';
import 'react-quill/dist/quill.snow.css';
import './overrides.scss';
import PERMISSIONS from '@shared/consts/permissions';
import { getPermissionCheckStatus, getPermittedPermissions } from '@store/reducers/check-permission/selectors';

const App = ({
  status,
  actions,
  isMaintenanceMode,
  permittedPermissions,
  checkPermissionStatus,
  splashScreenStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.COMMON);
  const notistackRef = useRef(null);
  const [checkUserPermissions, setCheckUserPermissions] = useState(false);
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useEffect(() => {
    if (getIsUserLoggedInFromStorage()) {
      actions.getUserInfo();
      actions.checkPermissionAction([
        PERMISSIONS.AGREEMENT_CREATE,
        PERMISSIONS.AGREEMENT_DELETE,
        PERMISSIONS.AGREEMENT_EDIT,
        PERMISSIONS.GET_AGREEMENT_BY_ID,
        PERMISSIONS.GET_AGREEMENTS_LIST,
        PERMISSIONS.EMAIL_TEMPLATE_EDIT,
        PERMISSIONS.GET_EMAIL_TEMPLATE_BY_ID,
        PERMISSIONS.EMAIL_TEMPLATE_GET_CONFIG,
        PERMISSIONS.GET_EMAIL_TEMPLATE_LIST,
        PERMISSIONS.EMAIL_TEMPLATE_TEST_TEMPLATE,
        PERMISSIONS.FAQ_CREATE,
        PERMISSIONS.FAQ_DELETE,
        PERMISSIONS.FAQ_EDIT,
        PERMISSIONS.GET_FAQ_LIST,
        PERMISSIONS.GET_FAQ_BY_ID,
        PERMISSIONS.FEATURED_OFFER_EDIT,
        PERMISSIONS.GET_FEATURED_OFFER_BY_ID,
        PERMISSIONS.GET_FEATURED_OFFERS_LIST,
        PERMISSIONS.GET_INVESTMENT_BY_ID,
        PERMISSIONS.GET_INVESTMENT_LIST,
        PERMISSIONS.EDIT_ORDER,
        PERMISSIONS.ACCEPT_ORDER,
        PERMISSIONS.REJECT_ORDER,
        PERMISSIONS.DOWNLOAD_ORDER_SPREAD_SHEET,
        PERMISSIONS.GET_ORDER_BY_ID,
        PERMISSIONS.GET_ORDER_LIST,
        PERMISSIONS.PAGE_CREATE,
        PERMISSIONS.PAGE_DELETE,
        PERMISSIONS.PAGE_EDIT,
        PERMISSIONS.GET_PAGE_BY_ID,
        PERMISSIONS.GET_PAGE_LIST,
        PERMISSIONS.PERMISSION_CREATE,
        PERMISSIONS.PERMISSION_DELETE,
        PERMISSIONS.PERMISSION_EDIT,
        PERMISSIONS.GET_PERMISSION_BY_ID,
        PERMISSIONS.GET_PERMISSION_LIST,
        PERMISSIONS.ADD_PERMISSION_TO_ROLE,
        PERMISSIONS.ADD_USER_TO_ROLE,
        PERMISSIONS.ROLE_CREATE,
        PERMISSIONS.ROLE_DELETE,
        PERMISSIONS.ROLE_EDIT,
        PERMISSIONS.GET_ROLE_BY_ID,
        PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE,
        PERMISSIONS.GET_USERS_ASSIGNED_TO_ROLE,
        PERMISSIONS.GET_ROLE_LIST,
        PERMISSIONS.REMOVE_PERMISSION_FROM_ROLE,
        PERMISSIONS.REMOVE_USER_FROM_ROLE,
        PERMISSIONS.GET_USER_LIST,
        PERMISSIONS.GET_SERIES_LIST,
        PERMISSIONS.GET_SERIES_BY_ID,
        PERMISSIONS.CREATE_SERIES,
        PERMISSIONS.DELETE_SERIES,
        PERMISSIONS.EDIT_SERIES,
        PERMISSIONS.SLIDER_CREATE,
        PERMISSIONS.SLIDER_DELETE,
        PERMISSIONS.SLIDER_EDIT,
        PERMISSIONS.GET_SLIDER_BY_ID,
        PERMISSIONS.GET_SLIDER_LIST,
        PERMISSIONS.STATIC_CONTENT_EDIT_ELEMENT,
        PERMISSIONS.GET_STATIC_CONTENT_ELEMENT_BY_ID,
        PERMISSIONS.GET_STATIC_CONTENT_LIST,
        PERMISSIONS.GET_TRANSACTION_LIST,
        PERMISSIONS.GET_TRANSACTION_BY_ID,
        PERMISSIONS.ACCEPT_TRANSACTION,
        PERMISSIONS.EXTEND_TRANSACTION,
        PERMISSIONS.USER_CREATE,
        PERMISSIONS.USER_DELETE,
        PERMISSIONS.USER_EDIT,
        PERMISSIONS.GET_USER_BY_ID,
        PERMISSIONS.GET_PERSONAL_PERMISSIONS,
        PERMISSIONS.GET_USER_ROLES,
        PERMISSIONS.GET_USER_LIST,
        PERMISSIONS.GET_USER_AGREEMENTS,
        PERMISSIONS.ADD_PERSONAL_PERMISSION_TO_USER,
        PERMISSIONS.GET_OFFER_BY_ID,
        PERMISSIONS.CREATE_OFFER,
        PERMISSIONS.DELETE_OFFER,
        PERMISSIONS.EDIT_OFFER,
        PERMISSIONS.GET_OFFER_LIST,
        PERMISSIONS.GET_OFFER_BY_ID,
        PERMISSIONS.MANUAL_ORDER,
      ]);
    }
  }, [getIsUserLoggedInFromStorage()]);

  useEffect(() => {
    if (checkPermissionStatus === FETCH_STATUS.SUCCESS && checkUserPermissions) {
      setCheckUserPermissions(false);
    }
  }, [checkPermissionStatus]);

  if (isMaintenanceMode) {
    return <SystemMaintenance />;
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={1}
      preventDuplicate
      onClick={() => {
        notistackRef.current.closeSnackbar();
      }}
    >
      <div className="App">
        <Notifier />
        <Loader
          disableEnterTransition={checkUserPermissions}
          open={
            status === FETCH_STATUS.LOADING ||
            checkUserPermissions ||
            splashScreenStatus === FETCH_STATUS.LOADING ||
            checkPermissionStatus === FETCH_STATUS.IDLE
          }
          fullscreen
          title={t('global-loading-title')}
          subtitle={t('global-loading-subtitle')}
        />
        <Switch>
          <RouteWithLayout
            exact
            isRestricted
            component={SetPasswordScreen}
            layout={UnauthorizedLayout}
            path={ROUTES.USER_SET_PASSWORD}
          />
          <RouteWithLayout exact isRestricted path={ROUTES.LOGIN} layout={UnauthorizedLayout} component={LoginPage} />
          <RouteWithLayout
            exact
            path={ROUTES.FORGOT_PASSWORD}
            layout={UnauthorizedLayout}
            component={ForgotPasswordPage}
            isRestricted
          />
          <RouteWithLayout
            exact
            path={ROUTES.RESET_PASSWORD}
            layout={UnauthorizedLayout}
            component={ResetPasswordPage}
            isRestricted
          />
          <PrivateRouteWithLayout exact path={ROUTES.HOME} layout={MainLayout} component={DashboardPage} />

          {isUserPermitted(PERMISSIONS.GET_OFFER_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.OFFERS} layout={MainLayout} component={OffersScreen} />
          )}

          {isUserPermitted(PERMISSIONS.CREATE_OFFER) && (
            <PrivateRouteWithLayout exact path={ROUTES.ADD_OFFER} layout={MainLayout} component={AddOfferScreen} />
          )}

          {isUserPermitted(PERMISSIONS.EDIT_OFFER) && (
            <PrivateRouteWithLayout exact path={ROUTES.EDIT_OFFER} layout={MainLayout} component={EditOfferScreen} />
          )}

          {isUserPermitted(PERMISSIONS.GET_INVESTMENT_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.INVESTMENTS} layout={MainLayout} component={InvestmentsScreen} />
          )}

          {isUserPermitted(PERMISSIONS.GET_INVESTMENT_BY_ID) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.SINGLE_INVESTMENT}
              layout={MainLayout}
              component={EditInvestmentScreen}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_ORDER_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.ORDERS} layout={MainLayout} component={OrdersScreen} />
          )}

          {isUserPermitted(PERMISSIONS.GET_ORDER_BY_ID) && (
            <PrivateRouteWithLayout exact path={ROUTES.SINGLE_ORDER} layout={MainLayout} component={EditOrderScreen} />
          )}

          {isUserPermitted(PERMISSIONS.MANUAL_ORDER) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.CREATE_ORDER}
              layout={MainLayout}
              component={CreateOrderScreen}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_AGREEMENTS_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.AGREEMENTS} layout={MainLayout} component={AgreementsPage} />
          )}

          {isUserPermitted(PERMISSIONS.GET_STATIC_CONTENT_LIST) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.STATIC_CONTENT}
              layout={MainLayout}
              component={StaticContentPage}
            />
          )}

          <PrivateRouteWithLayout
            exact
            path={ROUTES.STATIC_CONTENT_PARENT}
            layout={MainLayout}
            component={StaticContentParent}
          />

          {isUserPermitted(PERMISSIONS.GET_STATIC_CONTENT_ELEMENT_BY_ID) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.STATIC_CONTENT_ELEMENT}
              layout={MainLayout}
              component={StaticContentElement}
            />
          )}

          {isUserPermitted(PERMISSIONS.AGREEMENT_CREATE) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.AGREEMENT_CREATE}
              layout={MainLayout}
              component={AgreementCreate}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_AGREEMENT_BY_ID) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.SINGLE_AGREEMENT}
              layout={MainLayout}
              component={SingleAgreementPage}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_PAGE_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.PAGES} layout={MainLayout} component={PageList} />
          )}

          {isUserPermitted(PERMISSIONS.PAGE_CREATE) && (
            <PrivateRouteWithLayout exact path={ROUTES.PAGE_CREATE} layout={MainLayout} component={PageCreate} />
          )}

          {isUserPermitted(PERMISSIONS.GET_PAGE_BY_ID) && (
            <PrivateRouteWithLayout exact path={ROUTES.EDIT_PAGE} layout={MainLayout} component={PageEdit} />
          )}

          {isUserPermitted(PERMISSIONS.GET_FAQ_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.FAQ} layout={MainLayout} component={FaqPage} />
          )}

          {isUserPermitted(PERMISSIONS.FAQ_CREATE) && (
            <PrivateRouteWithLayout exact path={ROUTES.ADD_FAQ} layout={MainLayout} component={CreateFaqPage} />
          )}

          {isUserPermitted(PERMISSIONS.GET_FAQ_BY_ID) && (
            <PrivateRouteWithLayout exact path={ROUTES.EDIT_FAQ} layout={MainLayout} component={EditFaqPage} />
          )}

          {isUserPermitted(PERMISSIONS.GET_EMAIL_TEMPLATE_LIST) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.EMAIL_TEMPLATES}
              layout={MainLayout}
              component={EmailTemplatePage}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_EMAIL_TEMPLATE_BY_ID) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.SINGLE_TEMPLATE}
              layout={MainLayout}
              component={SingleTemplatePage}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_TRANSACTION_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.TRANSACTIONS} layout={MainLayout} component={TransactionsPage} />
          )}

          {isUserPermitted(PERMISSIONS.GET_TRANSACTION_BY_ID) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.EDIT_TRANSACTION}
              layout={MainLayout}
              component={EditTransactionPage}
            />
          )}

          <PrivateRouteWithLayout exact path={ROUTES.SETTINGS} layout={MainLayout} component={Settings} />

          {isUserPermitted(PERMISSIONS.GET_PERMISSION_LIST) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.PERMISSION_MANAGEMENT}
              layout={MainLayout}
              component={PermissionManagement}
            />
          )}

          {isUserPermitted(PERMISSIONS.PERMISSION_CREATE) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.PERMISSION_CREATE}
              layout={MainLayout}
              component={CreatePermission}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_PERMISSION_BY_ID) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.SINGLE_PERMISSION}
              layout={MainLayout}
              component={SinglePermission}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_ROLE_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.ROLES} layout={MainLayout} component={RolesScreen} />
          )}

          {isUserPermitted(PERMISSIONS.ROLE_CREATE) && (
            <PrivateRouteWithLayout exact path={ROUTES.CREATE_ROLE} layout={MainLayout} component={CreateRoleScreen} />
          )}

          {isUserPermitted(PERMISSIONS.GET_ROLE_BY_ID) && (
            <PrivateRouteWithLayout exact path={ROUTES.EDIT_ROLE} layout={MainLayout} component={EditRoleScreen} />
          )}

          {isUserPermitted(PERMISSIONS.GET_SLIDER_LIST) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.SLIDER_MANAGEMENT}
              layout={MainLayout}
              component={SliderManagement}
            />
          )}

          {isUserPermitted(PERMISSIONS.SLIDER_CREATE) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.CREATE_SLIDER}
              layout={MainLayout}
              component={CreateSliderPage}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_SLIDER_BY_ID) && (
            <PrivateRouteWithLayout exact path={ROUTES.EDIT_SLIDER} layout={MainLayout} component={EditSliderPage} />
          )}

          {isUserPermitted(PERMISSIONS.GET_SERIES_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.SERIES} layout={MainLayout} component={SeriesScreen} />
          )}

          {isUserPermitted(PERMISSIONS.CREATE_SERIES) && (
            <PrivateRouteWithLayout exact path={ROUTES.ADD_SERIES} layout={MainLayout} component={AddSeriesScreen} />
          )}

          {isUserPermitted(PERMISSIONS.GET_SERIES_BY_ID) && (
            <PrivateRouteWithLayout exact path={ROUTES.EDIT_SERIES} layout={MainLayout} component={EditSeriesScreen} />
          )}

          {isUserPermitted(PERMISSIONS.GET_USER_LIST) && (
            <PrivateRouteWithLayout exact path={ROUTES.USERS} layout={MainLayout} component={UserListScreen} />
          )}

          {isUserPermitted(PERMISSIONS.USER_CREATE) && (
            <PrivateRouteWithLayout exact path={ROUTES.USER_CREATE} layout={MainLayout} component={AddUserScreen} />
          )}

          {isUserPermitted(PERMISSIONS.GET_USER_BY_ID) && (
            <PrivateRouteWithLayout exact path={ROUTES.USER_EDIT} layout={MainLayout} component={EditUserScreen} />
          )}

          {isUserPermitted(PERMISSIONS.GET_FEATURED_OFFERS_LIST) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.FEATURED_OFFERS}
              layout={MainLayout}
              component={FeaturedOffersList}
            />
          )}

          {isUserPermitted(PERMISSIONS.GET_FEATURED_OFFER_BY_ID) && (
            <PrivateRouteWithLayout
              exact
              path={ROUTES.EDIT_FEATURED_OFFER}
              layout={MainLayout}
              component={EditFeaturedOffer}
            />
          )}

          <PrivateRouteWithLayout layout={MainLayout} component={NotFoundPage} />
        </Switch>
      </div>
    </SnackbarProvider>
  );
};

App.propTypes = {
  status: string,
  actions: shape({}).isRequired,
  isMaintenanceMode: bool,
  permittedPermissions: arrayOf(shape({})),
  checkPermissionStatus: string,
  splashScreenStatus: string,
};

App.defaultProps = {
  status: undefined,
  isMaintenanceMode: false,
  permittedPermissions: [],
  checkPermissionStatus: undefined,
  splashScreenStatus: undefined,
};

const mapStateToProps = state => ({
  status: getGlobalStatus(state),
  user: getLoggedUser(state),
  isMaintenanceMode: getIsMaintenanceMode(state),
  permittedPermissions: getPermittedPermissions(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
  splashScreenStatus: getSplashScreenStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUserInfo,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
