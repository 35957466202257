import React from 'react';
import { Form, Grid, Icon, Label } from 'semantic-ui-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '@screens/offers/NewOfferForm/components/TranslatableTextField/styles.scss';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';

class DatePickerField extends React.PureComponent {
  render() {
    const { label, name, onChange, value, icon, errorMessage, clearable = false, tooltip, disabled } = this.props;

    return (
      <Grid.Column>
        <Form.Field>
          {errorMessage && (
            <Label color="red" className="mb-5">
              {errorMessage}
            </Label>
          )}
          <Form.Field className="d-flex justify-content-between">
            <label>
              {label}{' '}
              {tooltip && (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                >
                  <Icon className={styles.icon} name="info circle" />
                </OverlayTrigger>
              )}
            </label>
          </Form.Field>
          <DatePicker
            id={name}
            name={name}
            isClearable={clearable}
            utcOffset={0}
            disabled={disabled}
            {...(new Date(value)?.toString?.() !== 'Invalid Date' && value !== null && value !== undefined
              ? { selected: new Date(moment?.tz?.(value, 'Europe/Warsaw').format('YYYY-MM-DDTHH:mm:ss.SSS')) }
              : {})}
            onChange={(date, e) => onChange(e, { value: date, name })}
            dateFormat="dd/MM/yyyy'"
            monthsShown={1}
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ['bottom'],
              },
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            }}
          />
        </Form.Field>
      </Grid.Column>
    );
  }
}

DatePickerField.defaultProps = {
  onChange: () => {},
};

export default DatePickerField;
