import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modalWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2000,
    height: '100%',
    width: '100%',
    backdropFilter: 'brightness(0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  quillWrapper: {
    background: '#fff',
    minWidth: 500,
    position: 'relative',
  },
  buttonCell: {
    width: 10,
  },
  th: {
    padding: 8,
    whiteSpace: 'nowrap',
    width: '1%',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  removeButton: {
    maxWidth: 'fit-content',
    margin: 0,
  },
  td: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    width: '1%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  quillInfoWrapper: {
    background: '#ffffff',
    position: 'absolute',
    top: -32,
    padding: '3px 6px',
    borderRadius: 4,

    '& span': {
      margin: 4,
      fontWeight: 600,
    },
  },
}));

export default useStyles;
