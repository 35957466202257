const getNewAgreementModel = prevState =>
  prevState?.offerAgreements.concat({
    active: false,
    required: false,
    target: 'investment',
    type: 'data-processing',
    position:
      prevState?.offerAgreements?.length > 0
        ? prevState?.offerAgreements?.[prevState?.offerAgreements?.length - 1]?.position + 1
        : 1,
    backendIndex:
      prevState?.offerAgreements?.length > 0
        ? prevState?.offerAgreements?.[prevState?.offerAgreements?.length - 1]?.backendIndex + 1
        : 1,
    name: { en: '', pl: '' },
    content: { en: '', pl: '' },
    initWithEditor: true,
  });

export default getNewAgreementModel;
