const mapTransactionStatus = ({ status, t }) => {
  switch (status) {
    case 'new': {
      return t('transactionStatus.new');
    }
    case 'paid': {
      return t('transactionStatus.paid');
    }
    case 'rejected': {
      return t('transactionStatus.rejected');
    }
    case 'completed': {
      return t('transactionStatus.completed');
    }
    default: {
      return '-';
    }
  }
};

export default mapTransactionStatus;
