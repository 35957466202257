import React from 'react';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { useParams } from 'react-router-dom';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import SeriesForm from './components/SeriesForm';

const Main = ({ actions, singleSeries, seriesStatus, seriesErrors, permittedPermissions }) => {
  const params = useParams();
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMountWithTranslate(() => {
    actions.getSingleSeriesAction(params?.id);
  });

  return (
    <div>
      <SeriesForm
        isLoading={seriesStatus === FETCH_STATUS.IDLE || seriesStatus === FETCH_STATUS.LOADING}
        series={singleSeries}
        seriesErrors={seriesErrors}
        onRefreshSeries={() => actions.getSingleSeriesAction(params?.id)}
        onSaveSeries={actions.editSeriesAction}
        isViewMode={!isUserPermitted(PERMISSIONS.EDIT_SERIES)}
      />
    </div>
  );
};

export default Main;
