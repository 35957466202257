import React from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { TranslatableRichTextField } from '../../../../../components';
import TRANSLATIONS from '@translations/translationNamespaces';
import { withTranslation } from 'react-i18next';

class TextElement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChangeElementContent = this.onChangeElementContent.bind(this);
  }

  onChangeElementContent(e, { value, name, language }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: {
                        ...el[name],
                        [language]: value,
                      },
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  render() {
    const { element, elementErrors, t, isViewMode } = this.props;

    return (
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            disabled={isViewMode}
            name="content"
            label={t('text.content')}
            errorMessage={elementErrors?.content}
            onChange={this.onChangeElementContent}
            value={element.content}
          />
        </Grid>
      </Form.Group>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(TextElement);
