import React from 'react';
import { Message, Button, Form, Divider } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import './styles.scss';
import Agreement from './Agreement';
import getNewAgreementModel from './NewAgreementModel';
import { Tooltip } from '@shared/components';

const OfferAgreements = ({
  isLoading,
  offerAgreements,
  agreementTargets,
  agreementTypes,
  onChange,
  offerErrors,
  onSaveOffer,
  isViewMode,
}) => {
  const [t] = useTranslation(TRANSLATIONS.AGREEMENTS);

  const renderAgreementsList = () => {
    const agreementPositions =
      offerAgreements?.length > 0
        ? offerAgreements?.map?.(agreement => {
            return { key: agreement.position, text: agreement.position, value: agreement.position };
          })
        : { key: 1, text: 1, value: 1 };

    return (
      <div>
        {offerAgreements
          ?.sort((a, b) => Number(a.position) - Number(b.position))
          ?.map?.((agreement, agreementIndex) => {
            return (
              <>
                <Divider />
                <Agreement
                  isViewMode={isViewMode}
                  agreementPositions={agreementPositions}
                  agreementTargets={agreementTargets}
                  agreementTypes={agreementTypes}
                  key={agreement.id}
                  agreement={agreement}
                  agreementIndex={agreementIndex}
                  onChange={onChange}
                  offerErrors={offerErrors?.agreements?.[agreement?.backendIndex]}
                  onSaveOffer={onSaveOffer}
                />
              </>
            );
          })}
      </div>
    );
  };

  const onAddNewAgreement = () => {
    onChange(prevState => ({
      ...prevState,
      offerAgreements: getNewAgreementModel(prevState),
    }));
  };

  return (
    <Form loading={isLoading}>
      <div className="offer-tab-agreements">
        {!isEmpty(offerErrors?.agreements) && <Message color="red">{t('agreements.agreementsError')}</Message>}
        {isViewMode ? null : (
          <>
            <div>
              <Button primary onClick={onAddNewAgreement}>
                {t('agreements.addNewAgreement')}
              </Button>
            </div>
            <Divider />
          </>
        )}

        <div>
          <h3>
            {t('agreements.agreementsListTitle')} <Tooltip>{t('tooltip.offerAgreements')}</Tooltip>
          </h3>
          {renderAgreementsList()}
        </div>
      </div>
    </Form>
  );
};

export default OfferAgreements;
