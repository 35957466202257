import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSingleSeries, getSeriesStatus, getSeriesErrors } from '@store/reducers/series/selectors';
import { createSeriesAction } from '@store/actions/series';
import Main from './Main';

const mapStateToProps = state => ({
  singleSeries: getSingleSeries(state),
  seriesStatus: getSeriesStatus(state),
  seriesErrors: getSeriesErrors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createSeriesAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
