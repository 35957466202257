import {
  EDIT_ORDER_INIT,
  EDIT_ORDER_FAILURE,
  EDIT_ORDER_SUCCESS,
  GET_SINGLE_ORDER_SUCCESS,
} from '@store/action-types/orders';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import { set, toPath } from 'lodash';
import translateToastMessage from '@shared/utils/translateToastMessage';

const editOrderInit = () => ({ type: EDIT_ORDER_INIT });
const editOrderSuccess = order => ({ type: EDIT_ORDER_SUCCESS, payload: { order } });
const editOrderFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    set(acc, path, item.code?.split(':')?.pop() || item.message);

    return acc;
  }, {});

  return { type: EDIT_ORDER_FAILURE, payload: { error, errors: mappedErrors } };
};

const getSingleOrderSuccess = order => ({ type: GET_SINGLE_ORDER_SUCCESS, payload: { order } });

const editOrder = ({ investorData, orderId }) => async dispatch => {
  dispatch(editOrderInit());

  try {
    const editOrderResponse = await client.editOrder({
      slug: orderId,
      data: {
        investorData: {
          firstName: investorData?.firstName,
          lastName: investorData?.lastName,
          phoneNumber: investorData?.phoneNumber,
          address: {
            address: investorData?.address?.address,
            zip: investorData?.address?.zip,
            city: investorData?.address?.city,
            state: investorData?.address?.state?.id,
            country: investorData?.address?.country?.id,
          },
          bankAccount: {
            country: investorData?.bankAccount?.country?.id,
            bankName: investorData?.bankAccount?.bankName,
            accountNumber: investorData?.bankAccount?.accountNumber,
          },
        },
      },
    });

    await dispatch(editOrderSuccess(editOrderResponse.response));
    await dispatch(getSingleOrderSuccess(editOrderResponse.response));

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('order.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(editOrderFailure(error));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('order.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default editOrder;
