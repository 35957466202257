const getAllTransactions = state => state?.transactions?.allTransactions;
const getAllTransactionsQueryParams = state => state?.transactions?.queryParams;
const getTransactionStatus = state => state?.transactions?.status;
const getSingleTransaction = state => state?.transactions?.singleTransaction;
const getTransactionError = state => state?.transactions?.error;
const getTransactionListError = state => state?.transactions?.transactionError;

export {
  getAllTransactions,
  getTransactionListError,
  getTransactionStatus,
  getSingleTransaction,
  getTransactionError,
  getAllTransactionsQueryParams,
};
