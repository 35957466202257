const GET_ORDERS_INIT = '@orders/GET_ORDERS_INIT';
const GET_ORDERS_SUCCESS = '@orders/GET_ORDERS_SUCCESS';
const GET_ORDERS_FAILURE = '@orders/GET_ORDERS_FAILURE';

const GET_SINGLE_ORDER_INIT = '@orders/GET_SINGLE_ORDER_INIT';
const GET_SINGLE_ORDER_SUCCESS = '@orders/GET_SINGLE_ORDER_SUCCESS';
const GET_SINGLE_ORDER_FAILURE = '@orders/GET_SINGLE_ORDER_FAILURE';

const EDIT_ORDER_INIT = '@orders/EDIT_ORDER_INIT';
const EDIT_ORDER_SUCCESS = '@orders/EDIT_ORDER_SUCCESS';
const EDIT_ORDER_FAILURE = '@orders/EDIT_ORDER_FAILURE';

const ACCEPT_ORDER_INIT = '@orders/ACCEPT_ORDER_INIT';
const ACCEPT_ORDER_SUCCESS = '@orders/ACCEPT_ORDER_SUCCESS';
const ACCEPT_ORDER_FAILURE = '@orders/ACCEPT_ORDER_FAILURE';

const REJECT_ORDER_INIT = '@orders/REJECT_ORDER_INIT';
const REJECT_ORDER_SUCCESS = '@orders/REJECT_ORDER_SUCCESS';
const REJECT_ORDER_FAILURE = '@orders/REJECT_ORDER_FAILURE';

const DOWNLOAD_SPREADSHEET_INIT = '@orders/DOWNLOAD_SPREADSHEET_INIT';
const DOWNLOAD_SPREADSHEET_SUCCESS = '@orders/DOWNLOAD_SPREADSHEET_SUCCESS';
const DOWNLOAD_SPREADSHEET_FAILURE = '@orders/DOWNLOAD_SPREADSHEET_FAILURE';

const CREATE_ORDER_INIT = '@orders/CREATE_ORDER_INIT';
const CREATE_ORDER_SUCCESS = '@orders/CREATE_ORDER_SUCCESS';
const CREATE_ORDER_FAILURE = '@orders/CREATE_ORDER_FAILURE';

const REMOVE_SIGNED_DOCUMENT_INIT = '@orders/REMOVE_SIGNED_DOCUMENT_INIT';
const REMOVE_SIGNED_DOCUMENT_SUCCESS = '@orders/REMOVE_SIGNED_DOCUMENT_SUCCESS';
const REMOVE_SIGNED_DOCUMENT_FAILURE = '@orders/REMOVE_SIGNED_DOCUMENT_FAILURE';

export {
  GET_ORDERS_INIT,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  GET_SINGLE_ORDER_FAILURE,
  GET_SINGLE_ORDER_INIT,
  GET_SINGLE_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  EDIT_ORDER_INIT,
  EDIT_ORDER_SUCCESS,
  ACCEPT_ORDER_FAILURE,
  ACCEPT_ORDER_INIT,
  ACCEPT_ORDER_SUCCESS,
  REJECT_ORDER_FAILURE,
  REJECT_ORDER_INIT,
  REJECT_ORDER_SUCCESS,
  DOWNLOAD_SPREADSHEET_INIT,
  DOWNLOAD_SPREADSHEET_SUCCESS,
  DOWNLOAD_SPREADSHEET_FAILURE,
  CREATE_ORDER_INIT,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  REMOVE_SIGNED_DOCUMENT_INIT,
  REMOVE_SIGNED_DOCUMENT_SUCCESS,
  REMOVE_SIGNED_DOCUMENT_FAILURE,
};
