import {
  GET_SINGLE_INVESTMENT_INIT,
  GET_SINGLE_INVESTMENT_SUCCESS,
  GET_SINGLE_INVESTMENT_FAILURE,
} from '@store/action-types/investments';
import client from '@api-sdk/client';

const getSingleInvestmentInit = () => ({ type: GET_SINGLE_INVESTMENT_INIT });
const getSingleInvestmentSuccess = investment => ({ type: GET_SINGLE_INVESTMENT_SUCCESS, payload: { investment } });
const getSingleInvestmentFailure = error => ({ type: GET_SINGLE_INVESTMENT_FAILURE, payload: { error } });

const getSingleInvestment = investmentId => async dispatch => {
  dispatch(getSingleInvestmentInit());

  try {
    const singleInvestmentResponse = await client.getSingleInvestment({ slug: investmentId });

    dispatch(getSingleInvestmentSuccess(singleInvestmentResponse.response));
  } catch (error) {
    dispatch(getSingleInvestmentFailure(error));
  }
};

export default getSingleInvestment;
