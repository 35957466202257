import React from 'react';
import i18next from 'i18next';
import { func } from 'prop-types';
import changeLanguage from '@translations/changeLanguage';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getAbsoluteUrl } from '@metronic/helpers';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { useMediaQuery } from '@material-ui/core';
import DropdownTopbarItemToggler from '../DropdownTopbarItemToggler';
import useStyles from './styles';

const LanguageSwitcher = ({ getMenu }) => {
  const [t] = useTranslation(TRANSLATIONS.TOPBAR);
  const styles = useStyles();
  const isMobileMenu = useMediaQuery('(max-width: 991px)');

  const polishFlag = getAbsoluteUrl('/images/icons/poland.svg');
  const englishFlag = getAbsoluteUrl('/images/icons/united-states.svg');

  const handleClick = async language => {
    if (typeof language === 'string') {
      await changeLanguage(language);
      await getMenu();
    }
  };

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
        <OverlayTrigger
          placement="bottom"
          overlay={isMobileMenu ? <div /> : <Tooltip id="language-panel-tooltip">{t('selectLanguage')}</Tooltip>}
        >
          <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
            <img
              className="h-25px w-25px rounded"
              src={i18next.language === 'pl' ? polishFlag : englishFlag}
              alt={i18next.language === 'pl' ? 'PL' : 'EN'}
            />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="navi navi-hover py-4">
          <li
            className={clsx('navi-item', {
              active: i18next.language === 'pl',
            })}
          >
            <button type="button" onClick={() => handleClick('pl')} className={`navi-link ${styles.languageButton}`}>
              <Dropdown.Item className={styles.dropdownItem}>
                <span className="symbol symbol-20 mr-3">
                  <img src={polishFlag} alt="PL" />
                </span>
                <span className="navi-text">PL</span>
              </Dropdown.Item>
            </button>
          </li>

          <li
            className={clsx('navi-item', {
              active: i18next.language === 'en',
            })}
          >
            <button type="button" onClick={() => handleClick('en')} className={`navi-link ${styles.languageButton}`}>
              <Dropdown.Item className={styles.dropdownItem}>
                <span className="symbol symbol-20 mr-3">
                  <img src={englishFlag} alt="EN" />
                </span>
                <span className="navi-text">EN</span>
              </Dropdown.Item>
            </button>
          </li>
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

LanguageSwitcher.propTypes = {
  getMenu: func,
};

LanguageSwitcher.defaultProps = {
  getMenu: () => {},
};

export default LanguageSwitcher;
