import React, { useMemo } from 'react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Skeleton } from '@shared/layout-components';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, SimpleTable } from '@shared/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { Link, useParams } from 'react-router-dom';
import { Button, Grid, Icon, Image, Popup, Table } from 'semantic-ui-react';
import { mapErrorCodes } from '@screens/transactions/utils';
import i18next from 'i18next';
import mapTransactionStatus from '@shared/utils/mapTransactionStatus';
import ROUTES from '@shared/consts/routes';
import PERMISSIONS from '@shared/consts/permissions';
import useStyles from './styles';
import { SKELETON_VARIANT } from '@shared/layout-components/Skeleton/consts';

const Main = ({
  investment,
  investmentStatus,
  investmentError,
  actions,
  permittedPermissions,
  permissionCheckStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.INVESTMENTS);
  const params = useParams();
  const styles = useStyles();

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const refreshPage = () => {
    actions.getSingleInvestmentAction(params.id);
  };

  useMountWithTranslate(() => {
    actions.getSingleInvestmentAction(params.id);
  });

  const renderInfo = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('editInvestment.investmentInfoTitle')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.investmentInfo.id')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {investment?.id}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.investmentInfo.hasPendingOrders')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {investment?.hasPendingOrders ? t('yes') : t('no')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.investmentInfo.numberOfShares')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {investment?.numberOfShares}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.investmentInfo.investedAmount')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {investment?.investedAmount?.toFixed?.(2)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  const renderUserAndOfferMeta = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('editInvestment.userAndOfferMetaTitle')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.tableLeft} textAlign="center">
              {t('editInvestment.userAndOfferMeta.offerMeta')}
            </Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right" />
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.userAndOfferMeta.offerId')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {isUserPermitted(PERMISSIONS.GET_OFFER_BY_ID) ? (
                <a href={`${ROUTES.OFFERS}/${investment?.offer?.id}/edit`} target="_blank" rel="noreferrer">
                  {investment?.offer?.id}
                </a>
              ) : (
                <span>{investment?.offer?.id}</span>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.userAndOfferMeta.slug')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {investment?.offer?.slug?.[i18next.language]}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.userAndOfferMeta.title')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {investment?.offer?.title}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.userAndOfferMeta.image')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              <Image src={investment?.offer?.thumbUrl} className={styles.offerImage} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft} textAlign="center">
              {t('editInvestment.userAndOfferMeta.userMeta')}
              {investment?.userMeta?.isDeleted && (
                <Popup
                  basic
                  content="User deleted"
                  trigger={<Icon className="ml-1" name="warning sign" color="yellow" />}
                />
              )}
            </Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right" />
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.userAndOfferMeta.userId')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {investment?.userMeta?.isDeleted || !isUserPermitted(PERMISSIONS.GET_USER_BY_ID) ? (
                <span>{investment?.userMeta?.id}</span>
              ) : (
                <a href={`${ROUTES.USERS}/${investment?.userMeta?.id}`} target="_blank" rel="noreferrer">
                  {investment?.userMeta?.id}
                </a>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.userAndOfferMeta.email')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {investment?.userMeta?.email}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editInvestment.userAndOfferMeta.firstName')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {investment?.userMeta?.firstName}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  const sharesColumns = useMemo(
    () => [
      {
        Header: t('editInvestment.sharesList.id'),
        accessor: 'id',
      },
      {
        Header: t('editInvestment.sharesList.series'),
        accessor: 'series',
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.displayName} - {row?.original?.name}
            </div>
          );
        },
      },
      {
        Header: t('editInvestment.sharesList.shareNumber'),
        accessor: 'shareNumber',
      },
      {
        Header: t('editInvestment.sharesList.price'),
        accessor: 'price',
      },
      {
        Header: t('editInvestment.sharesList.date'),
        accessor: 'date',
      },
    ],
    [t],
  );

  const transactionsColumns = useMemo(
    () => [
      {
        Header: t('editInvestment.transactionsList.id'),
        accessor: 'id',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.GET_TRANSACTION_BY_ID) ? (
            <a href={`${ROUTES.TRANSACTIONS}/${row?.original?.id}/edit`} target="_blank" rel="noreferrer">
              {row?.original?.id}
            </a>
          ) : (
            <span>{row?.original?.id}</span>
          ),
      },
      {
        Header: t('editInvestment.transactionsList.method'),
        accessor: 'method',
      },
      {
        Header: t('editInvestment.transactionsList.type'),
        accessor: 'type',
      },
      {
        Header: t('editInvestment.transactionsList.status'),
        accessor: 'status',
        Cell: ({ row }) => {
          return <div>{mapTransactionStatus({ status: row?.original?.status, t })}</div>;
        },
      },
      {
        Header: t('editInvestment.transactionsList.amount'),
        accessor: 'amount',
        Cell: ({ row }) => {
          return <div>{row?.original?.amount?.toFixed?.(2)}</div>;
        },
      },
      {
        Header: t('editInvestment.transactionsList.originalAmount'),
        accessor: 'originalAmount',
        Cell: ({ row }) => {
          return <div>{row?.original?.originalAmount?.toFixed?.(2)}</div>;
        },
      },
      {
        Header: t('editInvestment.transactionsList.commission'),
        accessor: 'commission',
        Cell: ({ row }) => {
          return <div>{row?.original?.commission?.toFixed?.(2)}</div>;
        },
      },
      {
        Header: t('editInvestment.transactionsList.commissionPercent'),
        accessor: 'commissionPercent',
        Cell: ({ row }) => {
          return (
            <div>
              {(Number(row?.original?.commissionPercent ? row?.original?.commissionPercent : 0) * 100)?.toFixed?.(2)} %
            </div>
          );
        },
      },
      {
        Header: t('editInvestment.transactionsList.date'),
        accessor: 'date',
      },
      {
        Header: t('editInvestment.transactionsList.dateCompleted'),
        accessor: 'dateCompleted',
        Cell: ({ row }) => {
          return <div>{row?.original?.dateCompleted ? row?.original?.dateCompleted : '-'}</div>;
        },
      },
      {
        Header: t('editInvestment.transactionsList.dateRejected'),
        accessor: 'dateRejected',
        Cell: ({ row }) => {
          return <div>{row?.original?.dateRejected ? row?.original?.dateRejected : '-'}</div>;
        },
      },
      {
        Header: t('editInvestment.transactionsList.waitUntil'),
        accessor: 'waitUntil',
        Cell: ({ row }) => {
          return <div>{row?.original?.waitUntil ? row?.original?.waitUntil : '-'}</div>;
        },
      },
      {
        accessor: 'transactionId',
        Cell: ({ row }) => {
          return (
            <Button as={Link} to="/transactions" disabled>
              View
            </Button>
          );
        },
      },
    ],
    [t, permittedPermissions],
  );

  const ordersColumns = useMemo(
    () => [
      {
        Header: t('editInvestment.ordersList.id'),
        accessor: 'id',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.GET_ORDER_BY_ID) ? (
            <a href={`${ROUTES.ORDERS}/${row?.original?.id}/edit`} target="_blank" rel="noreferrer">
              {row?.original?.id}
            </a>
          ) : (
            <span>{row?.original?.id}</span>
          ),
      },
      {
        Header: t('editInvestment.ordersList.date'),
        accessor: 'date',
      },
      {
        Header: t('editInvestment.ordersList.dateCompleted'),
        accessor: 'dateCompleted',
      },
      {
        Header: t('editInvestment.ordersList.dateRejected'),
        accessor: 'dateRejected',
      },
      {
        Header: t('editInvestment.ordersList.pricePerShare'),
        accessor: 'pricePerShare',
      },
      {
        Header: t('editInvestment.ordersList.quantity'),
        accessor: 'quantity',
      },
      {
        Header: t('editInvestment.ordersList.status'),
        accessor: 'status',
        Cell: ({ row }) => {
          return <div>{mapTransactionStatus({ status: row?.original?.status, t })}</div>;
        },
      },
      {
        Header: t('editInvestment.ordersList.totalAmount'),
        accessor: 'totalAmount',
        Cell: ({ row }) => {
          return <div>{row?.original?.totalAmount?.toFixed?.(2)}</div>;
        },
      },
      {
        Header: t('editInvestment.ordersList.transactionId'),
        accessor: 'transactionId',
      },
      {
        Header: t('editInvestment.ordersList.transactionStatus'),
        accessor: 'transactionStatus',
        Cell: ({ row }) => {
          return <div>{mapTransactionStatus({ status: row?.original?.transactionStatus, t })}</div>;
        },
      },
      {
        Header: t('editInvestment.ordersList.investorData'),
        accessor: 'investorData',
        Cell: ({ row }) => {
          return (
            <div className="meta-popup">
              <Popup
                style={{ minWidth: '500px' }}
                wide
                position="top right"
                trigger={<Button content={t('actionButtons.investorData')} />}
                on="click"
              >
                <Grid container className="meta-popup--grid">
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.firstName')}:</b> {row?.original?.investorData?.firstName}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.lastName')}:</b> {row?.original?.investorData?.lastName}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.phoneNumber')}:</b> {row?.original?.investorData?.phoneNumber}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.address')}:</b> {row?.original?.investorData?.address?.address}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.city')}:</b> {row?.original?.investorData?.address?.city}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.country')}:</b>{' '}
                      {row?.original?.investorData?.address?.country?.name}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.state')}:</b> {row?.original?.investorData?.address?.state?.name}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.zip')}:</b> {row?.original?.investorData?.address?.zip}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.bankName')}:</b>{' '}
                      {row?.original?.investorData?.bankAccount?.bankName}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.accountNumber')}:</b>{' '}
                      {row?.original?.investorData?.bankAccount?.accountNumber}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <p>
                      <b>{t('editInvestment.ordersList.country')}:</b>{' '}
                      {row?.original?.investorData?.bankAccount?.country?.name}
                    </p>
                  </Grid.Row>
                  <Grid.Row className="meta-popup--row">
                    <Button disabled>View</Button>
                  </Grid.Row>
                </Grid>
              </Popup>
            </div>
          );
        },
      },
    ],
    [t, permittedPermissions],
  );

  const dividendsColumns = useMemo(() => [], [t]);

  return (
    <Card width={false} className="admin-investments">
      <CardHeader>
        <CardTitle title={t('singleMainTitle')} />
        <CardToolbar>
          <Button as={Link} type="button" to={ROUTES.INVESTMENTS}>
            {t('actionButtons.goBack')}
          </Button>
          <Button color="black" disabled={investmentStatus === FETCH_STATUS.LOADING} onClick={refreshPage}>
            {t('actionButtons.reload')}
          </Button>
        </CardToolbar>
      </CardHeader>
      {investmentError && mapErrorCodes({ errorCode: investmentError, t })}
      <CardBody transparent>
        {investmentStatus === FETCH_STATUS.LOADING || permissionCheckStatus === FETCH_STATUS.IDLE ? (
          <Skeleton variant={SKELETON_VARIANT.PREVIEW} />
        ) : (
          <>
            <Grid columns={2}>
              <Grid.Column>{renderInfo()}</Grid.Column>
              <Grid.Column>{renderUserAndOfferMeta()}</Grid.Column>
            </Grid>
            {investment?.shares?.length > 0 && (
              <>
                <h3>{t('tables.sharesTitle')}</h3>
                <Grid columns={1} stackable>
                  <Grid.Column>
                    <SimpleTable hideFooter data={investment?.shares} columns={sharesColumns} />
                  </Grid.Column>
                </Grid>
              </>
            )}
            {investment?.transactions?.dividend?.length > 0 && (
              <>
                <h3>{t('tables.dividendsTitle')}</h3>
                <Grid columns={1} stackable>
                  <Grid.Column>
                    <SimpleTable hideFooter data={investment?.transactions?.dividend} columns={dividendsColumns} />
                  </Grid.Column>
                </Grid>
              </>
            )}
            {investment?.transactions?.investment?.length > 0 && (
              <>
                <h3>{t('tables.transactionsInvestmentsTitle')}</h3>
                <Grid columns={1} stackable>
                  <Grid.Column>
                    <SimpleTable hideFooter data={investment?.transactions?.investment} columns={transactionsColumns} />
                  </Grid.Column>
                </Grid>
              </>
            )}
            {investment?.orders?.length > 0 && (
              <>
                <h3>{t('tables.ordersTitle')}</h3>
                <Grid columns={1} stackable>
                  <Grid.Column>
                    <SimpleTable hideFooter data={investment?.orders} columns={ordersColumns} />
                  </Grid.Column>
                </Grid>
              </>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
