import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import FETCH_STATUS from '@shared/consts/fetchStatuses';
import FaqForm from '@screens/faq/components/FaqForm';

const Main = ({ actions, createStatus, errors }) => {
  const FaqModel = {
    active: true,
    position: '1',
    question: {
      en: '',
      pl: '',
    },
    answer: {
      en: '',
      pl: '',
    },
  };

  return (
    <FaqForm
      isCreateMode
      faq={FaqModel}
      isLoading={createStatus === FETCH_STATUS.IDLE}
      faqErrors={errors}
      onCreateFaq={actions.createFaqAction}
    />
  );
};

Main.propTypes = {
  actions: shape({ createFaqAction: func }).isRequired,
  errors: arrayOf(shape({})),
  createStatus: string,
};

Main.defaultProps = {
  createStatus: undefined,
  errors: {},
};

export default Main;
