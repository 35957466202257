import React, { useMemo } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import objectPath from 'object-path';
import { useHtmlClassService } from '@metronic/providers';
import { Topbar } from './components';

const Header = ({ getMenu, logoutUser, user }) => {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(uiService.config, 'header.menu.self.display'),
    };
  }, [uiService]);

  return (
    <>
      <div className={`header ${layoutProps.headerClasses}`} id="kt_header" {...layoutProps.headerAttributes}>
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-end`}>
          <Topbar getMenu={getMenu} logoutUser={logoutUser} user={user} />
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  getMenu: func,
  topbarMenuItems: arrayOf(shape({})),
  logoutUser: func,
  user: shape({}),
};

Header.defaultProps = {
  getMenu: () => {},
  topbarMenuItems: [],
  logoutUser: () => {},
  user: {},
};

export default Header;
