import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  tableLeft: {
    fontWeight: 700,
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
  },
}));

export default useStyles;
