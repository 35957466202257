import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar } from '@shared/components';
import { Button, Table, Grid, Icon, Popup } from 'semantic-ui-react';
import { arrayOf, func, shape, string } from 'prop-types';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { useParams } from 'react-router';
import { Loader, Skeleton } from '@shared/layout-components';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { mapErrorCodes } from '@screens/transactions/utils';
import mapTransactionStatus from '@shared/utils/mapTransactionStatus';
import moment from 'moment';
import { DatePickerField } from '@shared/components/translatable';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import PERMISSIONS from '@shared/consts/permissions';
import { SKELETON_VARIANT } from '@shared/layout-components/Skeleton/consts';
import useStyles from './styles';

const Main = ({ actions, singleTransaction, status, error, permittedPermissions }) => {
  const params = useParams();
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.TRANSACTIONS);
  const [waitUntilPicker, setWaitUntilPicker] = useState('');

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMountWithTranslate(() => {
    actions.getSingleTransactionAction(params.id);
    setWaitUntilPicker(
      singleTransaction?.waitUntil
        ? moment(singleTransaction?.waitUntil).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
    );
  });

  useEffect(() => {
    if (singleTransaction?.waitUntil) {
      setWaitUntilPicker(moment(singleTransaction?.waitUntil).format('YYYY-MM-DD'));
    }
  }, [singleTransaction]);

  const onChangeWaitUntil = (e, { value }) => setWaitUntilPicker(value);

  const renderTransactionInfo = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('transactionInfo.header')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {singleTransaction?.meta?.canExtendWaitUntil && (
            <Table.Row style={{ fontWeight: '600' }}>
              <Table.Cell className={styles.tableLeft}>{t('transactionInfo.waitUntilPicker')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                <DatePickerField value={waitUntilPicker} onChange={onChangeWaitUntil} />
                {isUserPermitted(PERMISSIONS.EXTEND_TRANSACTION) ? (
                  <Button
                    style={{ margin: '5px' }}
                    color="blue"
                    disabled={!singleTransaction?.meta?.canExtendWaitUntil}
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals,no-alert
                      if (confirm(`${t('actionButtons.extendDisclaimer')} ${params.id}?`)) {
                        actions.extendTransactionAction({ id: params.id, waitUntil: waitUntilPicker });
                      }
                    }}
                  >
                    {t('actionButtons.extend')}
                  </Button>
                ) : null}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.id')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.id}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.methodId')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.methodId}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.method')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.method}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.type')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.type}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.status')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {mapTransactionStatus({ status: singleTransaction?.status, t })}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.amount')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.amount?.toFixed?.(2)} PLN
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.originalAmount')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.originalAmount?.toFixed?.(2)} PLN
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.commission')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.commission?.toFixed?.(2)} PLN
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.commissionPercent')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {(
                Number(singleTransaction?.commissionPercent ? singleTransaction?.commissionPercent : 0) * 100
              )?.toFixed?.(2)}{' '}
              %
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionInfo.date')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.date}
            </Table.Cell>
          </Table.Row>
          {singleTransaction?.dateCompleted && (
            <Table.Row style={{ color: 'green', fontWeight: '600' }}>
              <Table.Cell className={styles.tableLeft}>{t('transactionInfo.dateCompleted')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                {singleTransaction?.dateCompleted}
              </Table.Cell>
            </Table.Row>
          )}
          {singleTransaction?.dateRejected && (
            <Table.Row style={{ color: 'red', fontWeight: '600' }}>
              <Table.Cell className={styles.tableLeft}>{t('transactionInfo.dateRejected')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                {singleTransaction?.dateRejected}
              </Table.Cell>
            </Table.Row>
          )}
          {singleTransaction?.waitUntil && (
            <Table.Row style={{ color: 'gold', fontWeight: '600' }}>
              <Table.Cell className={styles.tableLeft}>{t('transactionInfo.waitUntil')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                {singleTransaction?.waitUntil}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  const renderTransactionMeta = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('transactionMeta.header')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isUserPermitted(PERMISSIONS.GET_INVESTMENT_BY_ID) ? (
            <Table.Row>
              <Table.Cell className={styles.tableLeft}>{t('transactionMeta.investmentId')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                <Button primary as={Link} to={`/investments/${singleTransaction?.meta?.investmentId}/edit`}>
                  {t('actionButtons.goToInvestment')}
                </Button>
              </Table.Cell>
            </Table.Row>
          ) : null}

          {isUserPermitted(PERMISSIONS.GET_OFFER_BY_ID) ? (
            <Table.Row>
              <Table.Cell className={styles.tableLeft}>{t('transactionMeta.offerId')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                <Button primary as={Link} to={`/offers/${singleTransaction?.meta?.offerId}/edit`}>
                  {t('actionButtons.goToOffer')}
                </Button>
              </Table.Cell>
            </Table.Row>
          ) : null}

          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionMeta.offerSlug')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.meta?.offerSlug?.[i18next.language]}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionMeta.offerTitle')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.meta?.offerTitle}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionMeta.sharesQuantity')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.meta?.sharesQuantity}
            </Table.Cell>
          </Table.Row>

          {isUserPermitted(PERMISSIONS.GET_ORDER_BY_ID) ? (
            <Table.Row>
              <Table.Cell className={styles.tableLeft}>{t('transactionMeta.orderId')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                <Button primary as={Link} to={`/orders/${singleTransaction?.meta?.orderId}/edit`}>
                  {t('actionButtons.goToOrder')}
                </Button>
              </Table.Cell>
            </Table.Row>
          ) : null}
        </Table.Body>
      </Table>
    );
  };

  const renderTransactionUser = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('transactionUser.header')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {singleTransaction?.meta?.user?.isDeleted && (
                <Popup basic content="User deleted" trigger={<Icon name="warning sign" color="yellow" />} />
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!singleTransaction?.meta?.user?.isDeleted && (
            <Table.Row>
              <Table.Cell className={styles.tableLeft}>{t('transactionUser.id')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                <Button primary as={Link} to={`/users/${singleTransaction?.meta?.user?.id}`}>
                  {t('actionButtons.goToUser')}
                </Button>
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionUser.email')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.meta?.user?.email}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionUser.firstName')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.meta?.user?.firstName}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('transactionUser.lastName')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {singleTransaction?.meta?.user?.lastName}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle title={t('title')} />
        <CardToolbar>
          <Button as={Link} type="button" to={ROUTES.TRANSACTIONS}>
            {t('actionButtons.goBack')}
          </Button>
          <Button
            color="black"
            disabled={status === FETCH_STATUS.IDLE || status === FETCH_STATUS.LOADING}
            onClick={() => actions.getSingleTransactionAction(params.id)}
          >
            {t('actionButtons.reload')}
          </Button>

          {isUserPermitted(PERMISSIONS.ACCEPT_TRANSACTION) ? (
            <Button
              color="green"
              disabled={
                !singleTransaction?.meta?.canAccept || status === FETCH_STATUS.IDLE || status === FETCH_STATUS.LOADING
              }
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals,no-alert
                if (confirm(`${t('actionButtons.acceptDisclaimer')} ${params.id}?`)) {
                  actions.acceptTransactionAction(params.id);
                }
              }}
            >
              {t('actionButtons.accept')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      {error && mapErrorCodes({ errorCode: error, t })}
      <CardBody>
        {status === FETCH_STATUS.IDLE || status === FETCH_STATUS.LOADING ? (
          <Skeleton variant={SKELETON_VARIANT.PREVIEW} />
        ) : (
          <>
            <Grid columns={2}>
              <Grid.Column>{renderTransactionInfo()}</Grid.Column>
              <Grid.Column>{renderTransactionMeta()}</Grid.Column>
            </Grid>
            <Grid columns={2} stackable>
              <Grid.Column>{renderTransactionUser()}</Grid.Column>
            </Grid>
          </>
        )}
      </CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({ getSingleTransactionAction: func }).isRequired,
  singleTransaction: shape({}),
  status: string,
  error: shape({}),
  permittedPermissions: arrayOf(shape({})),
};

Main.defaultProps = {
  singleTransaction: {},
  status: undefined,
  error: undefined,
  permittedPermissions: [],
};

export default Main;
