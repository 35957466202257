import React from 'react';
import { NavLink } from 'react-router-dom';
import useStyles from './styles';

const Footer = () => {
  const styles = useStyles();

  return (
    <footer className={styles.footer}>
      <NavLink className={styles.navLink} to="">
        Privacy
      </NavLink>
      <NavLink className={styles.navLink} to="">
        Legal
      </NavLink>
      <NavLink className={styles.navLink} to="">
        Contact
      </NavLink>
    </footer>
  );
};

export default Footer;
