const mapSingleOfferResponse = response => {
  return {
    ...response,
    badges: response?.badges?.map?.((badge, index) => ({ ...badge, backendIndex: index })),
    descriptions: response?.descriptions?.map?.((badge, index) => ({ ...badge, backendIndex: index })),
    properties: response?.properties?.map?.((badge, index) => ({ ...badge, backendIndex: index })),
    investorAttachments: response?.investorDocuments?.map?.((attachment, index) => ({
      ...attachment?.attachment,
      backendIndex: index,
      position: index + 1,
      notifyInvestors: attachment?.notifyInvestors,
      signable: attachment?.signable,
      required: attachment?.required,
    })),
    agreements:
      response?.agreements
        ?.sort((a, b) => Number(a.position) - Number(b.position))
        ?.map?.((attachment, index) => ({
          ...attachment,
          backendIndex: index,
          position: index + 1,
        })) || [],
    sections: response?.sections?.map?.((section, index) => ({
      ...section,
      backendIndex: index,
      elements: section.elements?.map?.((sectionElement, sectionElementIndex) => ({
        ...sectionElement,
        backendIndex: sectionElementIndex,
        elements: sectionElement?.elements?.map?.((innerElement, innerElementIndex) => ({
          ...innerElement,
          backendIndex: innerElementIndex,
        })),
      })),
    })),
  };
};

export default mapSingleOfferResponse;
