import {
  GET_OFFER_DOCUMENTS_INIT,
  GET_OFFER_DOCUMENTS_SUCCESS,
  GET_OFFER_DOCUMENTS_FAILURE,
} from '@store/action-types/offers';
import Client from '@api-sdk/client';

const getOfferDocumentsInit = () => ({ type: GET_OFFER_DOCUMENTS_INIT });
const getOfferDocumentsSuccess = documents => ({
  type: GET_OFFER_DOCUMENTS_SUCCESS,
  payload: { documents },
});
const getOfferDocumentsFailure = error => ({ type: GET_OFFER_DOCUMENTS_FAILURE, payload: { error } });

const getOfferDocuments = offerId => async dispatch => {
  dispatch(getOfferDocumentsInit());

  try {
    const documents = await Client.getOfferDocuments({ slug: `${offerId}/documents` });
    dispatch(getOfferDocumentsSuccess(documents.response));
  } catch (error) {
    dispatch(getOfferDocumentsFailure(error?.errors));
  }
};

export default getOfferDocuments;
