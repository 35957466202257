import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { arrayOf, func, shape, string } from 'prop-types';
import { Button } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import ROUTES from '@shared/consts/routes';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, NotFoundMessage, Table } from '@shared/components';
import i18next from 'i18next';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';

const Main = ({
  actions,
  allOffers,
  offerStatus,
  queryParams,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.OFFERS);
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const refreshPage = () => actions.getOffersAction();

  useMountWithTranslate(() => {
    actions.getOffersAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.OFFERS]) {
      if (
        !isUserPermitted(PERMISSIONS.CREATE_OFFER) ||
        !isUserPermitted(PERMISSIONS.DELETE_OFFER) ||
        !isUserPermitted(PERMISSIONS.EDIT_OFFER) ||
        !isUserPermitted(PERMISSIONS.GET_OFFER_BY_ID)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.OFFERS);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.CREATE_OFFER) && PERMISSIONS.CREATE_OFFER,
          !isUserPermitted(PERMISSIONS.DELETE_OFFER) && PERMISSIONS.DELETE_OFFER,
          !isUserPermitted(PERMISSIONS.EDIT_OFFER) && PERMISSIONS.EDIT_OFFER,
          !isUserPermitted(PERMISSIONS.GET_OFFER_BY_ID) && PERMISSIONS.GET_OFFER_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  const columns = useMemo(
    () => [
      {
        Header: t('position'),
        accessor: 'position',
        columnOrder: 'position',
      },
      {
        Header: t('title'),
        accessor: 'title',
        columnName: 'title',
        columnOrder: 'title',
      },
      {
        Header: t('slug'),
        accessor: 'slug',
        columnName: 'slug',
        columnOrder: 'slug',
        Cell: ({ row }) => `${row?.original?.slug?.[i18next.language]}`,
      },
      {
        Header: t('investmentStart'),
        accessor: 'investmentStart',
        className: 'text-center',
      },
      {
        Header: t('investmentEnd'),
        accessor: 'investmentEnd',
        className: 'text-center',
      },
      {
        Header: t('isOpen'),
        accessor: 'isOpen',
        columnName: 'isOpen',
        className: 'text-center',
        Cell: ({ row }) => {
          const isOpen = row?.original?.isOpen;
          return isOpen ? t('yes') : t('no');
        },
      },
      {
        Header: t('isVisible'),
        accessor: 'isVisible',
        columnName: 'isVisible',
        className: 'text-center',
        Cell: ({ row }) => {
          const isVisible = row?.original?.isVisible;
          return isVisible ? t('yes') : t('no');
        },
      },
      {
        accessor: 'actions',
        className: 'text-right',
        Cell: ({ row }) => {
          return (
            <>
              {isUserPermitted(PERMISSIONS.EDIT_OFFER) ? (
                <Button as={Link} to={`/offers/${row.original.id}/edit`}>
                  {t('edit')}
                </Button>
              ) : null}

              {isUserPermitted(PERMISSIONS.DELETE_OFFER) ? (
                <Button
                  color="red"
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals,no-alert
                    if (confirm(`${t('deleteOffer')} ${row.original.title}?`)) {
                      actions.deleteOfferAction(row.original.id);
                    }
                  }}
                >
                  {t('remove')}
                </Button>
              ) : null}
            </>
          );
        },
      },
    ],
    [t],
  );

  const renderContent = () => (
    <Table
      className="table-responsive"
      isLoading={offerStatus === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
      data={allOffers}
      columns={columns}
      queryParams={queryParams}
      searchAction={actions.getOffersAction}
      reloadAction={refreshPage}
      notFoundMessage={
        <NotFoundMessage
          title={t('notFoundOffers')}
          subtitle={t('clickBelow')}
          buttonText={t('create')}
          to={ROUTES.ADD_OFFER}
        />
      }
    />
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle title={t('mainTitle')} />
        <CardToolbar>
          <Button color="black" disabled={offerStatus === FETCH_STATUS.LOADING} onClick={refreshPage}>
            {t('reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.CREATE_OFFER) ? (
            <Button as={Link} primary to={ROUTES.ADD_OFFER}>
              {t('create')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>{renderContent()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({
    getOffersAction: func,
  }),
  allOffers: arrayOf(shape({})),
  offerStatus: string,
  row: shape({}),
  queryParams: shape({}),
  permittedPermissions: arrayOf(shape({})),
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};

Main.defaultProps = {
  actions: {},
  allOffers: [],
  offerStatus: FETCH_STATUS.IDLE,
  row: {},
  queryParams: {},
  permittedPermissions: [],
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
