import FETCH_STATUS from '@shared/consts/fetchStatuses';
import {
  CREATE_PERMISSION_FAILURE,
  CREATE_PERMISSION_INIT,
  CREATE_PERMISSION_SUCCESS,
  GET_PERMISSIONS_FAILURE,
  GET_PERMISSIONS_INIT,
  GET_PERMISSIONS_SUCCESS,
  GET_SINGLE_PERMISSION_FAILURE,
  GET_SINGLE_PERMISSION_INIT,
  GET_SINGLE_PERMISSION_SUCCESS,
  EDIT_PERMISSION_INIT,
  EDIT_PERMISSION_SUCCESS,
  EDIT_PERMISSION_FAILURE,

} from '@store/action-types/permissions';
import {
  CHECK_PERMISSION_FAILURE,
  CHECK_PERMISSION_INIT,
  CHECK_PERMISSION_SUCCESS,
} from '@store/action-types/check-permission';

const initialState = {
  allPermissions: [],
  singlePermission: {},
  errors: undefined,
  editStatus: undefined,
  permissionCheckStatus: undefined,
  permittedPermissions: {},
};

const permissionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PERMISSIONS_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
      };
    }
    case GET_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        allPermissions: [...payload.allPermissions],
        status: FETCH_STATUS.SUCCESS,
        errors: undefined,
      };
    }
    case GET_PERMISSIONS_FAILURE: {
      return {
        ...state,
        allPermissions: [],
        status: FETCH_STATUS.FAILURE,
        errors: payload.error,
      };
    }
    case GET_SINGLE_PERMISSION_INIT: {
      return {
        ...state,
        singleStatus: FETCH_STATUS.IDLE,
        editStatus: undefined,
      };
    }
    case GET_SINGLE_PERMISSION_SUCCESS: {
      return {
        ...state,
        singlePermission: payload.singlePermission,
        singleStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SINGLE_PERMISSION_FAILURE: {
      return {
        ...state,
        singlePermission: {},
        singleStatus: FETCH_STATUS.FAILURE,
        errors: payload.error,
      };
    }
    case EDIT_PERMISSION_INIT: {
      return {
        ...state,
        errors: undefined,
        editStatus: FETCH_STATUS.IDLE,
      };
    }
    case EDIT_PERMISSION_SUCCESS: {
      return {
        ...state,
        errors: undefined,
        editStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case EDIT_PERMISSION_FAILURE: {
      return {
        ...state,
        errors: payload?.errors,
        editStatus: FETCH_STATUS.FAILURE,
      };
    }
    case CREATE_PERMISSION_INIT:
    case CREATE_PERMISSION_SUCCESS: {
      return {
        ...state,
        errors: undefined,
      };
    }
    case CREATE_PERMISSION_FAILURE: {
      return {
        ...state,
        errors: payload?.errors,
      };
    }
    case CHECK_PERMISSION_INIT: {
      return {
        ...state,
        permissionCheckStatus: FETCH_STATUS.IDLE,
        permittedPermissions: {},
      };
    }
    case CHECK_PERMISSION_SUCCESS: {
      return {
        ...state,
        permissionCheckStatus: FETCH_STATUS.SUCCESS,
        permittedPermissions: payload?.permittedPermissions,
      };
    }
    case CHECK_PERMISSION_FAILURE: {
      return {
        ...state,
        permissionCheckStatus: FETCH_STATUS.FAILURE,
        permittedPermissions: {},
      };
    }
    default:
      return state;
  }
};

export default permissionReducer;
