import {
  YoutubeElementModel,
  TimelineElementModel,
  TextElementModel,
  MapElementModel,
  ImageElementModel,
  CounterElementModel,
  GalleryElementModel,
  AttachmentsElementModel,
  BarChartElementModel,
  PieChartElementModel,
  TableElementModel,
} from '../../models/elementModels';

const ELEMENT_TYPES = {
  gallery: { value: 'gallery', additionalFields: GalleryElementModel },
  attachments: { value: 'attachments', additionalFields: AttachmentsElementModel },
  barChart: { value: 'barChart', additionalFields: BarChartElementModel },
  pieChart: { value: 'pieChart', additionalFields: PieChartElementModel },
  counter: { value: 'counter', additionalFields: CounterElementModel },
  image: { value: 'image', additionalFields: ImageElementModel },
  map: { value: 'map', additionalFields: MapElementModel },
  text: { value: 'text', additionalFields: TextElementModel },
  timeline: { value: 'timeline', additionalFields: TimelineElementModel },
  youtube: { value: 'youtube', additionalFields: YoutubeElementModel },
  table: { value: 'table', additionalFields: TableElementModel, column: 'column', row: 'row' },
};

const getElementTypesDropdownOptions = t =>
  Object.keys(ELEMENT_TYPES).map(type => ({
    key: type,
    value: type,
    text: t(`elementTypes.${type}`),
  }));

export { ELEMENT_TYPES, getElementTypesDropdownOptions };
