import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import { Button, Divider, Form, Grid, Label, Segment } from 'semantic-ui-react';
import {
  InputField,
  SwitchField,
  TranslatableTextField,
  TranslatableRichTextField,
} from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { validateDocumentType, validateFileSize, validateImageType } from '@screens/page/utils';
import { Container } from '@material-ui/core';
import { VALID_IMAGE_MIME_TYPES } from '@screens/page/const';
import clsx from 'clsx';
import useStyles from './styles';

const SliderForm = ({
  isCreateMode,
  isLoading,
  onRefreshSlider,
  onSaveSlider,
  onCreateSlider,
  sliderErrors,
  imageUrl,
  slider,
  isViewMode,
}) => {
  const [t] = useTranslation(TRANSLATIONS.SLIDER);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);
  const [sliderData, setSliderData] = useState(slider);
  const [imageData, setImageData] = useState({});
  const [imageUploadError, setImageUploadError] = useState('');
  const params = useParams();
  const styles = useStyles();

  useEffect(() => {
    setSliderData(slider);
  }, [slider]);

  const onChangeContent = (e, { name, value }) =>
    setSliderData(prevState => ({
      ...prevState,
      [name]: value,
    }));

  const onSwitchChange = (e, { name }) =>
    setSliderData(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }));

  const onChangeTranslatableContent = (e, { name, value, language }) =>
    setSliderData(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));

  const handleImageChange = e => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if ((validateDocumentType([file]) || validateImageType([file])) && validateFileSize([file], 0, 10000000)) {
      setImageUploadError('');

      if (file) {
        reader.onloadend = () => {
          return setImageData(prevState => ({
            ...prevState,
            image: {
              fileName: file.name,
              delete: false,
              base64: reader.result,
            },
          }));
        };

        return reader.readAsDataURL(file);
      }
      return setImageUploadError(
        'Wrong file type or size, we accept common image file types, image file size cannot exceed 10 MB',
      );
    }
    return setImageUploadError(
      'Wrong file type or size, we accept common image file types, image file size cannot exceed 10 MB',
    );
  };

  const renderMenuButtons = () => (
    <Grid columns={2}>
      <Grid.Column>
        <h2>{isCreateMode ? t('addSlider') : t('editSlider')}</h2>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button style={{ marginRight: 5 }} as={Link} type="button" to={ROUTES.SLIDER_MANAGEMENT}>
          {t('actionButtons.goBack')}
        </Button>
        {!isCreateMode && (
          <Button
            disabled={isLoading}
            type="submit"
            secondary
            onClick={() => {
              setImageData({});
              onRefreshSlider(params.id);
            }}
            style={{ marginRight: '5px' }}
          >
            {t('actionButtons.reload')}
          </Button>
        )}

        {!isCreateMode && !isViewMode && (
          <Button
            disabled={isLoading}
            variant="outlined"
            primary
            style={{ marginRight: 5 }}
            onClick={() => onSaveSlider({ sliderData, imageData: imageData?.image, sliderId: params.id })}
          >
            {t('actionButtons.save')}
          </Button>
        )}

        {!isCreateMode && !isViewMode && (
          <Button
            disabled={isLoading}
            variant="outlined"
            color="facebook"
            onClick={() =>
              onSaveSlider({ sliderData, imageData: imageData?.image, sliderId: params.id, saveAndBack: true })
            }
          >
            {t('actionButtons.saveAndBack')}
          </Button>
        )}

        {isCreateMode && (
          <Button
            disabled={isLoading}
            variant="outlined"
            primary
            style={{ marginRight: 5 }}
            onClick={() => onCreateSlider({ sliderData, imageData })}
          >
            {t('actionButtons.add')}
          </Button>
        )}

        {isCreateMode && (
          <Button
            disabled={isLoading}
            variant="outlined"
            color="facebook"
            onClick={() => onCreateSlider({ sliderData, imageData, saveAndBack: true })}
          >
            {t('actionButtons.addAndBack')}
          </Button>
        )}
      </Grid.Column>
    </Grid>
  );

  return (
    <Container maxWidth="md">
      {renderMenuButtons()}
      <Segment className={styles.segment}>
        <Form loading={isLoading}>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <InputField
                disabled={isViewMode}
                icon="numbered list"
                label={t('position')}
                name="position"
                onChange={onChangeContent}
                value={sliderData?.position}
                errorMessage={error(sliderErrors?.position)}
                labelClassName={styles.label}
              />
              <Grid.Column className="d-flex justify-content-around align-items-center">
                <SwitchField
                  disabled={isViewMode}
                  onChange={onSwitchChange}
                  className="text-center"
                  name="active"
                  value={sliderData?.active}
                  label={t('active')}
                />
              </Grid.Column>
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="name"
                label={t('name')}
                value={sliderData?.name}
                errorMessage={error(sliderErrors?.name)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="guestButtonLabel"
                label={t('guestButtonLabel')}
                value={sliderData?.guestButtonLabel}
                errorMessage={error(sliderErrors?.guestButtonLabel)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="guestButtonUrl"
                label={t('guestButtonUrl')}
                value={sliderData?.guestButtonUrl}
                errorMessage={error(sliderErrors?.guestButtonUrl)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="loggedUserButtonLabel"
                label={t('loggedUserButtonLabel')}
                value={sliderData?.loggedUserButtonLabel}
                errorMessage={error(sliderErrors?.loggedUserButtonLabel)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="loggedUserButtonUrl"
                label={t('loggedUserButtonUrl')}
                value={sliderData?.loggedUserButtonUrl}
                errorMessage={error(sliderErrors?.loggedUserButtonUrl)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableRichTextField
                disabled={isViewMode}
                label={t('content')}
                name="content"
                errorMessage={error(sliderErrors?.content)}
                value={sliderData?.content}
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={1} divided>
              <Grid.Column>
                {isViewMode ? null : (
                  <Form.Field>
                    <label aria-controls="" htmlFor="create-page-add-file">
                      <div className="d-flex">
                        <p>{t('image')}</p>
                        {(imageUploadError || sliderErrors?.image) && (
                          <Label color="red" className={clsx('ml-4', styles.label)}>
                            {imageUploadError || error(sliderErrors?.image)}
                          </Label>
                        )}
                      </div>
                      <input
                        id="create-page-add-file"
                        type="file"
                        onChange={handleImageChange}
                        accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                      />
                    </label>
                  </Form.Field>
                )}
                <Grid container stackable columns={1} divided>
                  <Grid.Column className="d-flex flex-column align-items-center justify-content-center">
                    <Form.Field className="d-flex flex-column align-items-center justify-content-center">
                      {(imageData?.image?.base64 || (!isCreateMode && imageUrl)) && !imageData?.image?.delete && (
                        <label>{t('imagePreview')}</label>
                      )}

                      {(imageData?.image?.base64 || (!isCreateMode && imageUrl)) && !imageData?.image?.delete && (
                        <img
                          style={{ maxWidth: '100%' }}
                          className={clsx(styles.imagePreview, imageData?.image?.delete && styles.deletedPhoto)}
                          src={imageData?.image?.base64 || imageUrl}
                          alt=""
                        />
                      )}
                    </Form.Field>

                    {(imageData?.image?.base64 || (!isCreateMode && imageUrl)) &&
                      !imageData?.image?.delete &&
                      !isViewMode && (
                        <Button
                          className="mt-3"
                          onClick={e => {
                            e.preventDefault();
                            setImageData({ image: { base64: undefined, delete: true, name: undefined } });
                          }}
                        >
                          {t('actionButtons.remove')}
                        </Button>
                      )}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      {renderMenuButtons()}
    </Container>
  );
};

SliderForm.propTypes = {
  isViewMode: bool,
  isCreateMode: bool,
  isLoading: bool,
  onRefreshSlider: func,
  onSaveSlider: func,
  onCreateSlider: func,
  sliderErrors: arrayOf(shape({})),
  slider: shape({}),
  imageUrl: shape({}),
};

SliderForm.defaultProps = {
  isViewMode: false,
  isCreateMode: false,
  isLoading: false,
  onRefreshSlider: () => {},
  onSaveSlider: () => {},
  onCreateSlider: () => {},
  sliderErrors: [],
  slider: {},
  imageUrl: {},
};

export default SliderForm;
