import {
  GET_INVESTMENTS_SUCCESS,
  GET_INVESTMENTS_INIT,
  GET_INVESTMENTS_FAILURE,
} from '@store/action-types/investments';
import client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getInvestmentsInit = () => ({ type: GET_INVESTMENTS_INIT });
const getInvestmentsSuccess = ({ response, activeFilters, pagination }) => ({
  type: GET_INVESTMENTS_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredPagination: response?.pagination,
    pagination,
  },
});
const getInvestmentsFailure = error => ({ type: GET_INVESTMENTS_FAILURE, payload: { error } });

const getInvestments = ({ activeFilters, pagination } = {}) => async dispatch => {
  dispatch(getInvestmentsInit());
  const urlQueryParams = getUrlQueryParams();

  try {
    const investmentsResponse = await client.getInvestments({
      params: activeFilters || urlQueryParams?.all,
    });

    dispatch(
      getInvestmentsSuccess({
        response: investmentsResponse.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
      }),
    );
  } catch (error) {
    dispatch(getInvestmentsFailure(error));
  }
};

export default getInvestments;
