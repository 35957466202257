import { DELETE_SERIES_INIT, DELETE_SERIES_SUCCESS, DELETE_SERIES_FAILURE } from '@store/action-types/series';
import client from '@api-sdk/client';
import getSeries from '@store/actions/series/getSeries';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deleteSeriesInit = () => ({ type: DELETE_SERIES_INIT });
const deleteSeriesSuccess = singleSeries => ({
  type: DELETE_SERIES_SUCCESS,
  payload: { singleSeries },
});
const getSingleSeriesFailure = error => ({ type: DELETE_SERIES_FAILURE, payload: { error } });

const deleteSeries = id => async dispatch => {
  dispatch(deleteSeriesInit());

  try {
    const singleSeriesDeleteResponse = await client.deleteSeries({ slug: id });
    await dispatch(deleteSeriesSuccess(singleSeriesDeleteResponse));
    dispatch(getSeries());
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('series.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('series.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(getSingleSeriesFailure(error));
  }
};

export default deleteSeries;
