import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import SocialItem from '@screens/settings/components/SocialMedia/components/SocialItem';

const SocialMedia = ({ isLoading, status, configData, setConfigData }) => {
  return (
    <Form loading={status === FETCH_STATUS.IDLE || isLoading}>
      <Grid container stackable columns={2}>
        {configData?.socialMediaIcons?.map(item => (
          <SocialItem social={item} key={item.id} setConfigData={setConfigData} configData={configData} />
        ))}
        <Grid.Column />
      </Grid>
    </Form>
  );
};

SocialMedia.propTypes = {
  isLoading: bool,
  status: string,
  configData: shape({}),
  setConfigData: func,
};

SocialMedia.defaultProps = {
  isLoading: false,
  status: undefined,
  configData: {},
  setConfigData: () => {},
};

export default SocialMedia;
