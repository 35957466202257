import {
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_INIT,
  GET_TRANSACTIONS_SUCCESS,
} from '@store/action-types/transactions';
import client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getTransactionsInit = () => ({ type: GET_TRANSACTIONS_INIT });
const getTransactionsSuccess = ({ response, activeFilters, pagination, order }) => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredOrder: response?.configuredOrder,
    appliedOrder: response?.appliedOrder,
    configuredPagination: response?.pagination,
    pagination,
    order,
  },
});
const getTransactionsFailure = error => ({ type: GET_TRANSACTIONS_FAILURE, payload: { error } });

const getTransactions = ({ activeFilters, pagination, order } = {}) => async dispatch => {
  dispatch(getTransactionsInit());
  const urlQueryParams = getUrlQueryParams();

  try {
    const transactions = await client.getTransactions({
      params: activeFilters || urlQueryParams?.all,
    });

    dispatch(
      getTransactionsSuccess({
        response: transactions.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
        order: order || urlQueryParams?.order,
      }),
    );
  } catch (error) {
    dispatch(getTransactionsFailure(error?.errors?.[0]?.code));
  }
};

export default getTransactions;
