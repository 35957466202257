import {
  CREATE_SERIES_INIT,
  CREATE_SERIES_SUCCESS,
  CREATE_SERIES_FAILURE,
  GET_SINGLE_SERIES_SUCCESS,
} from '@store/action-types/series';
import client from '@api-sdk/client';
import { set, toPath } from 'lodash';
import history from '@store/history';
import { enqueueSnackbar } from '@store/actions/toast';
import moment from 'moment';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';

const createSeriesInit = () => ({ type: CREATE_SERIES_INIT });
const createSeriesSuccess = singleSeries => ({
  type: CREATE_SERIES_SUCCESS,
  payload: { singleSeries },
});

const createSeriesFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    set(acc, path, item.code.split(':').pop() || item.message);

    return acc;
  }, {});

  return { type: CREATE_SERIES_FAILURE, payload: { error: { ...error, errors: mappedErrors } } };
};

const getSingleSeriesSuccess = singleSeries => ({
  type: GET_SINGLE_SERIES_SUCCESS,
  payload: { singleSeries },
});

const createSeries = ({ seriesData, saveAndBack = false }) => async dispatch => {
  dispatch(createSeriesInit());

  try {
    const createSeriesResponse = await client.createSeries({
      data: {
        limit: seriesData?.limit,
        pricePerShare: seriesData?.pricePerShare,
        bankInfo: {
          accountNumber: seriesData?.bankInfo?.accountNumber,
          bankName: seriesData?.bankInfo?.bankName,
          accountHolder: seriesData?.bankInfo?.accountHolder,
        },
        availableFrom: moment(seriesData?.availableFrom).format('YYYY-MM-DD HH:mm:ss'),
        availableTo: moment(seriesData?.availableTo).format('YYYY-MM-DD HH:mm:ss'),
        name: seriesData?.name,
        displayName: seriesData?.displayName,
        useDefaultPayPalConfig: seriesData?.useDefaultPayPalConfig,
        allowOnlinePayments: seriesData?.allowOnlinePayments,
        payPalCredentials: {
          productionMode: seriesData?.payPalCredentials?.productionMode,
          productionClientId: seriesData?.payPalCredentials?.productionClientId,
          productionClientSecret: seriesData?.payPalCredentials?.productionClientSecret,
          sandboxClientId: seriesData?.payPalCredentials?.sandboxClientId,
          sandboxClientSecret: seriesData?.payPalCredentials?.sandboxClientSecret,
        },
      },
      slug: seriesData?.id,
    });

    await dispatch(createSeriesSuccess(createSeriesResponse));
    await dispatch(
      getSingleSeriesSuccess({
        ...createSeriesResponse,
        response: {
          ...createSeriesResponse.response,
          availableTo: createSeriesResponse?.response?.availableTo
            ? moment(createSeriesResponse?.response?.availableTo)
            : moment(),
          availableFrom: createSeriesResponse?.response?.availableFrom
            ? moment(createSeriesResponse?.response?.availableFrom)
            : moment(),
        },
      }),
    );

    if (saveAndBack) {
      history.push(ROUTES.SERIES);
    } else {
      history.push(`/series/${createSeriesResponse.response?.id}/edit`);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('series.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('series.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(createSeriesFailure(error));
  }
};

export default createSeries;
