import React from 'react';
import { Divider, Container, Typography } from '@material-ui/core';
import useStyles from './styles';

const Main = () => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <Typography variant="h1">Dashboard</Typography>
      <Divider />
    </Container>
  );
};

Main.propTypes = {};
Main.defaultProps = {};

export default Main;
