import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Button, Grid, Menu, Tab } from 'semantic-ui-react';
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { useTranslation } from 'react-i18next';
import useMount from '@shared/utils/useMountHook';
import TRANSLATIONS from '@translations/translationNamespaces';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { InvestmentAgreements, InvestorData } from '@screens/orders/add-order/components';
import { Loader } from '@shared/layout-components';
import { isEmpty } from 'lodash';
import { Tooltip } from '@shared/components';
import { ManualInvestingModel, ManualInvestingAgreementsModel } from './models';
import useStyles from './styles';

const Main = ({
  actions,
  countries,
  countriesStatus,
  offersOptions,
  offerStatus,
  states,
  offerOptionsForInvesting,
  userEmail,
  createOrderErrors,
}) => {
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.ORDERS);
  const firstLoad = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [manualInvestingData, setManualInvestingData] = useState(ManualInvestingModel);
  const [agreementsData, setAgreementsData] = useState(ManualInvestingAgreementsModel);

  const isFetchingAgreements = offerStatus === FETCH_STATUS.IDLE;
  const isOfferEmpty = isEmpty(manualInvestingData?.offer);
  const isAgreementToAccept =
    !isEmpty(manualInvestingData?.offer) &&
    agreementsData?.find(agreement => agreement.isRequired && !agreement.isAccepted);

  useMount(() => {
    actions.getCountriesAction();
    actions.getOffersAction({
      activeFilters: {
        isOpen: {
          prototypeFieldName: 'value',
          searchValue: true,
          type: 'filter',
        },
      },
    });
    firstLoad.current = false;
  });

  useEffect(() => {
    !isEmpty(offerOptionsForInvesting?.agreements) &&
      setAgreementsData(
        offerOptionsForInvesting?.agreements?.map(agreement => ({
          agreement: agreement.id,
          isAccepted: false,
          isRequired: agreement.required,
        })),
      );
  }, [offerOptionsForInvesting.agreements]);

  useEffect(() => {
    !isEmpty(offerOptionsForInvesting?.series) &&
      setManualInvestingData(prevState => ({
        ...prevState,
        series: offerOptionsForInvesting?.series,
      }));
  }, [offerOptionsForInvesting.series]);

  const getOfferAgreements = ({ offerId }) => actions.getSingleOfferAction(offerId);

  const checkEmail = () =>
    actions.getUsersAction({
      activeFilters: {
        email: {
          prototypeFieldName: 'value',
          searchValue: manualInvestingData?.email,
          type: 'filter',
        },
      },
    });

  const onSave = async ({ saveAndBack }) => {
    await setIsLoading(true);
    await actions.createOrderAction({ agreementsData, investorData: manualInvestingData, saveAndBack });
    await setIsLoading(false);
  };

  const disableSaveButtons = () =>
    isOfferEmpty || isAgreementToAccept || isFetchingAgreements || manualInvestingData?.quantity <= 0 || isLoading;

  const renderMenuButtons = () => (
    <Grid columns={2} className="my-1">
      <Grid.Column>
        <h2>{t('form.manualInvesting')}</h2>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button as={Link} type="button" to={ROUTES.ORDERS}>
          {t('actionButtons.goBack')}
        </Button>

        <Button disabled={disableSaveButtons()} variant="outlined" primary onClick={onSave} loading={isLoading}>
          {t('actionButtons.create')}
        </Button>

        <Button
          disabled={disableSaveButtons()}
          variant="outlined"
          color="facebook"
          onClick={() => onSave({ saveAndBack: true })}
          loading={isLoading}
        >
          {t('actionButtons.createAndBack')}
        </Button>
      </Grid.Column>
    </Grid>
  );

  const panes = [
    {
      menuItem: (
        <Menu.Item>
          {t('investorData')}
          {!isEmpty(createOrderErrors) ? (
            <Tooltip name="warning" color="red">
              {t('tooltip.createOrderError')}
            </Tooltip>
          ) : null}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <InvestorData
            createOrderErrors={createOrderErrors}
            isLoading={
              isLoading ||
              countriesStatus === FETCH_STATUS.IDLE ||
              (offerStatus === FETCH_STATUS.IDLE && firstLoad.current)
            }
            manualInvestingData={manualInvestingData}
            setManualInvestingData={setManualInvestingData}
            setAgreementsData={setAgreementsData}
            countries={countries}
            states={
              states.find(
                countryState => countryState.countryId === manualInvestingData?.investorData?.address?.country,
              )?.states
            }
            offersOptions={offersOptions}
            getOfferAgreements={getOfferAgreements}
            checkEmail={checkEmail}
            isEmailBusy={!isEmpty(userEmail) && !isEmpty(manualInvestingData?.email)}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item disabled={isEmpty(manualInvestingData?.offer)}>
          {t('agreements')}
          {isFetchingAgreements ? <Loader className={styles.tabLoader} /> : null}
          {isOfferEmpty && !isFetchingAgreements ? (
            <Tooltip name="warning" color="red">
              {t('tooltip.offerIsEmpty')}
            </Tooltip>
          ) : null}
          {isAgreementToAccept && !isFetchingAgreements ? (
            <Tooltip color="yellow">{t('tooltip.agreementsIsRequired')}</Tooltip>
          ) : null}
          {!isAgreementToAccept && !isOfferEmpty && !isFetchingAgreements ? (
            <Tooltip name="checkmark" color="green">
              {t('tooltip.allAgreementsIsAccepted')}
            </Tooltip>
          ) : null}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane loading={isLoading || offerStatus === FETCH_STATUS.IDLE}>
          <InvestmentAgreements
            isLoading={isLoading || offerStatus === FETCH_STATUS.IDLE}
            agreements={offerOptionsForInvesting?.agreements}
            agreementsData={agreementsData}
            setAgreementsData={setAgreementsData}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container maxWidth="md">
      {renderMenuButtons()}
      <Tab panes={panes} />
      {renderMenuButtons()}
    </Container>
  );
};

Main.propTypes = {
  actions: shape({
    getCountriesAction: func,
  }).isRequired,
  countries: arrayOf(shape({})),
  countriesStatus: string,
  offersOptions: arrayOf(shape({})),
  offerStatus: string,
  states: arrayOf(shape({})),
  offerOptionsForInvesting: shape({}),
  userEmail: arrayOf(shape({})),
  createOrderErrors: shape({}),
};

Main.defaultProps = {
  countries: [],
  countriesStatus: undefined,
  offersOptions: [],
  offerStatus: undefined,
  states: [],
  offerOptionsForInvesting: {},
  userEmail: [],
  createOrderErrors: {},
};

export default Main;
