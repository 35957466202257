import React, { useEffect, useMemo } from 'react';
import { func, shape, string, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, NotFoundMessage, Table } from '@shared/components';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';

const Main = ({
  actions,
  seriesStatus,
  allSeries,
  queryParams,
  permittedPermissions,
  checkPermissionStatus,
  isPermissionChecked,
}) => {
  const [t] = useTranslation(TRANSLATIONS.SERIES);
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const refreshPage = () => actions.getSeriesAction();

  useMountWithTranslate(() => {
    actions.getSeriesAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.SERIES]) {
      if (
        !isUserPermitted(PERMISSIONS.GET_SERIES_BY_ID) ||
        !isUserPermitted(PERMISSIONS.CREATE_SERIES) ||
        !isUserPermitted(PERMISSIONS.DELETE_SERIES) ||
        !isUserPermitted(PERMISSIONS.EDIT_SERIES)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.SERIES);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.GET_SERIES_BY_ID) && PERMISSIONS.GET_SERIES_BY_ID,
          !isUserPermitted(PERMISSIONS.CREATE_SERIES) && PERMISSIONS.CREATE_SERIES,
          !isUserPermitted(PERMISSIONS.DELETE_SERIES) && PERMISSIONS.DELETE_SERIES,
          !isUserPermitted(PERMISSIONS.EDIT_SERIES) && PERMISSIONS.EDIT_SERIES,
        ]);
      }
    }
  }, [permittedPermissions]);

  const columns = useMemo(
    () => [
      {
        Header: t('table.seriesName'),
        accessor: 'name',
        columnName: 'name',
        columnOrder: 'name',
      },
      {
        Header: t('table.limit'),
        accessor: 'limit',
        columnOrder: 'limit',
      },
      {
        Header: t('table.numberOfShares'),
        accessor: 'numberOfShares',
      },
      {
        Header: t('table.pricePerShare'),
        accessor: 'pricePerShare',
        columnOrder: 'pricePerShare',
      },
      {
        accessor: 'actions',
        className: 'text-right',
        Cell: ({ row }) => {
          return (
            <>
              {isUserPermitted(PERMISSIONS.EDIT_SERIES) ? (
                <Button as={Link} to={`/series/${row.original.id}/edit`}>
                  {t('actionButtons.edit')}
                </Button>
              ) : null}

              {isUserPermitted(PERMISSIONS.DELETE_SERIES) ? (
                <Button
                  color="red"
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals,no-alert
                    if (confirm(`${t('deleteSeriesDisclaimer')} ${row.original.name}?`)) {
                      actions.deleteSeriesAction(row.original.id);
                    }
                  }}
                >
                  {t('actionButtons.remove')}
                </Button>
              ) : null}
            </>
          );
        },
      },
    ],
    [t, permittedPermissions],
  );

  const renderContent = () => (
    <Table
      className="table-responsive"
      isLoading={seriesStatus === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
      data={allSeries}
      columns={columns}
      queryParams={queryParams}
      searchAction={actions.getSeriesAction}
      reloadAction={refreshPage}
      notFoundMessage={
        <NotFoundMessage
          title={t('notFoundSeries')}
          subtitle={t('clickBelow')}
          buttonText={t('actionButtons.add')}
          to={ROUTES.ADD_SERIES}
        />
      }
    />
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle title={t('mainTitle')} />
        <CardToolbar>
          <Button disabled={seriesStatus === FETCH_STATUS.LOADING} color="black" onClick={refreshPage}>
            {t('actionButtons.reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.CREATE_SERIES) ? (
            <Button as={Link} primary to={ROUTES.ADD_SERIES}>
              {t('actionButtons.add')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>{renderContent()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({
    getSeriesAction: func,
    deleteSeriesAction: func,
  }).isRequired,
  seriesStatus: string.isRequired,
  allSeries: arrayOf(shape({})).isRequired,
};

export default Main;
