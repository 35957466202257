import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getAllTransactions,
  getTransactionListError,
  getTransactionStatus,
  getAllTransactionsQueryParams,
} from '@store/reducers/transactions/selectors';
import { acceptTransactionAction, getTransactionsAction } from '@store/actions/transactions';
import { checkPermissionAction, setIsPermissionCheckedAction } from '@store/actions/check-permission';
import {
  getPermittedPermissions,
  getPermissionCheckStatus,
  getIsPermissionChecked,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  allTransactions: getAllTransactions(state),
  queryParams: getAllTransactionsQueryParams(state),
  status: getTransactionStatus(state),
  transactionListError: getTransactionListError(state),
  permittedPermissions: getPermittedPermissions(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
  isPermissionChecked: getIsPermissionChecked(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getTransactionsAction,
      acceptTransactionAction,
      checkPermissionAction,
      setIsPermissionCheckedAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
