import React from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { Checkbox, Form, Icon, Table } from 'semantic-ui-react';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { NotFoundMessage } from '@shared/components';

const InvestmentAgreements = ({ agreements, agreementsData, setAgreementsData }) => {
  const [t] = useTranslation(TRANSLATIONS.ORDERS);
  const platformLanguage = i18next.language;

  const onAgreementCheckboxChange = agreementIndex =>
    setAgreementsData(prevState =>
      prevState.map((agreement, index) =>
        index === agreementIndex ? { ...agreement, isAccepted: !agreement.isAccepted } : agreement,
      ),
    );

  const onSelectAllAgreements = (name, { checked }) => {
    setAgreementsData(prevState => prevState?.map(item => ({ ...item, isAccepted: checked })));
  };

  return (
    <Form>
      {agreements
        ?.filter?.(agreement => agreement.active)
        ?.map?.((agreement, index) => (
          <Table key={index} striped color="black">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{agreement.name?.[platformLanguage]}</Table.HeaderCell>
                <Table.HeaderCell className="position-relative" textAlign="right" width="one">
                  {agreement.required ? <Icon name="asterisk" color="red" /> : null}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <span dangerouslySetInnerHTML={{ __html: agreement.content?.[platformLanguage] }} />
                </Table.Cell>
                <Table.Cell textAlign="right" width="one">
                  <Checkbox
                    checked={agreementsData?.[index]?.isAccepted}
                    onChange={() => onAgreementCheckboxChange(index)}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ))}
      {isEmpty(agreements) ? <NotFoundMessage title="Investment has not agreements" /> : null}
      {!isEmpty(agreements) ? (
        <div className="d-flex w-100 justify-content-end">
          <div className="d-flex p-3">
            <p className="mr-3">{t('selectAll')}</p>
            <Checkbox
              checked={agreementsData?.every(agreement => agreement.isAccepted)}
              onChange={onSelectAllAgreements}
            />
          </div>
        </div>
      ) : null}
    </Form>
  );
};

InvestmentAgreements.propTypes = {
  agreements: arrayOf(shape({})),
  agreementsData: arrayOf(shape({})),
  setAgreementsData: func,
};

InvestmentAgreements.defaultProps = {
  agreements: [],
  agreementsData: [],
  setAgreementsData: () => {},
};

export default InvestmentAgreements;
