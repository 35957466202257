import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getSingleTransactionAction,
  extendTransactionAction,
  acceptTransactionAction,
} from '@store/actions/transactions';
import {
  getSingleTransaction,
  getTransactionError,
  getTransactionStatus,
} from '@store/reducers/transactions/selectors';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  singleTransaction: getSingleTransaction(state),
  status: getTransactionStatus(state),
  error: getTransactionError(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSingleTransactionAction,
      extendTransactionAction,
      acceptTransactionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
