import { GET_SERIES_INIT, GET_SERIES_SUCCESS, GET_SERIES_FAILURE } from '@store/action-types/series';
import client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getSeriesInit = () => ({ type: GET_SERIES_INIT });
const getSeriesSuccess = ({ response, activeFilters, pagination, order }) => ({
  type: GET_SERIES_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredOrder: response?.configuredOrder,
    appliedOrder: response?.appliedOrder,
    configuredPagination: response?.pagination,
    pagination,
    order,
  },
});
const getSeriesFailure = error => ({ type: GET_SERIES_FAILURE, payload: { error } });

const getSeries = ({ activeFilters, pagination, order } = {}) => async dispatch => {
  dispatch(getSeriesInit());
  const urlQueryParams = getUrlQueryParams();

  try {
    const series = await client.getSeries({ params: activeFilters || urlQueryParams?.all });
    dispatch(
      getSeriesSuccess({
        response: series.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
        order: order || urlQueryParams?.order,
      }),
    );
  } catch (error) {
    dispatch(getSeriesFailure(error));
  }
};

export default getSeries;
