import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Skeleton } from '@shared/layout-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, Tooltip } from '@shared/components';
import { Button, Table, Grid, Dropdown, Label, Popup, Icon } from 'semantic-ui-react';
import i18next from 'i18next';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { mapErrorCodes } from '@screens/transactions/utils';
import mapTransactionStatus from '@shared/utils/mapTransactionStatus';
import { InputField } from '@shared/components/translatable';
import ROUTES from '@shared/consts/routes';
import { SKELETON_VARIANT } from '@shared/layout-components/Skeleton/consts';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import useStyles from './styles';
import './styles.scss';

const Main = ({
  actions,
  order,
  orderError,
  orderStatus,
  countries,
  inputOrderErrors,
  permittedPermissions,
  investorDocuments,
  documentsStatus,
}) => {
  const [investorData, editInvestorData] = useState(order?.investorData);
  const [offerId, setOfferId] = useState('');
  const [t] = useTranslation(TRANSLATIONS.ORDERS);
  const params = useParams();
  const styles = useStyles();

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const refreshPage = () => {
    actions.getSingleOrderAction(params.id);
    actions.getCountriesAction();
    editInvestorData(order?.investorData);
    actions.getOfferDocumentsAction(offerId);
  };

  useMountWithTranslate(() => {
    actions.getSingleOrderAction(params.id);
    actions.getCountriesAction();
    editInvestorData(order?.investorData);
  });

  useEffect(() => {
    if (order?.offer?.id) {
      actions.getOfferDocumentsAction(order?.offer?.id);
      setOfferId(order?.offer?.id);
    }
  }, [order?.offer?.id]);

  useEffect(() => {
    editInvestorData(order?.investorData);
  }, [order]);

  const renderOrderInfo = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('editOrder.orderInfo.title')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.orderInfo.id')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.id}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.orderInfo.date')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.date}
            </Table.Cell>
          </Table.Row>
          {order?.dateCompleted && (
            <Table.Row
              style={{
                color: 'green',
                fontWeight: '600',
              }}
            >
              <Table.Cell className={styles.tableLeft}>{t('editOrder.orderInfo.dateCompleted')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                {order?.dateCompleted}
              </Table.Cell>
            </Table.Row>
          )}
          {order?.dateRejected && (
            <Table.Row
              style={{
                color: 'red',
                fontWeight: '600',
              }}
            >
              <Table.Cell className={styles.tableLeft}>{t('editOrder.orderInfo.dateRejected')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                {order?.dateRejected}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.orderInfo.investmentId')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {isUserPermitted(PERMISSIONS.GET_INVESTMENT_BY_ID) ? (
                <a href={`${ROUTES.INVESTMENTS}/${order?.investmentId}/edit`} target="_blank" rel="noreferrer">
                  {order?.investmentId}
                </a>
              ) : (
                <span>{order?.investmentId}</span>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.orderInfo.pricePerShare')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.pricePerShare}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.orderInfo.quantity')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.quantity}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.orderInfo.status')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {mapTransactionStatus({
                status: order?.status,
                t,
              })}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.orderInfo.totalAmount')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.totalAmount?.toFixed?.(2)} PLN
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  const renderInvestorAndUserData = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('editOrder.investorInfo.title')}{' '}
              {order?.userMeta?.isDeleted && (
                <Popup
                  basic
                  content="User deleted"
                  trigger={<Icon className="ml-1" name="warning sign" color="yellow" />}
                />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.firstName')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.canEditOrder ? (
                <InputField
                  errorMessage={inputOrderErrors?.investorData?.firstName}
                  value={investorData?.firstName}
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  name="firstName"
                  disableIcon
                  onChange={(e, { name, value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                />
              ) : (
                order?.investorData?.firstName
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.lastName')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.canEditOrder ? (
                <InputField
                  errorMessage={inputOrderErrors?.investorData?.lastName}
                  value={investorData?.lastName}
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  name="lastName"
                  disableIcon
                  onChange={(e, { name, value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                />
              ) : (
                order?.investorData?.lastName
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.phoneNumber')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.canEditOrder ? (
                <InputField
                  value={investorData?.phoneNumber}
                  errorMessage={inputOrderErrors?.investorData?.phoneNumber}
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  name="phoneNumber"
                  disableIcon
                  onChange={(e, { name, value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                />
              ) : (
                order?.investorData?.phoneNumber
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.addressTitle')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right" />
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.address')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.canEditOrder ? (
                <InputField
                  disableIcon
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  value={investorData?.address?.address}
                  errorMessage={inputOrderErrors?.investorData?.address?.address}
                  name="address"
                  onChange={(e, { name, value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      address: {
                        ...prevState.address,
                        [name]: value,
                      },
                    }))
                  }
                />
              ) : (
                order?.investorData?.address?.address
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.city')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.canEditOrder ? (
                <InputField
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  value={investorData?.address?.city}
                  errorMessage={inputOrderErrors?.investorData?.address?.city}
                  name="city"
                  disableIcon
                  onChange={(e, { name, value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      address: {
                        ...prevState.address,
                        [name]: value,
                      },
                    }))
                  }
                />
              ) : (
                order?.investorData?.address?.city
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.zip')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.canEditOrder ? (
                <InputField
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  value={investorData?.address?.zip}
                  errorMessage={inputOrderErrors?.investorData?.address?.zip}
                  name="zip"
                  disableIcon
                  onChange={(e, { name, value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      address: {
                        ...prevState.address,
                        [name]: value,
                      },
                    }))
                  }
                />
              ) : (
                order?.investorData?.address?.zip
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.country')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {inputOrderErrors?.investorData?.address?.country && (
                <Label color="red" className="mb-5">
                  {inputOrderErrors?.investorData?.address?.country}
                </Label>
              )}
              {order?.canEditOrder ? (
                <Dropdown
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  placeholder={t('editOrder.investorInfo.country')}
                  fluid
                  selection
                  options={countries?.map?.(country => ({
                    key: country.id,
                    text: country.name,
                    value: country.id,
                  }))}
                  value={investorData?.address?.country?.id}
                  onChange={(e, { value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      address: {
                        ...prevState.address,
                        country: {
                          id: value,
                        },
                      },
                    }))
                  }
                />
              ) : (
                order?.investorData?.address?.country?.name
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.state')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {inputOrderErrors?.investorData?.address?.state && (
                <Label color="red" className="mb-5">
                  {inputOrderErrors?.investorData?.address?.state}
                </Label>
              )}
              {order?.canEditOrder ? (
                <Dropdown
                  placeholder={t('editOrder.investorInfo.state')}
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  fluid
                  selection
                  options={countries
                    ?.find?.(country => country.id === investorData?.address?.country?.id)
                    ?.states?.map?.(state => ({
                      key: state.id,
                      text: state.name,
                      value: state.id,
                    }))}
                  value={investorData?.address?.state?.id}
                  onChange={(e, { value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      address: {
                        ...prevState.address,
                        state: {
                          id: value,
                        },
                      },
                    }))
                  }
                />
              ) : (
                order?.investorData?.address?.state?.name
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.bankAccountTitle')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right" />
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.bankName')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.canEditOrder ? (
                <InputField
                  value={investorData?.bankAccount?.bankName}
                  name="bankName"
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  errorMessage={inputOrderErrors?.investorData?.bankAccount?.bankName}
                  disableIcon
                  onChange={(e, { name, value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      bankAccount: {
                        ...prevState.bankAccount,
                        [name]: value,
                      },
                    }))
                  }
                />
              ) : (
                order?.investorData?.bankAccount?.bankName
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.accountNumber')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.canEditOrder ? (
                <InputField
                  value={investorData?.bankAccount?.accountNumber}
                  name="accountNumber"
                  disableIcon
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  errorMessage={inputOrderErrors?.investorData?.bankAccount?.accountNumber}
                  onChange={(e, { name, value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      bankAccount: {
                        ...prevState.bankAccount,
                        [name]: value,
                      },
                    }))
                  }
                />
              ) : (
                order?.investorData?.bankAccount?.accountNumber
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.investorInfo.country')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {inputOrderErrors?.investorData?.bankAccount?.country && (
                <Label color="red" className="mb-5">
                  {inputOrderErrors?.investorData?.bankAccount?.country}
                </Label>
              )}
              {order?.canEditOrder ? (
                <Dropdown
                  placeholder={t('editOrder.investorInfo.country')}
                  fluid
                  selection
                  disabled={!isUserPermitted(PERMISSIONS.EDIT_ORDER)}
                  options={countries?.map?.(country => ({
                    key: country.id,
                    text: country.name,
                    value: country.id,
                  }))}
                  value={investorData?.bankAccount?.country?.id}
                  onChange={(e, { value }) =>
                    editInvestorData(prevState => ({
                      ...prevState,
                      bankAccount: {
                        ...prevState.bankAccount,
                        country: {
                          id: value,
                        },
                      },
                    }))
                  }
                />
              ) : (
                order?.investorData?.bankAccount?.country?.name
              )}
            </Table.Cell>
          </Table.Row>
          {isUserPermitted(PERMISSIONS.EDIT_ORDER) ? (
            <Table.Row>
              <Table.Cell className={styles.tableLeft}>{t('actionButtons.saveTitle')}</Table.Cell>
              <Table.Cell className={styles.tableRight} textAlign="right">
                <Button
                  primary
                  onClick={() =>
                    actions.editOrderAction({
                      investorData,
                      orderId: order?.id,
                    })
                  }
                >
                  {t('actionButtons.save')}
                </Button>
              </Table.Cell>
            </Table.Row>
          ) : null}
        </Table.Body>
      </Table>
    );
  };

  const renderOfferData = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('editOrder.offerInfo.title')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.offerInfo.id')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {isUserPermitted(PERMISSIONS.GET_OFFER_BY_ID) ? (
                <a href={`${ROUTES.OFFERS}/${order?.offer?.id}/edit`} target="_blank" rel="noreferrer">
                  {order?.offer?.id}
                </a>
              ) : (
                <span>{order?.offer?.id}</span>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.offerInfo.offerTitle')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.offer?.title}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.offerInfo.slug')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              {order?.offer?.slug?.[i18next.language]}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.tableLeft}>{t('editOrder.offerInfo.image')}</Table.Cell>
            <Table.Cell className={styles.tableRight} textAlign="right">
              <img
                alt={String(order?.offer?.title)}
                style={{
                  maxWidth: '100%',
                }}
                src={order?.offer?.thumbUrl}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  const renderTransactionData = () => {
    if (isEmpty(order?.transactions)) {
      return null;
    }

    return (
      <Table className="mt-5">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('editOrder.transactionInfo.id')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">{t('editOrder.transactionInfo.status')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">{t('editOrder.transactionInfo.originalAmount')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">{t('editOrder.transactionInfo.amount')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">{t('editOrder.transactionInfo.commission')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">{t('editOrder.transactionInfo.commissionPercent')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">{t('editOrder.transactionInfo.date')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">{t('editOrder.transactionInfo.method')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {order?.transactions?.map?.(item => (
            <Table.Row>
              <Table.Cell>
                <Link to={`/transactions/${item.id}/edit`}>{t('view')}</Link>
              </Table.Cell>
              <Table.Cell>
                {item?.dateCompleted && (
                  <Tooltip color="green" name="check circle outline">
                    {t('editOrder.transactionInfo.dateCompleted')} {item?.dateCompleted}
                  </Tooltip>
                )}
                {item?.dateRejected && (
                  <Tooltip color="red" name="times circle outline">
                    {t('editOrder.transactionInfo.dateRejected')} {item?.dateRejected}
                  </Tooltip>
                )}
                {item?.waitUntil && (
                  <Tooltip color="yellow" name="dot circle outline">
                    {t('editOrder.transactionInfo.waitUntil')} {item?.waitUntil}
                  </Tooltip>
                )}

                {mapTransactionStatus({
                  status: item?.status,
                  t,
                })}
              </Table.Cell>
              <Table.Cell textAlign="center">{item?.originalAmount?.toFixed?.(2)} PLN</Table.Cell>
              <Table.Cell textAlign="center">{item?.amount?.toFixed?.(2)} PLN</Table.Cell>
              <Table.Cell textAlign="center">{item?.commission?.toFixed?.(2)} PLN</Table.Cell>
              <Table.Cell textAlign="center">
                {(Number(item?.commissionPercent ? item?.commissionPercent : 0) * 100)?.toFixed?.(2)} %
              </Table.Cell>
              <Table.Cell textAlign="center">{item?.date}</Table.Cell>
              <Table.Cell textAlign="right">{item?.method}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  const renderInvestorDocuments = () => {
    if (documentsStatus === FETCH_STATUS.LOADING) {
      return <Skeleton variant={SKELETON_VARIANT.TABLE} />;
    }

    if (documentsStatus === FETCH_STATUS.FAILURE) {
      return null;
    }

    const findSignedDocument = offerDocumentId => {
      const findedDocument = order?.signedDocuments?.find?.(document => document.offerDocumentId === offerDocumentId);
      return findedDocument ? (
        <a target="_blank" rel="noreferrer" href={String(findedDocument?.url)}>
          {findedDocument?.displayName}
        </a>
      ) : (
        <span>{t('editOrder.signableDocuments.notUploaded')}</span>
      );
    };

    const isAnySignedDocument = !isEmpty(order?.signedDocuments);

    const renderCreateAt = signedDocumentId => {
      const signedDoc = order?.signedDocuments?.find?.(document => document.offerDocumentId === signedDocumentId);
      return signedDoc?.createdAt ? <strong>{moment(signedDoc?.createdAt).format('DD.MM.YYYY HH:mm')}</strong> : '-';
    };

    return (
      <Table className="mt-8">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('editOrder.signableDocuments.document')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">{t('editOrder.signableDocuments.signedDocument')}</Table.HeaderCell>
            {isAnySignedDocument ? (
              <Table.HeaderCell textAlign="center">{t('editOrder.signableDocuments.signedAt')}</Table.HeaderCell>
            ) : null}
            <Table.HeaderCell textAlign="center">{t('editOrder.signableDocuments.signable')}</Table.HeaderCell>
            {isAnySignedDocument ? <Table.HeaderCell textAlign="center" /> : null}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {investorDocuments?.map?.(item => (
            <Table.Row key={item.id}>
              <Table.Cell>
                <a rel="noopener noreferrer" href={item.attachment.url} target="_blank">
                  {item.attachment.displayName}
                </a>
              </Table.Cell>
              <Table.Cell textAlign="center">{item?.signable ? findSignedDocument(item.id) : 'N / A'}</Table.Cell>
              {isAnySignedDocument ? <Table.Cell textAlign="center">{renderCreateAt(item.id)}</Table.Cell> : null}

              <Table.Cell textAlign="center">
                {item?.signable ? (
                  <Tooltip color="green" name="check circle outline" />
                ) : (
                  <Tooltip color="yellow" name="dot circle outline" />
                )}
              </Table.Cell>
              {isAnySignedDocument ? (
                <Table.Cell textAlign="right">
                  {item?.signable &&
                  order?.signedDocuments?.find?.(document => document.offerDocumentId === item.id) ? (
                    <Button
                      style={{
                        height: 25,
                        fontSize: 12,
                        lineHeight: 1,
                        minHeight: '100%',
                        padding: '0 8px',
                      }}
                      color="red"
                      onClick={() => {
                        // eslint-disable-next-line no-restricted-globals,no-alert
                        if (confirm(t('actionButtons.removeDocumentDisclaimer'))) {
                          actions.removeSignedDocumentAction({
                            documentId: order?.signedDocuments?.find?.(document => document.offerDocumentId === item.id)
                              .id,
                            orderId: params.id,
                          });
                        }
                      }}
                    >
                      {t('editOrder.signableDocuments.delete')}
                    </Button>
                  ) : null}
                </Table.Cell>
              ) : null}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Card className="admin-orders">
      <CardHeader>
        <CardTitle title={t('mainTitle')} />
        <CardToolbar>
          <div>
            <Button as={Link} type="button" to={ROUTES.ORDERS}>
              {t('actionButtons.goBack')}
            </Button>
            <Button color="black" disabled={orderStatus === FETCH_STATUS.LOADING} onClick={refreshPage}>
              {t('actionButtons.reload')}
            </Button>
          </div>
          <div>
            {order?.downloadSpreadSheetUrl && isUserPermitted(PERMISSIONS.DOWNLOAD_ORDER_SPREAD_SHEET) ? (
              <Button
                color="green"
                style={{
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
                onClick={() =>
                  actions.downloadSpreadsheetAction({
                    url: order?.downloadSpreadSheetUrl,
                    orderId: order?.id,
                  })
                }
              >
                {t('actionButtons.downloadSpreadsheet')}
              </Button>
            ) : (
              '-'
            )}

            {isUserPermitted(PERMISSIONS.ACCEPT_ORDER) ? (
              <Button
                color="green"
                disabled={!order?.canAcceptOrder || orderStatus === FETCH_STATUS.LOADING}
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals,no-alert
                  if (confirm(`${t('actionButtons.acceptDisclaimer')} ${params.id}?`)) {
                    actions.acceptOrderAction(params.id);
                  }
                }}
              >
                {t('actionButtons.toAccept')}
              </Button>
            ) : null}

            {isUserPermitted(PERMISSIONS.REJECT_ORDER) ? (
              <Button
                color="red"
                disabled={!order?.canRejectOrder || orderStatus === FETCH_STATUS.LOADING}
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals,no-alert
                  if (confirm(`${t('actionButtons.rejectDisclaimer')} ${params.id}?`)) {
                    actions.rejectOrderAction(params.id);
                  }
                }}
              >
                {t('actionButtons.toReject')}
              </Button>
            ) : null}

            {isUserPermitted(PERMISSIONS.EDIT_ORDER) ? (
              <Button
                primary
                disabled={orderStatus === FETCH_STATUS.LOADING}
                onClick={() =>
                  actions.editOrderAction({
                    investorData,
                    orderId: order?.id,
                  })
                }
              >
                {t('actionButtons.save')}
              </Button>
            ) : null}
          </div>
        </CardToolbar>
      </CardHeader>
      {orderError &&
        mapErrorCodes({
          errorCode: orderError,
          t,
        })}
      <CardBody transparent>
        {orderStatus === FETCH_STATUS.LOADING ? (
          <Skeleton variant={SKELETON_VARIANT.PREVIEW} />
        ) : (
          <>
            <Grid style={{ justifyContent: 'space-between' }} columns={2} stackable>
              <Grid columns={1} stackable>
                <Grid.Column>{renderOrderInfo()}</Grid.Column>
                <Grid.Column>{renderOfferData()}</Grid.Column>
              </Grid>

              <Grid style={{ marginTop: 0 }} columns={1} stackable>
                <Grid.Column>{renderInvestorAndUserData()}</Grid.Column>
              </Grid>
            </Grid>
            <div className="table-responsive">{renderTransactionData()}</div>
            <div className="table-responsive">{renderInvestorDocuments()}</div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({
    getSingleOrderAction: func,
    acceptOrderAction: func,
    rejectOrderAction: func,
  }),
  order: shape({}),
  orderStatus: string,
  orderError: string,
};

Main.defaultProps = {
  actions: {},
  order: {},
  orderStatus: FETCH_STATUS.IDLE,
  orderError: undefined,
};

export default Main;
