import React from 'react';
import { Label, Form, Input, Grid } from 'semantic-ui-react';

const languages = ['pl', 'en'];
const languageFlags = {
  en: <i className="us flag" />,
  pl: <i className="pl flag" />,
};

class TranslatableTextField extends React.PureComponent {
  render() {
    const { label, name, onChange, value, icon, iconPosition, errorMessage, disabled } = this.props;

    return (
      <>
        {languages.map(language => {
          return (
            <Grid.Column key={`${name}-${language}`}>
              <Form.Field>
                {errorMessage && (
                  <Label color="red" className="mb-1">
                    {errorMessage}
                  </Label>
                )}
                <label>
                  {languageFlags[language]}
                  {label}
                </label>
                <Input
                  disabled={disabled}
                  icon={icon}
                  iconPosition={iconPosition || 'left'}
                  name={name}
                  value={value?.[language]}
                  type="text"
                  onChange={(e, { name, value }) => onChange(e, { name, value, language })}
                />
              </Form.Field>
            </Grid.Column>
          );
        })}
      </>
    );
  }
}

TranslatableTextField.defaultProps = {
  label: '',
  name: '',
  onChange: () => {},
  value: '',
};

export default TranslatableTextField;
