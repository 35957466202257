import React from 'react';
import { Form, Grid, Label } from 'semantic-ui-react';
import ReactQuill from 'react-quill';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Tooltip } from '@shared/components';

const languages = ['pl', 'en'];
const languageFlags = {
  en: <i className="us flag" />,
  pl: <i className="pl flag" />,
};

class TranslatableRichTextField extends React.Component {
  render() {
    const { label, name, onChange, value, errorMessage, labelClassName, t, tooltip } = this.props;

    return (
      <>
        {languages.map(language => {
          return (
            <Grid.Column key={`${name}-${language}`}>
              <Form.Field>
                {errorMessage && (
                  <Label color="red" className={clsx('mb-5', labelClassName)}>
                    {t(errorMessage)}
                  </Label>
                )}
                <Form.Field className="d-flex justify-content-between">
                  <label>
                    {languageFlags[language]}
                    {label} {tooltip && <Tooltip>{tooltip}</Tooltip>}
                  </label>
                </Form.Field>
                <ReactQuill
                  theme="snow"
                  name={name}
                  value={value?.[language] ?? ' '}
                  onChange={value => onChange(0, { name, value, language })}
                />
              </Form.Field>
            </Grid.Column>
          );
        })}
      </>
    );
  }
}

TranslatableRichTextField.defaultProps = {
  label: '',
  name: '',
  onChange: () => {},
  value: '',
};

export default withTranslation(TRANSLATIONS.ERRORS)(TranslatableRichTextField);
