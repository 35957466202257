import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  tableLeft: {
    fontWeight: 700,
    width: 'max-content',
    display: 'flex',
    minWidth: '100%',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
  },
  offerImage: {
    maxWidth: '100% !important',
    borderRadius: 4,
  },
}));

export default useStyles;
