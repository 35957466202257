const SIZE_TYPES = {
  '25': { value: 3 },
  '50': { value: 6 },
  '75': { value: 9 },
  '100': { value: 12 },
};

const getSizeTypesDropdownOptions = () =>
  Object.keys(SIZE_TYPES).map(key => ({
    key,
    value: SIZE_TYPES[key].value,
    text: `${key}%`,
  }));

export { SIZE_TYPES, getSizeTypesDropdownOptions };
