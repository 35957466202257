import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getOffersAction, getSingleOfferAction } from '@store/actions/offers';
import { getCountriesAction } from '@store/actions/investors';
import { createOrderAction } from '@store/actions/orders';
import { getUsersAction } from '@store/actions/user-management';
import { getCreateOrderErrors } from '@store/reducers/orders/selectors';
import { getAllUsers } from '@store/reducers/user-management/selectors';
import { getOffersOptions, getOfferStatus, getOfferOptionsForInvesting } from '@store/reducers/offers/selectors';
import { getStates, getCountriesOptions, getCountriesStatus } from '@store/reducers/investors/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  countries: getCountriesOptions(state),
  countriesStatus: getCountriesStatus(state),
  offersOptions: getOffersOptions(state),
  offerStatus: getOfferStatus(state),
  states: getStates(state),
  offerOptionsForInvesting: getOfferOptionsForInvesting(state),
  userEmail: getAllUsers(state),
  createOrderErrors: getCreateOrderErrors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCountriesAction,
      getOffersAction,
      getSingleOfferAction,
      getUsersAction,
      createOrderAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
