import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { TranslatableTextField } from '@shared/components/translatable';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const Text = ({ elementData, setElementData, isLoading, label, isViewMode }) => {
  const [t] = useTranslation(TRANSLATIONS.STATIC_CONTENT);

  const handleTextFieldChange = (e, { value, language }) => {
    setElementData(prevState => ({
      ...prevState,
      content: {
        ...prevState?.content,
        [language]: value,
      },
    }));
  };

  return (
    <Segment>
      <Form loading={isLoading}>
        <Form.Group>
          <Grid container stackable columns={1}>
            <TranslatableTextField
              disabled={isViewMode}
              label={t(label)}
              onChange={handleTextFieldChange}
              value={elementData?.content}
              icon="font"
            />
          </Grid>
        </Form.Group>
      </Form>
    </Segment>
  );
};

Text.propTypes = {
  elementData: shape({}),
  setElementData: func,
  isLoading: bool,
  isViewMode: bool,
  label: string,
};

Text.defaultProps = {
  elementData: {},
  setElementData: () => {},
  isLoading: false,
  isViewMode: false,
  label: '',
};

export default Text;
