import {
  REJECT_ORDER_INIT,
  REJECT_ORDER_SUCCESS,
  REJECT_ORDER_FAILURE,
  GET_SINGLE_ORDER_SUCCESS,
} from '@store/action-types/orders';
import client from '@api-sdk/client';

const rejectOrderInit = () => ({ type: REJECT_ORDER_INIT });
const rejectOrderSuccess = order => ({ type: REJECT_ORDER_SUCCESS, payload: { order } });
const rejectOrderFailure = error => ({ type: REJECT_ORDER_FAILURE, payload: { error } });

const getSingleOrderSuccess = order => ({ type: GET_SINGLE_ORDER_SUCCESS, payload: { order } });

const rejectOrder = orderId => async dispatch => {
  dispatch(rejectOrderInit());

  try {
    const rejectOrderResponse = await client.rejectOrder({ slugs: [orderId, 'reject'] });

    await dispatch(rejectOrderSuccess(rejectOrderResponse.response));
    await dispatch(getSingleOrderSuccess(rejectOrderResponse.response));
  } catch (error) {
    dispatch(rejectOrderFailure(error));
  }
};

export default rejectOrder;
