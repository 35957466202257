const GET_OFFERS_INIT = '@offers/GET_OFFERS_INIT';
const GET_OFFERS_SUCCESS = '@offers/GET_OFFERS_SUCCESS';
const GET_OFFERS_FAILURE = '@offers/GET_OFFERS_FAILURE';

const GET_SINGLE_OFFER_INIT = '@offers/GET_SINGLE_OFFER_INIT';
const GET_SINGLE_OFFER_SUCCESS = '@offers/GET_SINGLE_OFFER_SUCCESS';
const GET_SINGLE_OFFER_FAILURE = '@offers/GET_SINGLE_OFFER_FAILURE';

const EDIT_OFFER_INIT = '@offers/EDIT_OFFER_INIT';
const EDIT_OFFER_FAILURE = '@offers/EDIT_OFFER_FAILURE';
const EDIT_OFFER_SUCCESS = '@offers/EDIT_OFFER_SUCCESS';

const CREATE_OFFER_INIT = '@offers/CREATE_OFFER_INIT';
const CREATE_OFFER_SUCCESS = '@offers/CREATE_OFFER_SUCCESS';
const CREATE_OFFER_FAILURE = '@offers/CREATE_OFFER_FAILURE';

const DELETE_OFFER_INIT = '@offers/DELETE_OFFER_INIT';
const DELETE_OFFER_SUCCESS = '@offers/DELETE_OFFER_SUCCESS';
const DELETE_OFFER_FAILURE = '@offers/DELETE_OFFER_FAILURE';

const GET_OFFER_DOCUMENTS_INIT = '@offers/GET_OFFER_DOCUMENTS_INIT';
const GET_OFFER_DOCUMENTS_SUCCESS = '@offers/GET_OFFER_DOCUMENTS_SUCCESS';
const GET_OFFER_DOCUMENTS_FAILURE = '@offers/GET_OFFER_DOCUMENTS_FAILURE';

export {
  GET_OFFERS_FAILURE,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_INIT,
  GET_SINGLE_OFFER_INIT,
  GET_SINGLE_OFFER_SUCCESS,
  GET_SINGLE_OFFER_FAILURE,
  EDIT_OFFER_INIT,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_FAILURE,
  CREATE_OFFER_INIT,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  DELETE_OFFER_INIT,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_FAILURE,
  GET_OFFER_DOCUMENTS_INIT,
  GET_OFFER_DOCUMENTS_SUCCESS,
  GET_OFFER_DOCUMENTS_FAILURE,
};
