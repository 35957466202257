const GalleryItemModel = {
  position: 1,
  title: { en: '', pl: '' },
  description: { en: '', pl: '' },
  file: {
    base64: '',
    fileName: '',
    delete: false,
  },
};

export default GalleryItemModel;
