import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import { Button, Divider, Form, Grid, Segment, Select } from 'semantic-ui-react';
import { SwitchField, TranslatableTextField, TranslatableRichTextField } from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { isEmpty } from 'lodash';
import { Container } from '@material-ui/core';
import useStyles from './styles';

const AgreementsForm = ({
  isCreateMode,
  isLoading,
  onRefreshAgreement,
  onSaveAgreement,
  onCreateAgreement,
  agreementErrors,
  agreement,
  types,
  targets,
  isViewMode,
}) => {
  const [t] = useTranslation(TRANSLATIONS.AGREEMENTS);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);
  const [disableButtons, setDisableButtons] = useState(false);
  const params = useParams();
  const [agreementData, setAgreementData] = useState(agreement);
  const styles = useStyles();

  useEffect(() => {
    (isEmpty(agreementData) || !agreement?.name) && setAgreementData(agreement);
  }, [agreement]);

  const handleRefresh = async () => {
    await setAgreementData({});
    await onRefreshAgreement(params.id);
  };

  const onSwitchChange = (e, { name }) => {
    setAgreementData(prevState => ({
      ...prevState,
      [name]: !prevState?.[name],
    }));
  };

  const onChangeTranslatableContent = (e, { name, value, language }) => {
    setAgreementData(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));
  };

  const handleSelect = (e, { name, value }) => {
    setAgreementData({
      ...agreementData,
      [name]: value,
    });
  };

  const disableSubmitButton = () => isEmpty(agreementData?.target) || isEmpty(agreementData?.type);

  const renderMenuButtons = () => (
    <Grid columns={2}>
      <Grid.Column>
        <h2>{isCreateMode ? t('addAgreement') : t('editAgreement')}</h2>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button style={{ marginRight: 5 }} as={Link} type="button" to={ROUTES.AGREEMENTS}>
          {t('actionButtons.goBack')}
        </Button>
        {!isCreateMode && (
          <Button type="submit" disabled={isLoading} secondary onClick={handleRefresh} style={{ marginRight: '5px' }}>
            {t('actionButtons.reload')}
          </Button>
        )}

        {!isCreateMode && (
          <Button
            variant="outlined"
            primary
            style={{ marginRight: 5 }}
            disabled={disableSubmitButton() || isViewMode || disableButtons}
            onClick={async () => {
              await setDisableButtons(true);
              await onSaveAgreement({ agreementData });
              await setDisableButtons(false);
            }}
          >
            {t('actionButtons.save')}
          </Button>
        )}

        {!isCreateMode && (
          <Button
            variant="outlined"
            color="facebook"
            disabled={disableSubmitButton() || isViewMode || disableButtons}
            onClick={async () => {
              await setDisableButtons(true);
              await onSaveAgreement({ agreementData, saveAndBack: true });
              await setDisableButtons(false);
            }}
          >
            {t('actionButtons.saveAndBack')}
          </Button>
        )}

        {isCreateMode && (
          <Button
            variant="outlined"
            primary
            style={{ marginRight: 5 }}
            disabled={disableSubmitButton() || isViewMode || disableButtons}
            onClick={async () => {
              await setDisableButtons(true);
              await onCreateAgreement({ agreementData });
              await setDisableButtons(false);
            }}
          >
            {t('actionButtons.add')}
          </Button>
        )}

        {isCreateMode && (
          <Button
            variant="outlined"
            color="facebook"
            disabled={disableSubmitButton() || isViewMode || disableButtons}
            onClick={async () => {
              await setDisableButtons(true);
              await onCreateAgreement({ agreementData, saveAndBack: true });
              await setDisableButtons(false);
            }}
          >
            {t('actionButtons.addAndBack')}
          </Button>
        )}
      </Grid.Column>
    </Grid>
  );

  return (
    <Container>
      {renderMenuButtons()}
      <Segment className={styles.segment}>
        <Form loading={isLoading}>
          <Form.Group>
            <Grid container stackable columns={3} divided>
              <Grid.Column>
                <Form.Field>
                  <label htmlFor="agreement-type-select">{t('type')}</label>
                  <Select
                    disabled={isViewMode}
                    id="agreement-type-select"
                    value={agreementData?.type || ''}
                    onChange={handleSelect}
                    options={types}
                    name="type"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label htmlFor="agreement-target-select">{t('target')}</label>
                  <Select
                    disabled={isViewMode}
                    options={targets}
                    id="agreement-target-select"
                    value={agreementData?.target || ''}
                    onChange={handleSelect}
                    name="target"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column className="d-flex justify-content-around align-items-center">
                <SwitchField
                  disabled={isViewMode}
                  className="text-center"
                  onChange={onSwitchChange}
                  name="active"
                  value={agreementData?.active}
                  label={t('active')}
                />
                <SwitchField
                  disabled={isViewMode}
                  onChange={onSwitchChange}
                  className="text-center"
                  name="required"
                  value={agreementData?.required}
                  label={t('required')}
                />
              </Grid.Column>
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="name"
                label={t('name')}
                value={agreementData?.name}
                errorMessage={error(agreementErrors?.name)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableRichTextField
                disabled={isViewMode}
                tooltip={t('tooltip.content')}
                label={t('content')}
                name="content"
                errorMessage={error(agreementErrors?.content)}
                value={agreementData?.content}
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      {renderMenuButtons()}
    </Container>
  );
};

AgreementsForm.propTypes = {
  isCreateMode: bool,
  isLoading: bool,
  onRefreshAgreement: func,
  onSaveAgreement: func,
  onCreateAgreement: func,
  agreementErrors: shape({}),
  agreement: shape({}),
  types: arrayOf(shape({})),
  targets: arrayOf(shape({})),
  isViewMode: bool,
};

AgreementsForm.defaultProps = {
  isCreateMode: false,
  isLoading: false,
  onRefreshAgreement: () => {},
  onSaveAgreement: () => {},
  onCreateAgreement: () => {},
  agreementErrors: {},
  agreement: {},
  types: [],
  targets: [],
  isViewMode: false,
};

export default AgreementsForm;
