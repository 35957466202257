import React, { useEffect, useMemo } from 'react';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { arrayOf, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Button } from 'semantic-ui-react';
import ROUTES from '@shared/consts/routes';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, NotFoundMessage, Table } from '@shared/components';
import './styles.scss';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';

const Main = ({
  allPages,
  pageStatus,
  actions,
  queryParams,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.PAGES);
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMountWithTranslate(() => {
    actions.getPagesAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.PAGES]) {
      if (
        !isUserPermitted(PERMISSIONS.PAGE_CREATE) ||
        !isUserPermitted(PERMISSIONS.PAGE_DELETE) ||
        !isUserPermitted(PERMISSIONS.PAGE_EDIT) ||
        !isUserPermitted(PERMISSIONS.GET_PAGE_BY_ID)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.PAGES);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.PAGE_CREATE) && PERMISSIONS.PAGE_CREATE,
          !isUserPermitted(PERMISSIONS.PAGE_DELETE) && PERMISSIONS.PAGE_DELETE,
          !isUserPermitted(PERMISSIONS.PAGE_EDIT) && PERMISSIONS.PAGE_EDIT,
          !isUserPermitted(PERMISSIONS.GET_PAGE_BY_ID) && PERMISSIONS.GET_PAGE_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  const refreshPage = () => actions.getPagesAction();

  const columns = useMemo(
    () => [
      {
        Header: t('title'),
        accessor: 'title',
        columnName: 'title',
        columnOrder: 'title',
      },
      {
        Header: t('slug'),
        accessor: `slug`,
        columnName: 'slug',
        columnOrder: 'slug',
        Cell: ({ row }) => `${row?.original?.slug?.[i18next.language]}`,
      },
      {
        Header: t('active'),
        accessor: 'active',
        columnOrder: 'active',
        columnName: 'active',
        className: 'text-center',
        Cell: ({ row }) => {
          const isActive = row.original.active;
          return isActive ? t('yes') : t('no');
        },
      },
      {
        accessor: 'action',
        className: 'text-right',
        Cell: ({ row }) => {
          return (
            <>
              {isUserPermitted(PERMISSIONS.GET_PAGE_BY_ID) ? (
                <Button as={Link} to={`/pages/${row.original.id}`}>
                  {t('edit')}
                </Button>
              ) : null}

              {isUserPermitted(PERMISSIONS.PAGE_DELETE) ? (
                <Button
                  color="red"
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals,no-alert
                    if (confirm(`Are you sure you want to delete page ${row.original.title}?`)) {
                      actions.deletePageAction(row.original.id);
                    }
                  }}
                >
                  {t('remove')}
                </Button>
              ) : null}
            </>
          );
        },
      },
    ],
    [actions, t],
  );

  const renderContent = () => (
    <Table
      className="table-responsive"
      isLoading={pageStatus === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
      data={allPages}
      columns={columns}
      queryParams={queryParams}
      searchAction={actions.getPagesAction}
      reloadAction={refreshPage}
      notFoundMessage={
        <NotFoundMessage
          title={t('notFoundPages')}
          subtitle={t('clickBelow')}
          buttonText={t('create')}
          to={ROUTES.PAGE_CREATE}
        />
      }
    />
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle title={t('pageTitle')} />
        <CardToolbar>
          <Button color="black" disabled={pageStatus === FETCH_STATUS.LOADING} onClick={refreshPage}>
            {t('reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.PAGE_CREATE) ? (
            <Button primary as={Link} to={ROUTES.PAGE_CREATE}>
              {t('create')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>{renderContent()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({
    getPagesAction: func,
  }),
  pageStatus: string,
  allPages: arrayOf(shape({})),
  row: shape({}),
  queryParams: shape({}),
  permittedPermissions: arrayOf(shape({})),
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};

Main.defaultProps = {
  actions: {},
  pageStatus: '',
  allPages: [],
  row: {},
  queryParams: {},
  permittedPermissions: [],
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
