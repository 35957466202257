import {
  REMOVE_SIGNED_DOCUMENT_INIT,
  REMOVE_SIGNED_DOCUMENT_SUCCESS,
  REMOVE_SIGNED_DOCUMENT_FAILURE,
} from '@store/action-types/orders';
import client from '@api-sdk/client';
import getSingleOrderAction from '../getSingleOrder';

const removeSignedDocumentInit = () => ({ type: REMOVE_SIGNED_DOCUMENT_INIT });
const removeSignedDocumentSuccess = order => ({ type: REMOVE_SIGNED_DOCUMENT_SUCCESS, payload: { order } });
const removeSignedDocumentFailure = error => ({ type: REMOVE_SIGNED_DOCUMENT_FAILURE, payload: { error } });

const removeSignedDocument = ({ documentId, orderId }) => async dispatch => {
  dispatch(removeSignedDocumentInit());

  try {
    const removeSignedDocumentResponse = await client.removeSignedDocument({ slugs: [documentId] });
    await dispatch(removeSignedDocumentSuccess(removeSignedDocumentResponse.response));

    await dispatch(getSingleOrderAction(orderId));
  } catch (error) {
    dispatch(removeSignedDocumentFailure(error));
  }
};

export default removeSignedDocument;
