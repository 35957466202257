import React, { useState } from 'react';
import { Button, Flag, Form, Icon, Select, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { SwitchField, TranslatableRichTextField, TranslatableTextField } from '@shared/components/translatable';

const Agreement = ({
  agreement,
  agreementTypes,
  agreementTargets,
  onChange,
  offerErrors,
  agreementPositions,
  isViewMode,
}) => {
  const [isEditingState, setEditingState] = useState(agreement?.initWithEditor || false);
  const [t] = useTranslation(TRANSLATIONS.AGREEMENTS);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);

  const onSwitchChange = (e, { name }) => {
    onChange(prevState => ({
      ...prevState,
      offerAgreements: prevState?.offerAgreements?.reduce?.((acc, item) => {
        if (item.backendIndex === agreement.backendIndex) {
          return acc.concat({ ...item, [name]: !item?.[name] });
        }
        return acc.concat([item]);
      }, []),
    }));
  };

  const onChangeValue = (e, { name, value, language }) => {
    onChange(prevState => ({
      ...prevState,
      offerAgreements: prevState?.offerAgreements?.reduce?.((acc, item) => {
        if (item.backendIndex === agreement.backendIndex) {
          return language
            ? acc.concat({ ...item, [name]: { ...item?.[name], [language]: value } })
            : acc.concat({ ...item, [name]: value });
        }
        return acc.concat([item]);
      }, []),
    }));
  };

  const onChangePositionValue = (e, { value }) => {
    onChange(prevState => ({
      ...prevState,
      offerAgreements: prevState?.offerAgreements?.reduce?.((acc, item) => {
        if (item.position === value) {
          return acc.concat({ ...item, position: agreement?.position, initWithEditor: true });
        }
        if (item.backendIndex === agreement.backendIndex) {
          return acc.concat({ ...item, position: value, initWithEditor: true });
        }

        return acc.concat([item]);
      }, []),
    }));
  };

  const renderName = () =>
    isEditingState ? (
      <>
        <Table.Cell colSpan={2} style={{ margin: 10 }}>
          <TranslatableTextField
            name="name"
            label={t('name')}
            value={agreement?.name}
            errorMessage={error(offerErrors?.name)}
            icon="font"
            onChange={onChangeValue}
          />
        </Table.Cell>
      </>
    ) : (
      <>
        <Table.Cell>
          <Flag name="pl" />
          {agreement?.name?.pl}
        </Table.Cell>
        <Table.Cell>
          <Flag name="us" />
          {agreement?.name?.en}
        </Table.Cell>
      </>
    );

  const renderContent = () =>
    isEditingState ? (
      <Table.Cell colSpan={2} style={{ margin: 10 }}>
        <TranslatableRichTextField
          tooltip={t('tooltip.content')}
          label={t('content')}
          name="content"
          errorMessage={error(offerErrors?.content)}
          value={agreement?.content}
          onChange={onChangeValue}
        />
      </Table.Cell>
    ) : (
      <>
        <Table.Cell>
          <Flag name="pl" />
          <span dangerouslySetInnerHTML={{ __html: agreement?.content?.pl }} />
        </Table.Cell>
        <Table.Cell>
          <Flag name="us" />
          <span dangerouslySetInnerHTML={{ __html: agreement?.content?.en }} />
        </Table.Cell>
      </>
    );

  const renderIsActive = () =>
    isEditingState ? (
      <Table.Cell colSpan={2} style={{ margin: 10 }}>
        <SwitchField
          className="text-center"
          onChange={onSwitchChange}
          name="active"
          value={agreement?.active}
          label={t('active')}
        />
      </Table.Cell>
    ) : (
      <>
        <Table.Cell colSpan={2}>
          {agreement.active ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="checkmark" color="green" /> {t('agreements.activeBadge')}
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="close" color="red" /> {t('agreements.notActiveBadge')}
            </div>
          )}
        </Table.Cell>
      </>
    );

  const renderIsRequired = () =>
    isEditingState ? (
      <Table.Cell colSpan={2} style={{ margin: 10 }}>
        <SwitchField
          onChange={onSwitchChange}
          className="text-center"
          name="required"
          value={agreement?.required}
          label={t('required')}
        />
      </Table.Cell>
    ) : (
      <>
        <Table.Cell colSpan={2}>
          {agreement.required ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="checkmark" color="green" /> {t('agreements.isRequiredBadge')}
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="close" color="red" /> {t('agreements.isNotRequiredBadge')}
            </div>
          )}
        </Table.Cell>
      </>
    );

  const renderType = () =>
    isEditingState ? (
      <>
        <Table.Cell colSpan={2} style={{ margin: 10 }}>
          <Form.Field>
            <label htmlFor="agreement-type-select">{t('type')}</label>
            <Select
              id="agreement-type-select"
              value={agreement?.type || ''}
              onChange={onChangeValue}
              options={agreementTypes}
              name="type"
            />
          </Form.Field>
        </Table.Cell>
      </>
    ) : (
      <>
        <Table.Cell colSpan={2}>{agreement.type}</Table.Cell>
      </>
    );

  const renderPosition = () =>
    isEditingState ? (
      <Table.Cell colSpan={2} style={{ margin: 10 }}>
        <Form.Field>
          <Select
            id="position"
            value={agreement?.position || ''}
            onChange={onChangePositionValue}
            options={agreementPositions}
            name="position"
          />
        </Form.Field>
      </Table.Cell>
    ) : (
      <>
        <Table.Cell colSpan={2}>{agreement.position}</Table.Cell>
      </>
    );

  const onRemoveAgreement = async () => {
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm(`Are you sure you wanna delete this agreement?`)) {
      onChange(prevState => ({
        ...prevState,
        offerAgreements: prevState?.offerAgreements?.reduce?.((acc, item) => {
          if (item.id !== agreement.id) {
            return acc.concat([item]);
          }
          return acc.concat({ ...item, toBeDeleted: true });
        }, []),
      }));
    }
  };

  const onUndoRemoveAgreement = () => {
    onChange(prevState => ({
      ...prevState,
      offerAgreements: prevState?.offerAgreements?.reduce?.((acc, item) => {
        if (item.id !== agreement.id) {
          return acc.concat([item]);
        }
        return acc.concat({ ...item, toBeDeleted: false });
      }, []),
    }));
  };

  return (
    <Table celled striped color="black">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2">
            {agreement.toBeDeleted ? (
              <span style={{ textDecoration: 'line-through' }}>
                {agreement.position}. {t('agreements.agreement')}
              </span>
            ) : (
              <span>
                {agreement.position}. {t('agreements.agreement')}
              </span>
            )}
          </Table.HeaderCell>
          {isViewMode ? (
            <Table.HeaderCell className="text-right" />
          ) : (
            <Table.HeaderCell className="text-right">
              {isEditingState ? (
                <Button
                  primary
                  onClick={() => {
                    setEditingState(false);
                  }}
                >
                  {t('agreements.previewAgreement')}
                </Button>
              ) : (
                <Button
                  primary
                  onClick={() => {
                    setEditingState(true);
                  }}
                >
                  {t('agreements.editAgreement')}
                </Button>
              )}
              {!agreement.toBeDeleted ? (
                <Button icon color="red" onClick={onRemoveAgreement}>
                  <Icon name="trash" />
                  {t('agreements.removeAgreement')}
                </Button>
              ) : (
                <Button icon color="yellow" onClick={onUndoRemoveAgreement}>
                  <Icon name="undo" />
                  {t('agreements.undoRemoval')}
                </Button>
              )}
            </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <b>{t('agreements.name')}</b>
          </Table.Cell>
          {renderName()}
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>{t('agreements.position')}</b>
          </Table.Cell>
          {renderPosition()}
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>{t('agreements.content')}</b>
          </Table.Cell>
          {renderContent()}
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>{t('agreements.isActive')}</b>
          </Table.Cell>
          {renderIsActive()}
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>{t('agreements.type')}</b>
          </Table.Cell>
          {renderType()}
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>{t('agreements.isRequired')}</b>
          </Table.Cell>
          {renderIsRequired()}
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default Agreement;
