import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { arrayOf, func, shape, string } from 'prop-types';
import { Message, Button, Popup, Icon } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, Table } from '@shared/components';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { mapErrorCodes } from '@screens/transactions/utils';
import mapTransactionStatus from '@shared/utils/mapTransactionStatus';
import './styles.scss';
import { Typography } from '@material-ui/core';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';
import ROUTES from '@shared/consts/routes';

const Main = ({
  actions,
  allOrders,
  allOrdersError,
  allOrdersStatus,
  queryParams,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.ORDERS);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const refreshPage = () => actions.getOrdersAction();

  useMountWithTranslate(() => {
    actions.getOrdersAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.ORDERS]) {
      if (
        !isUserPermitted(PERMISSIONS.EDIT_ORDER) ||
        !isUserPermitted(PERMISSIONS.ACCEPT_ORDER) ||
        !isUserPermitted(PERMISSIONS.REJECT_ORDER) ||
        !isUserPermitted(PERMISSIONS.DOWNLOAD_ORDER_SPREAD_SHEET) ||
        !isUserPermitted(PERMISSIONS.MANUAL_ORDER)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.ORDERS);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.EDIT_ORDER) && PERMISSIONS.EDIT_ORDER,
          !isUserPermitted(PERMISSIONS.ACCEPT_ORDER) && PERMISSIONS.ACCEPT_ORDER,
          !isUserPermitted(PERMISSIONS.REJECT_ORDER) && PERMISSIONS.REJECT_ORDER,
          !isUserPermitted(PERMISSIONS.DOWNLOAD_ORDER_SPREAD_SHEET) && PERMISSIONS.DOWNLOAD_ORDER_SPREAD_SHEET,
          !isUserPermitted(PERMISSIONS.MANUAL_ORDER) && PERMISSIONS.MANUAL_ORDER,
        ]);
      }
    }
  }, [permittedPermissions]);

  const columns = useMemo(
    () => [
      {
        Header: t('list.id'),
        accessor: 'id',
        columnName: 'id',
      },
      {
        Header: t('list.user'),
        accessor: 'userMeta',
        columnName: 'userEmail',
        columnOrder: 'userEmail',
        Cell: ({ row }) => {
          const Username =
            row?.original?.userMeta?.isDeleted || !isUserPermitted(PERMISSIONS.GET_USER_BY_ID) ? Typography : Link;

          return (
            <div className="d-flex align-items-center">
              <Username to={`/users/${row?.original?.userMeta?.id}`} className="mr-1">
                {`${row?.original?.userMeta?.firstName} ${row?.original?.userMeta?.lastName} (${row?.original?.userMeta?.email})`}
              </Username>
              {row?.original?.userMeta?.isDeleted && (
                <Popup basic content="User deleted" trigger={<Icon name="warning sign" color="yellow" />} />
              )}
            </div>
          );
        },
      },
      {
        Header: t('list.offer'),
        accessor: 'offer',
        columnName: 'offer',
        Cell: ({ row }) => {
          return (
            <Button
              as={Link}
              disabled={!isUserPermitted(PERMISSIONS.GET_OFFER_BY_ID)}
              target="_blank"
              to={`/offers/${row?.original?.id}/edit`}
            >
              {row?.original?.offer?.title}
            </Button>
          );
        },
      },
      {
        Header: t('list.date'),
        accessor: 'date',
        columnName: 'date',
        columnOrder: 'date',
      },
      {
        Header: t('list.quantity'),
        accessor: 'quantity',
        columnOrder: 'quantity',
      },
      {
        Header: t('list.status'),
        accessor: 'status',
        columnName: 'status',
        Cell: ({ row }) => {
          return <div>{mapTransactionStatus({ status: row?.original?.status, t })}</div>;
        },
      },
      {
        Header: t('list.totalAmount'),
        accessor: 'totalAmount',
        columnOrder: 'amount',
      },
      {
        Header: t('list.downloadSpreadsheet'),
        className: 'text-center',
        accessor: 'downloadSpreadSheetUrl',
        Cell: ({ row }) => {
          return row?.original?.downloadSpreadSheetUrl ? (
            <Button
              color="green"
              onClick={() =>
                actions.downloadSpreadsheetAction({
                  url: row?.original?.downloadSpreadSheetUrl,
                  orderId: row?.original?.id,
                })
              }
            >
              {t('actionButtons.downloadSpreadsheet')}
            </Button>
          ) : (
            '-'
          );
        },
      },
      {
        Header: t('list.edit'),
        className: 'text-center',
        Cell: ({ row }) => {
          return isUserPermitted(PERMISSIONS.EDIT_ORDER) ? (
            <Button primary as={Link} to={`/orders/${row.original?.id}/edit`}>
              {t('actionButtons.edit')}
            </Button>
          ) : null;
        },
      },
    ],
    [t, permittedPermissions],
  );

  const renderContent = () => (
    <Table
      className="table-responsive"
      isLoading={allOrdersStatus === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
      data={allOrders}
      columns={columns}
      queryParams={queryParams}
      searchAction={actions.getOrdersAction}
      reloadAction={refreshPage}
      notFoundMessage={<Message>{t('notFoundOrders')}</Message>}
    />
  );

  return (
    <Card width={false} className="admin-orders">
      <CardHeader>
        <CardTitle title={t('mainTitle')} />
        <CardToolbar className="flex-row">
          <Button color="black" disabled={allOrdersStatus === FETCH_STATUS.LOADING} onClick={refreshPage}>
            {t('actionButtons.reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.MANUAL_ORDER) ? (
            <Button primary as={Link} to={ROUTES.CREATE_ORDER}>
              {t('actionButtons.create')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      {allOrdersError && mapErrorCodes({ errorCode: allOrdersError, t })}
      <CardBody transparent>{renderContent()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({
    getOffersAction: func,
  }),
  allOffers: arrayOf(shape({})),
  offerStatus: string,
  row: shape({}),
};
Main.defaultProps = {
  actions: {},
  allOffers: [],
  offerStatus: FETCH_STATUS.IDLE,
  row: {},
};

export default Main;
