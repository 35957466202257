import {
  GET_OFFERS_INIT,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  GET_SINGLE_OFFER_INIT,
  GET_SINGLE_OFFER_SUCCESS,
  GET_SINGLE_OFFER_FAILURE,
  EDIT_OFFER_INIT,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_FAILURE,
  CREATE_OFFER_INIT,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  GET_OFFER_DOCUMENTS_INIT,
  GET_OFFER_DOCUMENTS_SUCCESS,
  GET_OFFER_DOCUMENTS_FAILURE,
} from '@store/action-types/offers';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allOffers: [],
  singleOffer: {},
  status: FETCH_STATUS.IDLE,
  error: undefined,
  queryParams: {},
  documentsStatus: FETCH_STATUS.IDLE,
  investorDocuments: [],
};

const offersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_OFFERS_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        queryParams: {},
      };
    }
    case GET_OFFERS_SUCCESS: {
      return {
        allOffers: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          configuredOrder: payload?.configuredOrder,
          appliedOrder: payload?.appliedOrder,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
          order: payload?.order,
        },
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_OFFERS_FAILURE: {
      return {
        allOffers: [],
        error: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case GET_SINGLE_OFFER_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
      };
    }
    case GET_SINGLE_OFFER_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        singleOffer: payload.singleOffer.response,
        error: undefined,
      };
    }
    case GET_SINGLE_OFFER_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        singleOffer: {},
        error: payload.error,
      };
    }
    case EDIT_OFFER_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
      };
    }
    case EDIT_OFFER_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case EDIT_OFFER_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case CREATE_OFFER_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
      };
    }
    case CREATE_OFFER_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case CREATE_OFFER_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }

    case GET_OFFER_DOCUMENTS_INIT: {
      return {
        ...state,
        documentsStatus: FETCH_STATUS.LOADING,
        investorDocuments: [],
        error: undefined,
      };
    }
    case GET_OFFER_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documentsStatus: FETCH_STATUS.SUCCESS,
        investorDocuments: payload?.documents?.investorDocuments,
        error: undefined,
      };
    }
    case GET_OFFER_DOCUMENTS_FAILURE: {
      return {
        ...state,
        documentsStatus: FETCH_STATUS.FAILURE,
        investorDocuments: payload?.error,
        error: undefined,
      };
    }
    default:
      return state;
  }
};

export default offersReducer;
