import { GET_SINGLE_ORDER_INIT, GET_SINGLE_ORDER_SUCCESS, GET_SINGLE_ORDER_FAILURE } from '@store/action-types/orders';
import client from '@api-sdk/client';

const getSingleOrderInit = () => ({ type: GET_SINGLE_ORDER_INIT });
const getSingleOrderSuccess = order => ({ type: GET_SINGLE_ORDER_SUCCESS, payload: { order } });
const getSingleOrderFailure = error => ({ type: GET_SINGLE_ORDER_FAILURE, payload: { error } });

const getSingleOrder = orderId => async dispatch => {
  dispatch(getSingleOrderInit());

  try {
    const singleOrderResponse = await client.getSingleOrder({ slug: orderId });

    dispatch(getSingleOrderSuccess(singleOrderResponse.response));
  } catch (error) {
    dispatch(getSingleOrderFailure(error));
  }
};

export default getSingleOrder;
