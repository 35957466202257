import {
  GET_SINGLE_TRANSACTION_INIT,
  GET_SINGLE_TRANSACTION_SUCCESS,
  GET_SINGLE_TRANSACTION_FAILURE,
} from '@store/action-types/transactions';
import client from '@api-sdk/client';

const getSingleTransactionInit = () => ({ type: GET_SINGLE_TRANSACTION_INIT });
const getSingleTransactionSuccess = transaction => ({ type: GET_SINGLE_TRANSACTION_SUCCESS, payload: { transaction } });
const getSingleTransactionFailure = error => ({ type: GET_SINGLE_TRANSACTION_FAILURE, payload: { error } });

const getSingleTransaction = transactionId => async dispatch => {
  dispatch(getSingleTransactionInit());

  try {
    const singleItem = await client.getSingleTransaction({ slug: transactionId });
    dispatch(getSingleTransactionSuccess(singleItem.response));
  } catch (error) {
    dispatch(getSingleTransactionFailure(error?.errors?.[0]?.code));
  }
};

export default getSingleTransaction;
