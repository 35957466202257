import React from 'react';
import { Form, Button, Icon, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { OfferSection } from './components';
import OfferSectionElementModel from '../models/OfferSectionElementModel';

class OfferSections extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onAddSection = this.onAddSection.bind(this);
  }

  onAddSection() {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerSections:
        prevState?.offerSections?.length > 0
          ? prevState.offerSections.concat([
              {
                position: prevState?.offerSections?.[prevState?.offerSections?.length - 1]?.position + 1,
                backendIndex: prevState?.offerSections?.[prevState?.offerSections?.length - 1]?.backendIndex + 1,
                elements: [OfferSectionElementModel],
              },
            ])
          : prevState.offerSections.concat({
              position: 1,
              backendIndex: 0,
              elements: [OfferSectionElementModel],
            }),
    }));
  }

  render() {
    const { offerSections, onChange, offerErrors, t, isViewMode } = this.props;
    const dropdownPositions = offerSections?.map?.(section => ({
      key: section.position,
      value: section.position,
      text: section.position,
    }));

    return (
      <Form loading={this.props.isLoading}>
        {isViewMode ? null : (
          <Segment>
            <Button primary onClick={this.onAddSection}>
              <Icon name="plus" />
              {t('addSection')}
            </Button>
          </Segment>
        )}
        <div>
          {offerSections
            ?.sort?.((a, b) => a.position - b.position)
            ?.map?.(section => {
              return (
                <div key={section.position}>
                  <OfferSection
                    isViewMode={isViewMode}
                    section={section}
                    sectionErrors={offerErrors?.sections?.[section?.backendIndex]}
                    disableUpArrow={section.position === offerSections?.[0]?.position}
                    disableDownArrow={section.position === offerSections?.[offerSections.length - 1]?.position}
                    dropdownPositions={dropdownPositions}
                    onChange={onChange}
                  />
                  <br />
                  <br />
                </div>
              );
            })}
        </div>
        {offerSections?.length > 0 && (
          <Segment>
            <Button primary onClick={this.onAddSection}>
              <Icon name="plus" />
              {t('addSection')}
            </Button>
          </Segment>
        )}
      </Form>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(OfferSections);
