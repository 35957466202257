import { CREATE_ORDER_INIT, CREATE_ORDER_SUCCESS, CREATE_ORDER_FAILURE } from '@store/action-types/orders';
import client from '@api-sdk/client';
import { set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const createOrderInit = () => ({ type: CREATE_ORDER_INIT });
const createOrderSuccess = order => ({ type: CREATE_ORDER_SUCCESS, payload: { order } });
const createOrderFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    set(acc, path, item.code?.split?.(':')?.pop().replace(/\s/g, '').split('.').join('') || item.message);

    return acc;
  }, {});

  return { type: CREATE_ORDER_FAILURE, payload: { error: mappedErrors } };
};

const createOrder = ({ investorData, agreementsData, saveAndBack = false }) => async dispatch => {
  dispatch(createOrderInit());

  try {
    await client.createOrder({
      data: {
        ...investorData,
        agreements: agreementsData?.map(agreement => ({
          agreement: agreement.agreement,
          isAccepted: agreement.isAccepted,
        })),
      },
    });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('order.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    if (saveAndBack) {
      history.push(ROUTES.ORDERS);
    }

    await dispatch(createOrderSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('order.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );

    dispatch(createOrderFailure(error));
  }
};

export default createOrder;
