import React, { useEffect, useState } from 'react';
import useMount from '@shared/utils/useMountHook';
import { shape, string } from 'prop-types';
import { Button, Tab } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { CardHeader, CardToolbar } from '@shared/components';
import { General, Maintenance, PaypalConfig, SocialMedia, GoogleConfig } from '@screens/settings/components';
import { isEmpty } from 'lodash';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Container } from '@material-ui/core';

const Main = ({ actions, config, status, paypalStatus, paypalConfig }) => {
  const [configData, setConfigData] = useState({});
  const [paypalData, setPaypalData] = useState({});
  const [t] = useTranslation(TRANSLATIONS.SETTINGS);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useMount(() => {
    actions.getConfigAction();
    actions.getPaypalConfigAction();
  });

  useEffect(() => {
    isEmpty(configData) && setConfigData(config);
    isEmpty(paypalData) && setPaypalData(paypalConfig);
  }, [config, paypalConfig]);

  const handleRefresh = async () => {
    await setConfigData({});
    await setPaypalData({});

    if (activeIndex === 3) {
      await actions.getPaypalConfigAction();
      return;
    }

    await actions.getConfigAction();
  };

  const handleSave = async () => {
    await setIsLoading(true);

    if (activeIndex === 3) {
      await actions.editPaypalConfigAction(paypalData);
      await setIsLoading(false);
      return;
    }

    await actions.editConfigAction(configData);
    await setIsLoading(false);
  };

  const handleTabChange = (e, tab) => setActiveIndex(tab?.activeIndex);

  const renderMenuButtons = () => {
    return (
      <CardHeader>
        <CardToolbar className="justify-content-end w-100 d-flex">
          <Button disabled={isLoading || status === FETCH_STATUS.IDLE} color="black" onClick={handleRefresh}>
            {t('actionButtons.reload')}
          </Button>
          <Button disabled={isLoading || status === FETCH_STATUS.IDLE} variant="outlined" primary onClick={handleSave}>
            {t('actionButtons.save')}
          </Button>
        </CardToolbar>
      </CardHeader>
    );
  };

  const panes = [
    {
      menuItem: t('tab.general'),
      render: () => (
        <Tab.Pane>
          <General isLoading={isLoading} status={status} configData={configData} setConfigData={setConfigData} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: t('tab.maintenance'),
      render: () => (
        <Tab.Pane>
          <Maintenance isLoading={isLoading} status={status} configData={configData} setConfigData={setConfigData} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: t('tab.socialMedia'),
      render: () => (
        <Tab.Pane>
          <SocialMedia isLoading={isLoading} status={status} configData={configData} setConfigData={setConfigData} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: t('tab.paypal'),
      render: () => (
        <Tab.Pane>
          <PaypalConfig
            isLoading={isLoading}
            status={paypalStatus}
            configData={paypalData}
            setConfigData={setPaypalData}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: t('google'),
      render: () => (
        <Tab.Pane>
          <GoogleConfig isLoading={isLoading} status={status} configData={configData} setConfigData={setConfigData} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container maxWidth="lg">
      <div style={{ marginBottom: -32 }}>{renderMenuButtons()}</div>
      <Tab onTabChange={handleTabChange} panes={panes} activeIndex={activeIndex} />
      <div className="mt-4">{renderMenuButtons()}</div>
    </Container>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  config: shape({}),
  status: string,
  paypalStatus: string,
  paypalConfig: shape({}),
};

Main.defaultProps = {
  config: {},
  status: undefined,
  paypalStatus: undefined,
  paypalConfig: {},
};

export default Main;
