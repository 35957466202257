import React, { useEffect, useMemo } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, Table } from '@shared/components';
import { Button, Icon, Message, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { mapErrorCodes } from '@screens/transactions/utils';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import PERMISSIONS from '@shared/consts/permissions';
import SCREEN_NAMES from '@shared/consts/screenNames';
import { isEmpty } from 'lodash';

const Main = ({
  allInvestments,
  actions,
  allInvestmentsError,
  allInvestmentsStatus,
  queryParams,
  permittedPermissions,
  isPermissionChecked,
}) => {
  const [t] = useTranslation(TRANSLATIONS.INVESTMENTS);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const refreshPage = () => {
    actions.getInvestmentsAction();
  };

  useMountWithTranslate(() => {
    actions.getInvestmentsAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.INVESTMENTS]) {
      if (!isUserPermitted(PERMISSIONS.GET_INVESTMENT_BY_ID)) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.INVESTMENTS);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.GET_INVESTMENT_BY_ID) && PERMISSIONS.GET_INVESTMENT_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  const columns = useMemo(
    () => [
      {
        Header: t('list.user'),
        accessor: 'id',
        columnName: 'userEmail',
        Cell: ({ row }) => {
          const Username = row?.original?.userMeta?.isDeleted ? Typography : Link;

          return (
            <div className="d-flex align-items-start">
              <Username to={`/users/${row?.original?.userMeta?.id}`} className="mr-1">
                {`${row?.original?.userMeta?.firstName} ${row?.original?.userMeta?.lastName} (${row?.original?.userMeta?.email})`}
              </Username>
              {row?.original?.userMeta?.isDeleted && (
                <Popup basic content="User deleted" trigger={<Icon name="warning sign" color="yellow" />} />
              )}
            </div>
          );
        },
      },
      {
        Header: t('list.hasPendingOrders'),
        accessor: 'hasPendingOrders',
        className: 'text-center',
        Cell: ({ row }) => {
          return <div>{row?.original?.hasPendingOrders ? t('yes') : t('no')}</div>;
        },
      },
      {
        Header: t('list.investedAmount'),
        accessor: 'investedAmount',
        className: 'text-center',
        Cell: ({ row }) => {
          return <div>{row?.original?.investedAmount?.toLocaleString()} PLN</div>;
        },
      },
      {
        Header: t('list.numberOfShares'),
        accessor: 'numberOfShares',
        className: 'text-center',
        Cell: ({ row }) => {
          return <div>{row?.original?.numberOfShares?.toLocaleString()}</div>;
        },
      },
      {
        Header: t('list.offer'),
        accessor: 'offer',
        columnName: 'offer',
        className: 'text-center',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.GET_OFFER_BY_ID) ? (
            <Button as={Link} target="_blank" to={`/offers/${row?.original?.id}/edit`}>
              {row?.original?.offer?.title}
            </Button>
          ) : (
            row?.original?.offer?.title
          ),
      },
      {
        Header: t('list.edit'),
        className: 'text-center',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.GET_INVESTMENT_BY_ID) ? (
            <Button primary as={Link} to={`/investments/${row.original?.id}/edit`}>
              {t('actionButtons.edit')}
            </Button>
          ) : null,
      },
    ],
    [t],
  );

  const renderContent = () => (
    <Table
      isLoading={allInvestmentsStatus === FETCH_STATUS.LOADING}
      data={allInvestments}
      columns={columns}
      queryParams={queryParams}
      searchAction={actions.getInvestmentsAction}
      reloadAction={refreshPage}
      notFoundMessage={<Message>{t('notFoundInvestments')}</Message>}
    />
  );

  return (
    <Card width={false} className="admin-investments">
      <CardHeader>
        <CardTitle title={t('mainTitle')} />
        <CardToolbar>
          <Button color="black" disabled={allInvestmentsStatus === FETCH_STATUS.LOADING} onClick={refreshPage}>
            {t('actionButtons.reload')}
          </Button>
        </CardToolbar>
      </CardHeader>
      {allInvestmentsError && mapErrorCodes({ errorCode: allInvestmentsError, t })}
      <CardBody transparent>{renderContent()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  allInvestments: arrayOf(shape({})),
  allInvestmentsError: shape({}),
  allInvestmentsStatus: string,
  queryParams: shape({}),
  permittedPermissions: arrayOf(shape({})),
  isPermissionChecked: shape({}),
  row: shape({}),
};

Main.defaultProps = {
  allInvestments: [],
  allInvestmentsError: undefined,
  allInvestmentsStatus: undefined,
  queryParams: undefined,
  permittedPermissions: [],
  isPermissionChecked: {},
  row: {},
};

export default Main;
