import React from 'react';
import moment from 'moment';
import SeriesForm from '@screens/series/edit-series/components/SeriesForm';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const Main = ({ actions, seriesStatus, seriesErrors }) => {
  const SeriesModel = {
    limit: 0,
    pricePerShare: 0,
    bankInfo: {
      accountNumber: '',
      bankName: '',
      accountHolder: '',
    },
    availableFrom: moment().format('YYYY-MM-DD'),
    availableTo: moment().format('YYYY-MM-DD'),
    payPalCredentials: {
      sandboxClientId: '',
      sandboxClientSecret: '',
      productionClientId: '',
      productionClientSecret: '',
      productionMode: false,
    },
    allowOnlinePayments: true,
    useDefaultPayPalConfig: true,
    name: {
      pl: '',
      en: '',
    },
    displayName: {
      pl: '',
      en: '',
    },
  };

  return (
    <div>
      <SeriesForm
        isLoading={seriesStatus === FETCH_STATUS.LOADING}
        series={SeriesModel}
        seriesErrors={seriesErrors}
        onCreateSeries={actions.createSeriesAction}
        isCreateMode
      />
    </div>
  );
};

export default Main;
